// ===== Interfaces ===== //
import {InterfaceOWDocletConsumer} from './consumer';
import {InterfaceAdmissionQRCode, InterfaceOWTemplateConsumerEntitlement} from './entitlement';
import {InterfaceOWDoclet} from '../ow/doclets';
//
export interface InterfacePOSActionScanTicketConsumers {
	consumer: InterfaceOWDocletConsumer;
	admissions: InterfaceOWDoclet<InterfaceAdmissionQRCode>[];
	consumer_entitlements: InterfaceOWDoclet<InterfaceOWTemplateConsumerEntitlement>[];
}

export interface InterfacePOSActionScanTicket {
	scanned_ticket: InterfaceOWDoclet<InterfaceAdmissionQRCode>; // supposed to scan a QR code, and this will be it. but server-side (incorrectly?) allows entitlements to be scanned...
	scanned_consumer_id: string; // the (consumer)._id, as a string, not as ObjectId()
	group_id: string; // Family ._id
	group_entitlements: InterfaceOWDoclet<InterfaceOWTemplateConsumerEntitlement>[];
	consumers: InterfacePOSActionScanTicketConsumers[]; // group members, along with any QR codes and entitlements.
	visit_date: string | null; // should be a YYYY-MM-DD string, or maybe an event ID (object_id) or maybe NULL???
	is_date_valid: boolean;
	valid_entry: boolean;
	event_id: string | null; // it will be the same as what is sent up...
	// TODO: re-verify these next fields
	device_id?: string;
}

export interface InterfaceUnassignedWristbandResponse {
	unassigned_device: boolean;
}

// TODO: replace/delete InterfaceScannedTicket
export interface InterfaceScannedTicket { // see InterfacePOSActionScanTicket
	// this entire data structure is created on the fly, by the POS landing page. it does not come from the OW API.
	consumer: InterfaceOWDoclet | undefined; // this won't exist unless it was a season pass holder's ticket or similar that was scanned.
	device_id?: string; // won't exist, unless the band is linked
	ticket: InterfaceOWDoclet;
	ticketFlags: { // TODO: re-do these flags, as they were sort-of WR specific...
		status: string;
		isLinked: boolean;
		isAssigned: boolean;
		isProfileLocked: boolean; // becomes true when somebody on the POS approves an SPH person's info (photo, first/last name, DOB)
		isDateForToday: boolean;
	};
	entitlements?: InterfaceOWDoclet[];
	isValidEntry: boolean;
	visitDate: string; // YYYY-MM-DD
}

export interface InterfacePOSProcessQRDataResult { // output of ComponentScanQRCode
	error: boolean;
	errorType: 'network' | 'bad_qr_code' | null;
	result: InterfacePOSActionScanTicket | null;
}
