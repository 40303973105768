import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http'; // https://angular.io/api/common/http/HttpClient
import {Injectable} from '@angular/core';
import {catchError, of, map, Observable} from 'rxjs';
// ===== App ===== //
import {ConfigOWEnvironmentIDs} from '../../../environment-ids/ow-live-ids';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway, InterfaceHTTPMethods, InterfaceOWAPIPaginationRequest} from '../../../interfaces/interfaces';
interface InterfaceHTTPOptions {
	observe: string;
	headers: HttpHeaders;
}
// ===== Services ===== //
import {ServiceAuthentication} from '../../authentication';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWGateway {
	public constructor(
		private readonly appConfig: ConfigOWEnvironmentIDs,
		private readonly auth: ServiceAuthentication,
		private readonly http: HttpClient
	) {
		//
	}

	public fetch( method: keyof InterfaceHTTPMethods, route: string, payload?: string, noAuth?: boolean, paginationOptions?: InterfaceOWAPIPaginationRequest ): Observable<InterfaceHTTPGateway> {
		let httpHeaders: HttpHeaders = new HttpHeaders().set( 'Content-Type', 'application/json' );
		let pLimit: InterfaceOWAPIPaginationRequest['limit'] = 20;
		let pSortDir: InterfaceOWAPIPaginationRequest['sortDir'] = 1;
		let pSortKey: InterfaceOWAPIPaginationRequest['sortKey'] = '';
		if ( paginationOptions ) {
			// X-Pagination-Cursor-Prev
			// X-Pagination-Cursor-Next
			// X-Pagination-Sort
			// X-Pagination-Sort-Dir
			// X-Pagination-Limit
			if ( paginationOptions.cursorPrev ) { // use the next cursor, for the next page. prev for the prev page, don't use both, etc.
				httpHeaders = httpHeaders.set( 'X-Pagination-Cursor-Prev', paginationOptions.cursorPrev );
			} // if you supply both next and prev, next takes priority.
			if ( paginationOptions.cursorNext ) {
				httpHeaders = httpHeaders.set( 'X-Pagination-Cursor-Next', paginationOptions.cursorNext );
			}
			if ( paginationOptions.sortKey ) {
				httpHeaders = httpHeaders.set( 'X-Pagination-Sort', paginationOptions.sortKey );
				pSortKey = paginationOptions.sortKey.valueOf();
			}
			if ( paginationOptions.sortDir ) {
				switch ( String( paginationOptions.sortDir ).toLowerCase() ) {
					default:
					case '1':
					case 'asc': {
						httpHeaders = httpHeaders.set( 'X-Pagination-Sort-Dir', '1' );
						pSortDir = 1;
						break;
					}
					case '-1':
					case 'desc': {
						httpHeaders = httpHeaders.set( 'X-Pagination-Sort-Dir', '-1' );
						pSortDir = -1;
						break;
					}
				}
			}
			if ( paginationOptions.limit ) {
				httpHeaders = httpHeaders.set( 'X-Pagination-Limit', String( paginationOptions.limit ) );
				pLimit = paginationOptions.limit;
			}
		}
		if ( noAuth ) {
			// don't include the JWT in the header.
		} else {
			const JWT: string | null = this.auth.getAuthToken();
			if ( typeof JWT === 'string' && JWT.length > 0 ) { // if it's blank, let it stay blank and fail the authentication.
				httpHeaders = httpHeaders.set( 'Authorization', 'Bearer ' + JWT );
			} else {
				console.log( 'Rejected network request due to missing JWT' );
				return of( {
					success: false,
					status: 0,
					data: null
				} );
			}
		}
		const httpOptions: {
			observe: 'body' | 'events' | 'response';
			headers: HttpHeaders
		} = {
			observe: 'response', // documentation & libraries says 'body' is the only valid value, but that's not true. source code shows "body", "response" and "events".
			headers: httpHeaders
		};
		// GET    : (route, options)
		// POST   : (route, payload, options)
		// HEAD   : (route, options)
		// DELETE : (route, options)
		// PUT    : (route, payload, options)
		// PATCH  : (route, payload, options)
		// OPTIONS: (route, options)
		const param1: string = this.appConfig.getBaseAPI() + route;
		let param2: string | InterfaceHTTPOptions;
		let param3: InterfaceHTTPOptions | undefined;
		if ( method === 'post' || method === 'put' || method === 'patch' ) { // these methods have 3 parameters.
			param2 = payload ? payload : '';
			param3 = httpOptions;
		} else {
			param2 = httpOptions;
		}
		// @ts-ignore -- param3 is seen as invalid because something assumes it *always* ought to only have 2 params, not 3.
		return this.http[method]( param1, param2, param3 ).pipe( // pipe returns an observable that returns data, once all parameters are processed.
			map( (response: any): InterfaceHTTPGateway => {
				/*
				if ( paginationOptions ) {
					if ( response.body && response.body.data && typeof response.body.data === 'object' ) {
						const paginatedData: InterfaceOWAPIPaginationData = response.body.data;
						if ( !paginatedData.hasOwnProperty( 'limit' ) ) {
							paginatedData.limit = pLimit;
						}
						if ( !paginatedData.hasOwnProperty( 'sort' ) ) {
							paginatedData.sort = pSortKey;
						}
						if ( !paginatedData.hasOwnProperty( 'sort_dir' ) ) {
							paginatedData.sort_dir = pSortDir;
						}
						if ( !paginatedData.hasOwnProperty( 'have_more' ) ) {
							paginatedData.have_more = false;
						}
					}
				}
				*/
				if ( 'meta' in response.body && response.body.meta && 'token' in response.body.meta && typeof response.body.meta.token === 'string' ) {
					if ( response.body.meta.token.length > 0 ) {
						this.auth.refreshAuthTokenFromAPIResponse( response.body );
					}
				}
				return {
					success: response.hasOwnProperty( 'ok' ) ? !!response.ok : (Number( response.status ) >= 200 && Number( response.status ) < 300),
					status: Number( response.status ),
					data: response.body
				};
			} ), // end of params #1 of .pipe()
			catchError( (response: HttpErrorResponse): Observable<InterfaceHTTPGateway> => {
				// instead of headers, status and body
				// the response has headers, status and error. but it's the same data.
				return of( { // 'of()' returns an observable
					success: false,
					status: Number( response.status ),
					// 'data' will always become an array this way. should be an array of errors.
					data: response.error && Array.isArray( response.error.errors )
						? response.error.errors
						: (response.error && response.error.errors ? [ response.error.errors ] : [] )
				} );
			} ) // end of params #2 of .pipe()
		);
	}

	public requestDenied(): Observable<InterfaceHTTPGateway> {
		return of( {
			success: false,
			status: 0,
			data: null
		} );
	}

	private fetchContents( path: string ): Observable<InterfaceHTTPGateway> {
		return this.http.get( path, {
			observe: 'response'
		} ).pipe(
			map( (response: any): InterfaceHTTPGateway => {
				return {
					success: true,
					status: Number( response.status ),
					data: response.body // beware - hidden automatic-transformation steps may have already occurred.
				}; // if you request JSON files, data turns into an Object.
			} ),
			catchError( (response: HttpErrorResponse): Observable<InterfaceHTTPGateway> => {
				return of( {
					success: false,
					status: Number( response.status ),
					data: response.error && Array.isArray( response.error.errors )
						? response.error.errors
						: (response.error && response.error.errors ? [ response.error.errors ] : [] )
				} );
			} )
		);
	}

	public fetchJSON( path: string ): Observable<InterfaceHTTPGateway> {
		if ( path && path.match( /\.json$/i ) !== null ) {
			return this.fetchContents( path );
		}
		return this.requestDenied();
	}
}
