<modal-back-drop id="modal-edit-group-member-wrapper">
	<span id="modal-edit-group-member" class="modal-body">
		<span class="content-wrapper">
			<span class="scroll-wrapper">
				<span class="title">Edit Member</span>
				<span class="form">
					<label class="wide">
						<span class="frame-label">First Name <b>*</b></span>
						<input class="align-center" type="text" name="first-name" autocomplete="off"
							[class.error]="memberErrors.firstName"
							[(ngModel)]="memberData.firstName"
							[ngModelOptions]="{ updateOn: 'blur' }"
							(ngModelChange)="validateField( 'firstName' )"
							(focus)="memberErrors.firstName = false;"
						/>
					</label>
					<label class="wide">
						<span class="frame-label">Last Name <b>*</b></span>
						<input class="align-center" type="text" name="last-name" autocomplete="off"
							[class.error]="memberErrors.lastName"
							[(ngModel)]="memberData.lastName"
							[ngModelOptions]="{ updateOn: 'blur' }"
							(ngModelChange)="validateField( 'lastName' )"
							(focus)="memberErrors.lastName = false;"
						/>
					</label>
					<label class="wide">
						<span class="frame-label">Email <b>*</b></span>
						<input class="align-center" type="text" name="email" autocomplete="off"
							[readOnly]="emailLocked"
							[class.error]="!emailLocked && memberErrors.email"
							[(ngModel)]="memberData.email"
							[ngModelOptions]="{ updateOn: 'blur' }"
							(ngModelChange)="validateField( 'email' )"
							(focus)="memberErrors.email = false;"
						/>
					</label>
				</span>
				<span class="actions">
					<span class="action button close" (click)="closeModal();">Cancel</span>
					<span class="action button submit" (click)="saveMember();">Save</span>
				</span>
			</span>
		</span>
	</span>
</modal-back-drop>
