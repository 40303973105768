import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../../../../../ow-framework/collections/profiles';
// ===== Interfaces ===== //
import {
	InterfaceAppEvent,
	InterfaceCartData,
	InterfaceDocletIDToTicketProps,
	InterfaceEventProductAvailability,
	InterfaceHTTPGateway,
	InterfaceOWAPIBulkRecordRequest,
	InterfaceOWAPIEventProductAvailabilityResponse,
	InterfaceOWAPIOrderItems,
	InterfaceOWAPIOrderPaymentInfo,
	InterfaceOWAPIOrderResponse,
	InterfaceOWAPIOrderResponseData,
	InterfaceOWAPIPromoCodeItems,
	InterfaceOWAPIPromoCodeItemsItem,
	InterfaceOWAPIPromoCodeResponse,
	InterfaceOWAPISignUpResponse,
	InterfaceOWDoclet,
	InterfaceOWTemplateEventPass,
	InterfaceOWTemplateEventProduct,
	InterfaceOWUser,
	InterfaceSerializedCart
} from '../../../../../../ow-framework/interfaces/interfaces';
interface InterfaceAccountData {
	firstName: string;
	lastName: string;
	email: string;
	password1: string;
	password2: string;
	cashlessSpending: boolean;
}
interface InterfaceAccountErrors {
	firstName: boolean;
	lastName: boolean;
	email: boolean;
	password1: boolean;
	password2: boolean;
}
interface InterfaceCheckoutData {
	card: {
		name: string;
		number: string;
		expMM: string;
		expYYYY: string;
		cvv: string;
	};
	billing: {
		street: string;
		unit: string;
		city: string;
		state: string;
		zip: string;
	};
}
interface InterfaceCheckoutErrors {
	card: {
		name: boolean;
		number: boolean;
		expMM: boolean;
		expYYYY: boolean;
		cvv: boolean;
	};
	billing: {
		street: boolean;
		city: boolean;
		state: boolean;
		zip: boolean;
	};
}
interface InterfaceDisplayLineItemsProductProps {
	productDocletID: string;
	productName: string;
	heldTicketID: string | null;
	first_name?: string;
	last_name?: string;
	dob?: string;
	errors: {
		first_name?: boolean;
		last_name?: boolean;
	};
}
interface InterfaceDisplayLineItems_v2 {
	eventID: string;
	strYYYYMMDD: 'any' | 'event' | string; // 'YYYY-MM-DD' // the date to send server-side.
	strDisplayDate: string; // the date to display on the front end.
	strDisplayTime?: string;
	productDisplayName: string;
	productProps: InterfaceDisplayLineItemsProductProps[];
	lineItemSubTotal: number;
	locationID?: string[];
}
interface InterfaceDisplayLineItems {
	passID: string;
	eventID: string;
	strYYYYMMDD: string; // the date to send server-side.
	strDisplayDate: string; // the date to display on the front end.
	strDisplayTime: string;
	passDisplayName: string;
	passProps: InterfaceDisplayLineItemsProductProps[];
	lineItemSubTotal: number;
	isDiscounted: boolean;
	discountAmount: number;
	locationID?: string[];
}
interface InterfaceRoles {
	admin: string;
	pos: string;
	staff: string;
	web: string;
}
// ===== Services ===== //
import {ServiceAppEvents} from '../../../../../../ow-framework/services/app-events';
import {ServiceAuthentication} from '../../../../../../ow-framework/services/authentication';
import {ServiceCart} from '../../../../../../ow-framework/services/cart';
import {ServiceOWAPI} from '../../../../../../ow-framework/services/ow-api';
import {ServiceRegex} from '../../../../../../ow-framework/services/regex';
import {ServiceSorting} from '../../../../../../ow-framework/services/sorting';
// ===== Transformers ===== //
import {TransformerDate} from '../../../../../../ow-framework/transformers/date';
import {TransformerEventPasses} from '../../../../../../ow-framework/transformers/event-passes';
import {TransformerEventProductBase} from '../../../../../../ow-framework/transformers/event-product-base';
//
@Component( {
	selector: 'page-checkout',
	templateUrl: './checkout.html',
	styleUrls: [
		'./checkout.less'
	]
} )
export class PageCheckout implements OnDestroy, OnInit {
	private selectedEventID: string = '67c655b13deb2858c5998a89'; // Framework's block party
	public readonly routes: typeof AppRouterLinks = AppRouterLinks;
	public readonly dobMaxDate: string = new Date().toISOString().split( 'T' )[0];
	public readonly monthLabels: string[] = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
	private readonly strConsumerTemplateID: string = this.appConfig.getTemplateID( 'Consumer' );
	private readonly strEventMerchandiseTemplateID: string = this.appConfig.getTemplateID( 'Event Merchandise' );
	private readonly strEventPassTemplateID: string = this.appConfig.getTemplateID( 'Event Pass' );
	private readonly strAdminRole: string = this.appConfig.getRoleID( 'Admin' );
	private readonly strStaffRole: string = this.appConfig.getRoleID( 'Staff' );
	private readonly strPOSRole: string = this.appConfig.getRoleID( 'POS' );
	private readonly strWebRole: string = this.appConfig.getRoleID( 'Web' );
	public cartHeldIDsFatalError: boolean = false; // if true, the user cannot check out, and must head back to purchasing event passes.
	public liabilityAgreementChecked: boolean = true;
	public termsAndConditionsChecked: boolean = false;
	private busy: boolean = false;
	public promoCode: string | null = '';
	private subPromoCodeChanged: Subscription | null = null;
	private discountedItems: InterfaceOWAPIPromoCodeItems[] = [];
	public invalidPromoCode: boolean = false;
	public isSignedIn: boolean = this.auth.isSignedIn();
	public termsConditionsURL: string | null = '/assets/pdf/workspace/' + this.appConfig.getContext().workspaceID + '/terms-and-conditions.pdf';
	// ===== Captcha ===== //
	public readonly reCaptchaSiteKey: string = this.appConfig.getReCaptchaSiteKey();
	private reCaptchaUserToken: string = '';
	// ===== Ticket availability (just for pricing logic) ===== //
	public productAvailability: InterfaceEventProductAvailability = {}; // { YYYY-MM-DD : { passID : { item_count: 0, item_availability: 0 } }
	// ===== Cart Items ===== //
	public productListing: InterfaceDisplayLineItems[] = [];
	public subTotal: number = 0; // a sum of all products
	public discounts: number = 0;
	public grandTotal: number = 0; // subTotal - discounts = grandTotal
	// ===== Account Data ===== //
	private subUserReSync: Subscription | null = null;
	public tmpTicketErrors: boolean = false;
	public userProfile: InterfaceOWUser | null = null;
	public accountData: InterfaceAccountData = {
		firstName: '',
		lastName: '',
		email: '',
		password1: '',
		password2: '',
		cashlessSpending: true
	};
	public accountErrors: InterfaceAccountErrors = {
		firstName: false,
		lastName: false,
		email: false,
		password1: false,
		password2: false
	};
	public checkoutData: InterfaceCheckoutData = {
		card: {
			name: '',
			number: '',
			expMM: '',
			expYYYY: '',
			cvv: ''
		},
		billing: {
			street: '',
			unit: '',
			city: '',
			state: '',
			zip: ''
		}
	};
	public checkoutErrors: InterfaceCheckoutErrors = {
		card: {
			name: false,
			number: false,
			expMM: false,
			expYYYY: false,
			cvv: false
		},
		billing: {
			street: false,
			city: false,
			state: false,
			zip: false
		}
	};
	private cacheEmailAlreadyInUse: { [email: string]: true; } = {};
	public emailIsInUse: boolean = false;
	// ===== Pass Content ===== //
	// TODO: reconfigure the PassPriceType now that the prices are packed into .data.price_matrix
	public productIDToProductProps: { [productID: string]: InterfaceDocletIDToTicketProps<InterfaceOWTemplateEventPass>; } = {};
	public productIDToTicketProps: { [productID: string]: {
		dataFirstLastName: boolean; // daily and Event Admission Passes need this.
		dataDOB: boolean; // daily and season admission need this.
		flagsFirstLastIsOptional?: boolean; // cabana's have an optional first/last name.
	}; } = {};
	public displayReady: boolean = false; // true once the cart items and the content passes are used to make display items.
	private merchIDs: { [merchID: string]: true; } = {};
	private passIDs: { [passID: string]: true; } = {};
	// ===== Checking Out ===== //
	private usersProfileIDForCart: string | null = null;
	public isCheckingOut: boolean = false;
	public haveCheckoutErrors: boolean = false;
	public declinedReason: string = ''; // 'Declined: Something something'
	public readonly now: Date = new Date();
	public readonly nowYYYYMM1DD: string = String( '0000' + this.now.getFullYear() ).slice( -4 ) + '-' + String( '00' + (this.now.getMonth() + 1) ).slice( -2 ) + '-' + String( '00' + this.now.getDate() ).slice( -2 );
	//
	public copyMap: {
		[key: string]: string;
	} = {
		pageTitle: 'Checkout' // needs to be "checkout_page_title" etc..
	};
	private subWorkspaceChanged: Subscription | null = null;
	private trackingCookie: string = String( new Date().getTime() ) + '-' + String( Math.floor( Math.random() * 100000 ) );
	private queryKVP: { [key: string]: string; } = {};
	//
	public constructor(
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly owapi: ServiceOWAPI,
		private readonly router: Router,
		private readonly title: Title
	) {
		ServiceCart.deserialize();
		this.promoCode = ServiceCart.getPromoCode();
		this.subPromoCodeChanged = ServiceCart.promoCodeChanged.subscribe( (): void => {
			this.promoCode = ServiceCart.getPromoCode();
		} );
		if ( ServiceCart.cartData.length < 1 ) {
			console.log( 'No items in the cart' );
		}
		this.title.setTitle( this.copyMap['pageTitle'] );
		this.subUserReSync = ServiceAppEvents.listen( 'user:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
			this.isSignedIn = this.auth.isSignedIn();
			if ( this.isSignedIn ) {
				this.cacheEmailAlreadyInUse = {};
				this.emailIsInUse = false; // the logic here assumed the re-sync happened due to the customer signing in. but profile updates will also trigger this.
			}
			this.fetchUserInfo();
		} );
		this.subWorkspaceChanged = ServiceAppEvents.listen( 'workspace:changed' ).subscribe( (_: InterfaceAppEvent): void => {
			this.termsConditionsURL = '/assets/pdf/workspace/' + this.appConfig.getContext().workspaceID + '/terms-and-conditions.pdf';
			this.title.setTitle( this.copyMap['pageTitle'] );
		} );
		this.fetchUserInfo();
		const cartStats: InterfaceSerializedCart = ServiceCart.getSerializedCart();
		this.owapi.workspace.actions.core.recordResourceUse( this.appConfig.getContext(), AppRouterLinks.checkout, 'page', {
			cartStats: {
				promoCode: cartStats.promoCode,
				serializedCart: cartStats.cartData
			},
			eventID: this.selectedEventID,
			isSignedIn: this.isSignedIn,
			profileID: this.auth.getProfileID(),
			tracker: this.trackingCookie
		} ).subscribe( (_:InterfaceHTTPGateway): void => {} ); // fire and forget
		const rystKVP: string | null = localStorage.getItem( 'ryst-kvp' );
		if ( typeof rystKVP === 'string' && rystKVP.length > 0 ) {
			try {
				const kvp = JSON.parse( rystKVP );
				Object.keys( kvp ).forEach( (key: string): void => {
					if ( typeof kvp[key] === 'string' ) {
						this.queryKVP[key] = kvp[key];
					}
				} );
			} catch ( _ ) {}
		}
	}

	private updateAvailability( availability: InterfaceEventProductAvailability ): void {
		// there is no great way to remove things from the cache.
		// currently you need to just wipe it out -- this.productAvailability = {};
		// and then re-request the date range all over again, which is expensive.
		Object.keys( availability ).forEach( (YYYYMMDD: string): void => {
			if ( !(YYYYMMDD in this.productAvailability) ) {
				this.productAvailability[YYYYMMDD] = {};
			}
			Object.keys( availability[YYYYMMDD] ).forEach( (passID: string): void => {
				this.productAvailability[YYYYMMDD][passID] = availability[YYYYMMDD][passID];
			} );
		} );
	}

	private fetchProducts( callback: () => void ): void { // build productIDToProductProps
		const productIDs: string[] = Array.from( new Set( ServiceCart.cartData.map( (cartEntry: InterfaceCartData): string => cartEntry.passID ).reduce( (output: string[], val: string): string[] => {
			output.push( val );
			return output;
		}, [] ) ) ).sort();
		console.log( '===== Fetching Public Products For Sale =====' ); // part 1 of 4
		let wasCartModified: boolean = false;
		const ticketIDsToRelease: string[] = [];
		const withoutAuth: boolean = true;
		const roleIDs: InterfaceRoles = {
			admin: this.strAdminRole,
			pos: this.strPOSRole,
			staff: this.strStaffRole,
			web: this.strWebRole
		};
		// warning ~ because you're not using the magic action, the entitlement_type_data won't be populated
		this.owapi.workspace.doclets.getAllDocletsByID( this.appConfig.getContext(), productIDs, withoutAuth, (responseBulkFetch: InterfaceOWAPIBulkRecordRequest<InterfaceOWDoclet<InterfaceOWTemplateEventProduct>>): void => {
			if ( responseBulkFetch.success ) {
				if ( responseBulkFetch.records.length !== productIDs.length ) {
					console.log( 'Fetched passes for sale, but the cart has some that are extra/missing.', productIDs, responseBulkFetch.records );
				}
				// the bulk fetch had no way to query for only active passes for sale, so we have to double check that..
				for ( let x: number = 0; x < responseBulkFetch.records.length; ++x ) {
					if ( responseBulkFetch.records[x]?.data?.status !== 'active' ) {
						const badProduct: InterfaceOWDoclet<InterfaceOWTemplateEventProduct> = responseBulkFetch.records.splice( x--, 1 ).pop() as InterfaceOWDoclet<InterfaceOWTemplateEventProduct>;
						console.log( 'Cart had a invalid product for sale, removing it.', badProduct );
						for ( let y: number = 0; y < ServiceCart.cartData.length; ++y ) {
							if ( ServiceCart.cartData[y].passID === badProduct._id.$oid ) {
								// TODO: cart fn to remove from cart, so that it doesn't directly change the cart outside the service/class.
								const badCartItem: InterfaceCartData = ServiceCart.cartData.splice( y--, 1 ).pop() as InterfaceCartData;
								wasCartModified = true; // the user needs to head back to the tickets page and re-do things...
								if ( badCartItem.ticketID !== null && badCartItem.ticketID.length > 0 ) {
									ticketIDsToRelease.push( badCartItem.ticketID );
								}
							}
						}
					}
				} // end for each product for sale to double check if it was active or not.
				for ( let x: number = 0; x < responseBulkFetch.records.length; ++x ) {
					const productID: string = responseBulkFetch.records[x]._id.$oid;
					switch ( responseBulkFetch.records[x].template_id.$oid ) {
						case this.strEventPassTemplateID: {
							this.passIDs[productID] = true;
							break;
						} // these are used to know which passes to hand over, for when we fetch availability by (pass/merch).
						case this.strEventMerchandiseTemplateID: {
							this.merchIDs[productID] = true;
							break;
						}
					}
					// ===== //
					this.productIDToProductProps[productID] = TransformerEventProductBase.processEventProductProps( responseBulkFetch.records[x], roleIDs );
					// ===== //
					const props: InterfaceDocletIDToTicketProps<InterfaceOWTemplateEventPass> = this.productIDToProductProps[productID];
					this.productIDToTicketProps[productID] = { // default values
						dataFirstLastName: false, // ticket props only control the UI shown. they're not the productIDToProductProps
						dataDOB: false,
						flagsFirstLastIsOptional: true
					};
					if ( props.doclet?.data?.['require_fn_ln'] !== 'Never' ) {
						this.productIDToTicketProps[productID].dataFirstLastName = true; // shows the first/last name field.
					}
					if ( props.doclet?.data?.['require_dob'] !== 'Never' ) {
						this.productIDToTicketProps[productID].dataDOB = true; // shows (and i guess requires) the DOB field
					}
					if ( props.doclet?.data?.['require_fn_ln'] === 'Required' ) {
						this.productIDToTicketProps[productID].flagsFirstLastIsOptional = true; // makes the first/last name fields required.
					}
				}
			}
			if ( wasCartModified ) {
				// might need to just completely remove all items from the cart and start over.
			}
			if ( ticketIDsToRelease.length > 0 ) {
				// TODO: this.
			}
			callback();
		} );
	}

	private fetchAvailability( callback: () => void ): void { // part 2 - fetch availability because it's required to determine prices.
		console.log( '===== Fetching Sold Tickets =====' );
		// the sold amounts, come from the availability object.
		// the amount of tickets sold, determine the ticket prices.
		const visitDates: string[] = Array.from( new Set( ServiceCart.cartData.map( (cartEntry: InterfaceCartData): string => {
			if ( cartEntry.visitDate.isEventLength || cartEntry.visitDate.isAnyDay ) {
				return this.nowYYYYMM1DD; // the price of the event-length pass will be determined by today's date, or the default price.
			}
			if ( (cartEntry.visitDate.day ?? 0) === 0 || (cartEntry.visitDate.month1 ?? 0) === 0 ) {
				return this.nowYYYYMM1DD; // these are any-day types or event-length types in disguise.
			}
			return TransformerDate.strYYYYMMDDFromYMDInts( cartEntry.visitDate.year ?? 0, cartEntry.visitDate.month1 ?? 0, cartEntry.visitDate.day ?? 0 );
		} ) ) ).sort();
		if ( visitDates.length > 0 ) {
			const endDate: string = visitDates.pop() as string;
			const startDate: string = visitDates.length < 1 ? endDate : visitDates.shift() as string;
			// there are two workflow actions to fetch availability.
			// TODO: figure out a way to not have such large ranges of start/stop dates. we only care about when the cartItem's date has availability.
			const productIDs: {
				merch: string[];
				passes: string[];
			} = {
				merch: Object.keys( this.merchIDs ),
				passes: Object.keys( this.passIDs )
			};
			let remainingAPICalls: number = 2; // one for merch, one for passes. when this reaches zero, the callback should be invoked.
			let callbackToggleLock: boolean = false; // when true, the callback will no longer be fired (future bug fix lol)
			this.owapi.workspace.actions.core.getDailyMerchandiseAvailabilityFromDateRange(
				this.appConfig.getContext(),
				productIDs.merch,
				TransformerDate.dateFromYYYYMM1DD( startDate ),
				TransformerDate.dateFromYYYYMM1DD( endDate ),
				this.selectedEventID,
				this.strWebRole
			).subscribe( (response: InterfaceHTTPGateway<InterfaceOWAPIEventProductAvailabilityResponse>): void => {
				if ( response?.success ) {
					const apiResponse: InterfaceOWAPIEventProductAvailabilityResponse | undefined = response?.data;
					if ( apiResponse && Array.isArray( apiResponse?.data?.items ) && apiResponse.data.items.length > 0 ) {
						this.updateAvailability( apiResponse.data.items[0] );
					}
				}
				if ( --remainingAPICalls < 1 && !callbackToggleLock ) {
					callbackToggleLock = true;
					callback();
				}
			} );
			this.owapi.workspace.actions.core.getDailyAdmissionAvailabilityFromDateRangeV2(
				this.appConfig.getContext(),
				productIDs.passes,
				TransformerDate.dateFromYYYYMM1DD( startDate ),
				TransformerDate.dateFromYYYYMM1DD( endDate ),
				this.selectedEventID,
				this.strWebRole
			).subscribe( (response: InterfaceHTTPGateway<InterfaceOWAPIEventProductAvailabilityResponse>): void => {
				if ( response?.success ) {
					const apiResponse: InterfaceOWAPIEventProductAvailabilityResponse | undefined = response?.data;
					if ( apiResponse && Array.isArray( apiResponse?.data?.items ) && apiResponse.data.items.length > 0 ) {
						this.updateAvailability( apiResponse.data.items[0] );
					}
				}
				if ( --remainingAPICalls < 1 && !callbackToggleLock ) {
					callbackToggleLock = true;
					callback();
				}
			} );
		}
	}

	private buildDisplayData(): void { // clears and resets the product listing. initializes the consumer fields... first/last name, DOB.
		this.productListing = []; // the product listing is used, when building the final output, when packing up items for server-side.
		this.subTotal = 0;
		this.discounts = 0;
		this.grandTotal = 0;
		const unsortedDisplayItems: {
			[strYYYYMMDD: string]: {
				[productID: string]: InterfaceDisplayLineItems_v2;
			};
		} = {};
		ServiceCart.sortByDate();
		const d: Date = new Date();
		const strPurchaseDate: string = TransformerDate.strYYYYMMDDFromYMDInts( d.getFullYear(), 1 + d.getMonth(), d.getDate() );
		for ( let x: number = 0; x < ServiceCart.cartData.length; ++x ) {
			// this groups together everything being bought, by (date+time)
			const cartItem: InterfaceCartData = ServiceCart.cartData[x];
			const passID: string = cartItem.passID;
			const ticketYYYYMMDD1: string = cartItem.visitDate.isEventLength
				? 'event'
				: (cartItem.visitDate?.day === 0 && cartItem.visitDate?.month1 === 0
					? 'any'
					: TransformerDate.strYYYYMMDDFromYMDInts( cartItem.visitDate.year ?? 0, cartItem.visitDate.month1 ?? 0, cartItem.visitDate.day ?? 0 )
				);
			const ticketTime: string = cartItem.entryTime ?? '';
			const trackingKey: string = ticketYYYYMMDD1 + ticketTime; // 'event02:30:00' // 'YYYY-MM-DDHH:MM:SS'
			const ticketYear: number = cartItem.visitDate.year ?? 0;
			const ticketMonth1: number = cartItem.visitDate.month1 ?? 0;
			const ticketDay: number = cartItem.visitDate.day ?? 0;
			if ( !(trackingKey in unsortedDisplayItems) ) {
				unsortedDisplayItems[trackingKey] = {};
			}
			if ( !(cartItem.passID in unsortedDisplayItems[trackingKey]) ) {
				let colorAndSize: string = '';
				if ( this.merchIDs[cartItem.passID] ) {
					colorAndSize += this.productIDToProductProps[passID].doclet.data['color'] ? ', ' + this.productIDToProductProps[passID].doclet.data['color'] : ''
					colorAndSize += this.productIDToProductProps[passID].doclet.data['size'] ? ', ' + this.productIDToProductProps[passID].doclet.data['size'] : ''
				}
				unsortedDisplayItems[trackingKey][passID] = {
					eventID: cartItem.eventID,
					strYYYYMMDD: ticketYYYYMMDD1, // 'event' | 'any' | YYYY-MM-DD
					strDisplayDate: cartItem.visitDate.isEventLength
						? 'Event'
						: (
							ticketMonth1 < 1 && ticketDay < 1 // maybe the year is set, or maybe it's an "any day" ticket.
							? ((cartItem.visitDate.year ?? '') + ' any day').replace( ServiceRegex.trimRegExp, '' )
							: (ticketMonth1 > 0 && ticketDay < 1
								? 'Year ' + ticketYear // bundles have an issue where they have a month, but no day.
								: this.monthLabels[ ticketMonth1 - 1 ] + ' ' + ticketDay + ', ' + ticketYear
							)
						),
					strDisplayTime: '', // the .price isn't a real thing // TransformerEventTicket.getTicketTimeDisplayFromPriceType( this.productIDToProductProps[passID]?.price, ticketYYYYMMDD1 ),
					productDisplayName: this.productIDToProductProps[passID].name + colorAndSize,
					lineItemSubTotal: 0,
					locationID: [],
					productProps: []
				};
			}
			const soldCount: number = this.productAvailability?.[ticketYYYYMMDD1]?.[passID]?.item_count ?? 0;
			const strTargetDate: string = TransformerDate.strYYYYMMDDFromYMDInts( ticketYear, ticketMonth1, ticketDay );
			const price: number = TransformerEventPasses.getEventPassPriceV2( this.productIDToProductProps[passID].doclet, strPurchaseDate, strTargetDate, soldCount );
			unsortedDisplayItems[trackingKey][passID].lineItemSubTotal += price; // this FUBARs the precision, so this needs to be transformed once, after this is over. // <number>.toFixed( 2 )
			const productProps: InterfaceDisplayLineItemsProductProps = {
				dob: undefined,
				errors: {},
				first_name: undefined,
				heldTicketID: cartItem.ticketID,
				last_name: undefined,
				productDocletID: passID,
				productName: this.productIDToProductProps[passID].name // seems like this ought to of been on the layer above, but we didn't have <ticket> yet, only line item with an array of tickets.
			};
			if ( this.productIDToTicketProps[passID].dataFirstLastName ) {
				productProps.first_name = '';
				productProps.last_name = '';
				productProps.errors.first_name = false;
				productProps.errors.last_name = false;
			}
			if ( this.productIDToTicketProps[passID].dataDOB ) {
				productProps.dob = '';
			}
			unsortedDisplayItems[trackingKey][passID].productProps.push( productProps );
		} // end for each line item in the cart.
		const trackingKeys: string[] = Object.keys( unsortedDisplayItems );
		trackingKeys.sort( (A: string, B: string): number => {
			return ServiceSorting.naturalSort( A, B );
		} );
		// tickets are sorted by date. (date ASC)
		for ( let x: number = 0; x < trackingKeys.length; ++x ) {
			const trackingKey: string = trackingKeys[x];
			const productIDs: string[] = Object.keys( unsortedDisplayItems[trackingKey] );
			productIDs.sort( (A: string, B: string): number => {
				if ( this.productIDToProductProps[A].sort === this.productIDToProductProps[B].sort ) {
					return ServiceSorting.naturalSort( unsortedDisplayItems[trackingKey][A].productDisplayName, unsortedDisplayItems[trackingKey][B].productDisplayName );
				}
				//
				return this.productIDToProductProps[A].sort - this.productIDToProductProps[B].sort;
			} );
			for ( let y: number = 0; y < productIDs.length; ++y ) {
				unsortedDisplayItems[trackingKey][productIDs[y]].lineItemSubTotal = Number( unsortedDisplayItems[trackingKey][productIDs[y]].lineItemSubTotal.toFixed( 2 ) );
				this.productListing.push( {
					passID: productIDs[y],
					eventID: unsortedDisplayItems[trackingKey][productIDs[y]].eventID,
					strYYYYMMDD: unsortedDisplayItems[trackingKey][productIDs[y]].strYYYYMMDD,
					strDisplayDate: unsortedDisplayItems[trackingKey][productIDs[y]].strDisplayDate,
					strDisplayTime: unsortedDisplayItems[trackingKey][productIDs[y]].strDisplayTime ?? '',
					passDisplayName: unsortedDisplayItems[trackingKey][productIDs[y]].productDisplayName,
					passProps: unsortedDisplayItems[trackingKey][productIDs[y]].productProps,
					lineItemSubTotal: unsortedDisplayItems[trackingKey][productIDs[y]].lineItemSubTotal,
					isDiscounted: false,
					discountAmount: 0,
					locationID: Array.isArray( unsortedDisplayItems[trackingKey][productIDs[y]].locationID ) ? unsortedDisplayItems[trackingKey][productIDs[y]].locationID : []
				} );
				this.subTotal += unsortedDisplayItems[trackingKey][productIDs[y]].lineItemSubTotal;
			}
		}
		this.subTotal = Number( this.subTotal.toFixed( 2 ) );
		this.grandTotal = Number( (this.subTotal - this.discounts).toFixed( 2 ) );
	}

	public ngOnInit(): void {
		try {
			const _gtag: Function = (window as any).gtag as Function;
			_gtag( 'config', this.appConfig.getGTagID(), {
				'page_path': '/' + this.routes.checkout
			} );
		} catch ( fail ) {
			//
		}
		ServiceCart.sortByDate();
		this.fetchProducts( (): void => { // part 1 - grab passes for sale, based upon only what's in the cart.
			this.fetchAvailability( (): void => { // part 2 - fetch availability because it's needed to determine prices.
				this.buildDisplayData();
				this.displayReady = true;
				if ( this.promoCode ) {
					this.useDiscountCode();
				}
			} );
		} );
	}

	public ngOnDestroy(): void {
		if ( this.subPromoCodeChanged ) {
			this.subPromoCodeChanged.unsubscribe();
			this.subPromoCodeChanged = null;
		}
		if ( this.subUserReSync ) {
			this.subUserReSync.unsubscribe();
			this.subUserReSync = null;
		}
		if ( this.subWorkspaceChanged ) {
			this.subWorkspaceChanged.unsubscribe();
			this.subWorkspaceChanged = null;
		}
	}

	public showTinySignIn(): void {
		ServiceAppEvents.broadcast( 'modal:open:tiny-sign-in' );
	}

	private reCalcDisplayData(): void { // the loop for digging up user-weaves calls this fn when it's done checking on season passes. (once per check)
		console.trace( 'reCalcDisplayData() called' ); // something was calling this millions of times a second.
		// ===== reset everything ===== //
		this.grandTotal = 0;
		this.subTotal = 0; // sub-total does not include a discount, it is pre-discount, etc.
		this.discounts = this.discountedItems.reduce( (totalDiscounts: number, promoItems: InterfaceOWAPIPromoCodeItems): number => {
			return totalDiscounts + promoItems.items.reduce( (amountOff: number, item: InterfaceOWAPIPromoCodeItemsItem): number => {
				return amountOff + Number( item.discount_applied );
			}, 0 ); // the total amount off in discounts is already known, because we have the server-side results of what to discount...
		}, 0 ); // only issue is going to be mapping it back onto what item was discounted.
		for ( let x: number = 0; x < this.productListing.length; ++x ) {
			this.productListing[x].lineItemSubTotal = 0;
			this.productListing[x].isDiscounted = false;
			this.productListing[x].discountAmount = 0;
		}
		// ============================ //
		// figure out discounts
		if ( this.discountedItems.length === this.productListing.length ) {
			for ( let x: number = 0; x < this.discountedItems.length; ++x ) {
				// TODO: sanity check - ensure the this.productListing[x].passID matches the this.discountedItems[x].items[???].doclet_id
				this.productListing[x].discountAmount = this.discountedItems[x].items.reduce( (acc: number, item: InterfaceOWAPIPromoCodeItemsItem): number => {
					return acc + Number( item.discount_applied );
				}, 0 );
				this.productListing[x].isDiscounted = this.productListing[x].discountAmount > 0;
			}
		} else { // else the discounted items has not yet created, or something else is wrong.
			console.log( 'amount of items vs discounted amount mis-matched...', this.discountedItems.length, 'vs', this.productListing.length );
			// this is OK, during the time we're waiting on the API for discount data.
			// otherwise it's a bug :S
			// if there's no promo code, then ignore all this..?
		}
		// figure out line item total, accumulate subTotal
		const d: Date = new Date();
		const strPurchaseDate: string = TransformerDate.strYYYYMMDDFromYMDInts( d.getFullYear(), 1 + d.getMonth(), d.getDate() );
		for ( let x: number = 0; x < this.productListing.length; ++x ) {
			const passID: string = this.productListing[x].passID;
			const soldCount: number = this.productAvailability?.[this.productListing[x].strYYYYMMDD]?.[passID]?.item_count ?? 0;
			const strTargetDate: string = this.productListing[x].strYYYYMMDD; // TODO: determine if the YYYY-MM-DD is the phrase 'event' or 'any' and it breaks things.
			let originalPrice: number = TransformerEventPasses.getEventPassPriceV2( this.productIDToProductProps[this.productListing[x].passID].doclet, strPurchaseDate, strTargetDate, soldCount );
			// line item total is just qty * cost. subTotal is all line item totals, before discounts.
			this.productListing[x].lineItemSubTotal = Number( (this.productListing[x].passProps.length * originalPrice).toFixed( 2 ) );
			this.subTotal = Number( (this.productListing[x].passProps.length * originalPrice + this.subTotal).toFixed( 2 ) );
			if ( this.productListing[x].isDiscounted ) {
				this.productListing[x].lineItemSubTotal = Number( (this.productListing[x].lineItemSubTotal - this.productListing[x].discountAmount).toFixed( 2 ) );
			}
		}
		this.grandTotal = Number( (this.subTotal - this.discounts).toFixed( 2 ) );
	}

	public validateDisplayItem( item: any, key: string ): void {
		if ( key in item && typeof item[key] === 'string' ) {
			item[key] = item[key].replace( ServiceRegex.trimRegExp, '' );
			if ( 'errors' in item && key in item['errors'] ) {
				item['errors'][key] = item[key].length < 1;
			}
		}
	}

	private fetchUserInfo(): void {
		this.colProfiles.getMyUserProfile( (userData: InterfaceOWUser | null): void => {
			if ( userData?._id ) {
				this.userProfile = userData;
				this.accountData.email = userData.email;
				if ( this.userProfile?.profile ) {
					this.accountData.firstName = this.userProfile.profile?.first_name ?? '';
					this.accountData.lastName = this.userProfile.profile?.last_name ?? '';
				}
			}
		} );
	}

	public validateAccData( key: keyof InterfaceAccountData ): void {
		if ( key !== 'password1' && key !== 'password2' && key !== 'cashlessSpending' ) {
			this.accountData[key] = this.accountData[key].replace( ServiceRegex.trimRegExp, '' );
		}
		switch ( key ) {
			case 'firstName': {
				this.accountErrors.firstName = this.accountData.firstName.length < 1;
				break;
			}
			case 'lastName': {
				this.accountErrors.lastName = this.accountData.lastName.length < 1;
				break;
			}
			case 'email': {
				this.accountData.email = this.accountData.email.toLowerCase();
				this.accountErrors.email = !ServiceRegex.emailRegExp.test( this.accountData.email );
				break;
			}
			case 'password1': {
				this.accountErrors.password1 = this.accountData.password1.length < 1;
				if ( this.accountData.password2.length > 0 ) {
					this.accountErrors.password2 = this.accountData.password1 !== this.accountData.password2;
					this.accountErrors.password1 = this.accountData.password1 !== this.accountData.password2;
				}
				break;
			}
			case 'password2': {
				this.accountErrors.password2 = this.accountData.password2.length < 1;
				if ( this.accountData.password1.length > 0 ) {
					this.accountErrors.password1 = this.accountData.password2 !== this.accountData.password1;
					this.accountErrors.password2 = this.accountData.password2 !== this.accountData.password1;
				}
				break;
			}
		}
	}

	public validateCardData( key: keyof InterfaceCheckoutData['card'] ): void {
		const cartNow: Date = new Date();
		this.checkoutData.card[key] = this.checkoutData.card[key].replace( ServiceRegex.trimRegExp, '' );
		switch ( key ) {
			case 'name': {
				this.checkoutErrors.card.name = this.checkoutData.card.name.length < 1;
				break;
			}
			case 'number': { // card numbers are 13 - 16 digits in length.
				this.checkoutErrors.card.number = !this.validateCCNum( this.checkoutData.card.number );
				break;
			}
			case 'expMM': {
				const intMM: number = parseInt( this.checkoutData.card.expMM, 10 );
				// if not a valid month.
				this.checkoutErrors.card.expMM = !!this.checkoutData.card.expMM.match( /^\d\d$/ ) || isNaN( intMM ) || intMM < 1 || intMM > 12;
				// or if expired...
				if ( this.checkoutData.card.expYYYY.length > 0 ) {
					let intYYYY: number = parseInt( this.checkoutData.card.expYYYY, 10 );
					if ( intYYYY < 100 ) {
						intYYYY += Math.floor( cartNow.getFullYear() / 100 ) * 100; // floor(2377/100) = 23. 23 * 100 = 2300.
					}
					this.checkoutErrors.card.expMM = intYYYY < cartNow.getFullYear() || intYYYY === cartNow.getFullYear() && intMM < cartNow.getMonth() + 1;
					this.checkoutErrors.card.expYYYY = this.checkoutErrors.card.expMM;
				}
				break;
			}
			case 'expYYYY': {
				let intYYYY: number = parseInt( this.checkoutData.card.expYYYY, 10 );
				if ( intYYYY < 100 ) {
					intYYYY += Math.floor( cartNow.getFullYear() / 100 ) * 100; // floor(2377/100) = 23. 23 * 100 = 2300.
				}
				// if not a valid year...
				this.checkoutErrors.card.expYYYY = !!this.checkoutData.card.expYYYY.match( /^\d\d$|^\d\d\d\d$/ ) || isNaN( intYYYY ) || intYYYY < cartNow.getFullYear();
				// or if expired...
				if ( this.checkoutData.card.expMM.length > 0 ) {
					const intMM: number = parseInt( this.checkoutData.card.expMM, 10 );
					this.checkoutErrors.card.expYYYY = intYYYY < cartNow.getFullYear() || intYYYY === cartNow.getFullYear() && intMM < cartNow.getMonth() + 1;
					this.checkoutErrors.card.expMM = this.checkoutErrors.card.expYYYY;
				}
				break;
			}
			case 'cvv': {
				this.checkoutErrors.card.cvv = this.checkoutData.card.cvv.length < 1; // 3 or 4, possibly other variants.
				break;
			}
		}
	}

	public validateBillingData( key: keyof InterfaceCheckoutData['billing'] ): void {
		this.checkoutData.billing[key] = this.checkoutData.billing[key].replace( ServiceRegex.trimRegExp, '' );
		switch ( key ) {
			case 'street': {
				this.checkoutErrors.billing.street = this.checkoutData.billing.street.length < 1;
				break;
			}
			case 'city': {
				this.checkoutErrors.billing.city = this.checkoutData.billing.city.length < 1;
				break;
			}
			case 'state': {
				this.checkoutErrors.billing.state = this.checkoutData.billing.state.length < 2;
				break;
			}
			case 'zip': {
				this.checkoutErrors.billing.zip = this.checkoutData.billing.zip.match( /^\d\d\d\d\d/ ) === null; // "12345" or "12345-6789" is OK...
				break;
			}
		}
	}

	public checkIfEmailAlreadyExist(): void {
		const email: string = this.accountData.email.toLowerCase();
		if ( ServiceRegex.emailRegExp.test( email ) ) {
			this.owapi.account.registration.checkIfEmailExists( this.appConfig.getContext(), email ).subscribe( (response: InterfaceHTTPGateway): void => {
				if ( response && !response.success && response.status === 409 ) {
					this.cacheEmailAlreadyInUse[ email ] = true;
					if ( this.accountData.email.toLowerCase() === email ) {
						this.accountErrors.email = true;
						this.emailIsInUse = true;
					}
				}
			} );
		}
	}

	public liabilityCheckboxChanged( checked: boolean ): void {
		this.liabilityAgreementChecked = checked;
	}

	public termsAndConditionsChanged( checked: boolean ): void {
		this.termsAndConditionsChecked = checked;
	}

	private checkoutFailed( reason: string ): void {
		this.haveCheckoutErrors = true;
		this.isCheckingOut = false;
		this.busy = false;
		this.declinedReason = reason; // 'Declined: something something'
	}

	private packUpOrderedItems(): InterfaceOWAPIOrderItems[] {
		// TODO: figure out how to make the date 'event' and group up items this way, etc.
		const output: InterfaceOWAPIOrderItems[] = [];
		for ( let x: number = 0; x < this.productListing.length; ++x ) {
			const items: InterfaceOWAPIOrderItems['items'] = [];
			for ( let y: number = 0; y < this.productListing[x].passProps.length; ++y ) {
				const passPropsDisplay: InterfaceDisplayLineItemsProductProps = this.productListing[x].passProps[y];
				const dataProps: {
					assigned_first_name?: string;
					assigned_last_name?: string;
					assigned_dob?: string;
					time?: string; // either undefined or 'HH:MM:SS'
				} = {};
				// if the pass to buy is a type where it needs a first/last name assigned to it.
				if ( this.productIDToTicketProps[ passPropsDisplay.productDocletID ].dataFirstLastName ) {
					dataProps.assigned_first_name = passPropsDisplay.first_name;
					dataProps.assigned_last_name = passPropsDisplay.last_name;
				}
				// if the pass to buy is a type where it wants a DoB assigned to it, that may not exist.
				if ( this.productIDToTicketProps[ passPropsDisplay.productDocletID ].dataDOB ) {
					dataProps.assigned_dob = passPropsDisplay.dob ? passPropsDisplay.dob : '';
				}
				dataProps.time = ''; // the .price isn't a real thing // TransformerEventTicket.getTicketTimeFromPriceType( this.productIDToProductProps[ passPropsDisplay.productDocletID ].price, this.productListing[x].strYYYYMMDD );
				items.push( {
					doclet_id: passPropsDisplay.productDocletID,
					event_id: this.productListing[x].eventID,
					item_id: passPropsDisplay.heldTicketID,
					source: 'web',
					data: dataProps
				} );
			}
			//
			output.push( {
				date: // TODO: 'event' vs 'any' vs 'YYYY-MM-DD'
					this.productListing[x].strYYYYMMDD.match( /-00-00$/ ) ? 'any' : this.productListing[x].strYYYYMMDD, // YYYY-MM-DD. month is 01 to 12.
				items: items
			} );
		} // end for each product (cart items + consumer data) to pack up.
		return output;
	}

	private submitOrder( paymentInfo: InterfaceOWAPIOrderPaymentInfo ): void {
		// this is part 2. submitCart is part 1.
		const cardDetailsRequired: boolean = this.grandTotal > 0 || (this.accountData.cashlessSpending && !(this.grandTotal > 0));
		const orderItems: InterfaceOWAPIOrderItems[] = this.packUpOrderedItems();
		this.owapi.workspace.actions.core.submitOrder( this.appConfig.getContext(), orderItems, paymentInfo, {
			captcha_token: this.reCaptchaUserToken,
			cashless_spending: cardDetailsRequired ? this.accountData.cashlessSpending : false,
			profile_id: this.usersProfileIDForCart as string,
			promo_code: this.promoCode !== null && this.promoCode.length > 0 ? this.promoCode.toUpperCase() : undefined,
			kvp: this.queryKVP
		} ).subscribe( (response: InterfaceHTTPGateway): void => {
			this.busy = false;
			this.isCheckingOut = false;
			console.log( 'Checked Out', window.location, response );
			if ( response?.status === 0 ) {
				console.log( 'Browser refused to send the network request. giving up.' );
			}
			if ( response && response.success && response.status === 200 ) {
				const apiResponse: InterfaceOWAPIOrderResponse = response.data;
				if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) && apiResponse.data.items.length > 0 ) {
					const orderResponse: InterfaceOWAPIOrderResponseData = apiResponse.data.items.shift() as InterfaceOWAPIOrderResponseData; // .pop_front()
					if ( orderResponse && orderResponse.success ) {
						// ===== checkout successful ===== //
						// regardless of all the junk, we fire-and-forget after this point,
						// we must always move onwards to the thank-you page.
						// ... no failing allowed ...
						// =============================== //
						this.queryKVP = {};
						localStorage.removeItem( 'ryst-kvp' );
						const cartStats: InterfaceSerializedCart = ServiceCart.getSerializedCart();
						const orderID: string = orderResponse.order_id;
						this.owapi.workspace.actions.core.recordResourceUse( this.appConfig.getContext(), this.routes.checkout, 'click', {
							cartStats: {
								orderID: orderID,
								promoCode: cartStats.promoCode,
								serializedCart: cartStats.cartData
							},
							eventID: this.selectedEventID,
							isSignedIn: this.auth.isSignedIn(),
							profileID: this.auth.getProfileID(),
							tracker: this.trackingCookie
						} ).subscribe( (_: InterfaceHTTPGateway): void => {} ); // fire and forget
						try {
							const _gtag: Function = (window as any).gtag as Function;
							_gtag( 'event', 'conversion', {
								'send_to': this.appConfig.getGTagID(),
								'event_category': 'checkout',
								'event_label': 'form_submission'
							} );
						} catch ( _ ) {}
						ServiceCart.clear();
						this.router.navigateByUrl( '/' + this.routes.thankYou, {
							replaceUrl: true,
							state: {
								'data-type': 'order-data',
								'data': {
									'orderID': orderID
								}
							}
						} ).then( (navigated: boolean): void => {
							if ( !navigated ) { // if it failed, kick the user to the next page.
								window.location.href = '/' + this.routes.thankYou;
							}
						} );
					} else {
						if ( 'captcha_status' in orderResponse && !orderResponse.captcha_status ) {
							this.checkoutFailed( 'Please select "I\'m not a robot" to prove you are human.' );
						} else if ( Array.isArray( orderResponse?.unavailable ) && orderResponse.unavailable.length > 0 ) {
							this.checkoutFailed( 'One or more items in your order are no longer for sale.' );
							this.cartHeldIDsFatalError = true; // the user can't change them out on this page. they're screwed.
						} else {
							this.checkoutFailed( orderResponse.payment_reason ? orderResponse.payment_reason : 'Please try again later.' );
						}
					}
				} else {
					// Please try again later.
				}
			} else { // not a 200
				this.checkoutFailed( 'Please try again later.' );
				this.owapi.workspace.actions.core.recordResourceUse( this.appConfig.getContext(), this.routes.checkout, 'checkout-error', {
					source: 'Web',
					url: window.location.toString(),
					response: response
				} ).subscribe( (_: InterfaceHTTPGateway): void => {} ); // fire and forget
			}
		} );
	}

	public submitCart(): void {
		// this is part 1. submitOrder is part 2
		if ( !this.busy ) {
			if ( !this.isSignedIn && this.emailIsInUse ) {
				this.checkoutFailed( 'Email is already in use.' );
				return;
			}
			this.haveCheckoutErrors = false;
			if ( this.auth.isSignedIn() ) {
				this.usersProfileIDForCart = this.auth.getProfileID();
				if ( this.usersProfileIDForCart === null ) {
					// can't fix this problem...
					console.error( 'Error checking out. Your profile is not valid.' );
					return;
				}
			}
			// ===== Validation ===== //
			let haveErrors: boolean = false;
			this.tmpTicketErrors = false;
			//
			for ( let x: number = 0; x < this.productListing.length; ++x ) {
				for ( let y: number = 0; y < this.productListing[x].passProps.length; ++y ) {
					const errorKeys: (keyof InterfaceDisplayLineItemsProductProps['errors'])[] = Object.keys( this.productListing[x].passProps[y].errors ) as (keyof InterfaceDisplayLineItemsProductProps['errors'])[];
					for ( let z: number = 0; z < errorKeys.length; ++z ) {
						this.validateDisplayItem( this.productListing[x].passProps[y], errorKeys[z] );
					}
					for ( let z: number = 0; !haveErrors && z < errorKeys.length; ++z ) {
						if ( this.productListing[x].passProps[y].errors[ errorKeys[z] ]) {
							haveErrors = true;
							console.error( 'Error checking out. A product is missing required customer information.' );
							this.tmpTicketErrors = true;
						}
					}
				}
			}
			//
			if ( !this.isSignedIn ) {
				// if the user is signed in, don't try to validate fields they no longer can access.
				const accKeys: string[] = Object.keys( this.accountErrors );
				for ( let x: number = 0; x < accKeys.length; ++x ) {
					this.validateAccData( accKeys[x] as keyof InterfaceAccountErrors );
				}
				for ( let x: number = 0; x < accKeys.length; ++x ) {
					if ( this.accountErrors[ accKeys[x] as keyof InterfaceAccountErrors ] ) {
						haveErrors = true;
						console.error( 'Error checking out. New account information is invalid.' );
					}
				}
			}
			// always validate billing info
			const billingErrors: string[] = Object.keys( this.checkoutErrors.billing );
			for ( let x: number = 0; x < billingErrors.length; ++x ) {
				// basically, if the field is a string-type, and it's empty, it's an error.
				// otherwise it skips the validation completely...
				this.validateBillingData( billingErrors[x] as keyof InterfaceCheckoutErrors['billing'] );
			}
			for ( let x: number = 0; x < billingErrors.length; ++x ) {
				if ( this.checkoutErrors.billing[ billingErrors[x] as keyof InterfaceCheckoutErrors['billing'] ] ) {
					haveErrors = true;
					console.error( 'Error checking out. Billing information is invalid.' );
				}
			}
			// always validate CC info, unless the grand total is zero.
			const cardDetailsRequired: boolean = this.grandTotal > 0 || (this.accountData.cashlessSpending && !(this.grandTotal > 0));
			const cardErrors: string[] = Object.keys( this.checkoutErrors.card );
			for ( let x: number = 0; x < cardErrors.length; ++x ) {
				this.validateCardData( cardErrors[x] as keyof InterfaceCheckoutErrors['card'] );
			}
			if ( cardDetailsRequired ) {
				for ( let x: number = 0; x < cardErrors.length; ++x ) {
					if ( this.checkoutErrors.card[ cardErrors[x] as keyof InterfaceCheckoutErrors['card'] ] ) {
						haveErrors = true;
						console.error( 'Error checking out. Credit card information is invalid.' );
					}
				}
			}

			if ( haveErrors ) {
				return;
			}

			const paymentInfo: InterfaceOWAPIOrderPaymentInfo = {
				action: 'CHARGE',
				billing_address: this.checkoutData.billing.street,
				billing_suite: this.checkoutData.billing.unit,
				billing_city: this.checkoutData.billing.city,
				billing_state: this.checkoutData.billing.state,
				billing_zip: String( this.checkoutData.billing.zip ),
				card_number: cardDetailsRequired ? String( this.checkoutData.card.number ) : '',
				card_exp_month: cardDetailsRequired ? String( this.checkoutData.card.expMM ) : '',
				card_exp_year: cardDetailsRequired ? String( this.checkoutData.card.expYYYY ) : '', // will be 2 digits or 4...
				card_cvv: cardDetailsRequired ? String( this.checkoutData.card.cvv ) : ''
			};
			if ( cardDetailsRequired && paymentInfo.card_exp_year.length < 3 ) {
				paymentInfo.card_exp_year = String( new Date().getFullYear() ).slice( 0, 2 ) + paymentInfo.card_exp_year;
			}

			this.busy = true;
			this.isCheckingOut = true;

			// don't use if(thing){ because JS said NULL was truthy..wtf
			if ( this.usersProfileIDForCart === null || this.usersProfileIDForCart.length < 1 ) {
				this.owapi.account.registration.register( // 6 params
					this.appConfig.getContext(),
					this.accountData.email,
					this.accountData.password1, // param #3
					this.strConsumerTemplateID,
					{ // workspace/app Consumer fields
						first_name: this.accountData.firstName,
						last_name: this.accountData.lastName
						// don't have anything else. need street, city, state, zip, etc.. but not from this. (it may be someone else's billing info)
					},
					{ // ow_user Profile fields.
						first_name: this.accountData.firstName,
						last_name: this.accountData.lastName
					}
				).subscribe( (response: InterfaceHTTPGateway): void => {
					if ( response && response.success ) { // success will be true, even if it s 409, 500, 400, 200 etc.
						if ( response.status === 201 ) {
							const apiResponse: InterfaceOWAPISignUpResponse = response.data;
							if ( apiResponse && apiResponse.data && apiResponse.data.profile_id ) {
								this.usersProfileIDForCart = apiResponse.data.profile_id;
								this.submitOrder( paymentInfo );
							} else {
								this.checkoutFailed( 'Please try again later.' );
							}
						} else if ( response.status === 409 ) {
							this.emailIsInUse = true;
							this.cacheEmailAlreadyInUse[this.accountData.email] = true;
							this.checkoutFailed( 'Email is already in use.' );
						}
					} else {
						this.checkoutFailed( 'Please try again later.' );
					}
				} );
			} else {
				this.submitOrder( paymentInfo );
			}
		}
	}

	public validateCCNum( ccNum: string ): boolean {
		let ccType: string = '';
		let isValid: boolean = false;
		if ( ccNum.length > 14 ) {
			switch ( ccNum.charAt( 0 ) ) {
				// all credit card info here: http://www.iinbase.com/
				case '3': { // American Express
					if ( ccNum.charAt( 1 ) === '4' || ccNum.charAt( 1 ) === '7' ) { // 34 or 37
						ccType = 'American Express';
						isValid = ccNum.length === 16 || ccNum.length === 15; // Amex has both 15 and 16 digit card numbers.
					} // system identifier, type, currency, account number, a check digit
					break; // AMEX: SSTCAAAAAAAAAAC
				}
				case '4': { // Visa
					ccType = 'Visa';
					isValid = ccNum.length === 16; // All 3 other cards, use the 16 digit schema
					break; // SSTCAAAAAAAAAAC
				} // system identifier, issuer/bank identifier, bank number, account number, a check digit
				case '5': { // Master Card
					if ( ccNum.charAt( 1 ) > '0' && ccNum.charAt( 1 ) < '6' ) { // 51 to 55
						ccType = 'Master Card';
						isValid = ccNum.length === 16;
					}
					break;
				}
				case '6': { // Discover Card
					if ( ccNum.charAt( 1 ) === '0' && ccNum.charAt( 2 ) === '1' && ccNum.charAt( 3 ) === '1' ) { // 6011
						ccType = 'Discover Card';
						isValid = ccNum.length === 16;
					} // see: https://www.discovernetworkvar.com/common/pdf/var/10-1_VAR_ALERT_April_2010.pdf
					break;
				}
			}
		} // end if CC length is at least 15.
		return isValid;
	}

	public clearDiscountCodeError(): void {
		this.invalidPromoCode = false;
	}

	public useDiscountCode(): void {
		this.invalidPromoCode = false;
		if ( this.promoCode === null || this.promoCode.length < 1 ) {
			return;
		}
		const pCode: string = this.promoCode;
		this.owapi.workspace.actions.core.checkPromoCode( this.appConfig.getContext(), this.packUpOrderedItems(), pCode ).subscribe( (response: InterfaceHTTPGateway): void => {
			let failed: boolean = true;
			if ( response && response.success ) {
				const apiResponse: InterfaceOWAPIPromoCodeResponse = response.data;
				if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ) {
					failed = false;
					const items: InterfaceOWAPIPromoCodeItems[] = apiResponse.data.items;
					if ( items.length > 0 && items[0] && items[0].error ) {
						this.invalidPromoCode = true;
					} else {
						ServiceCart.setPromoCode( pCode );
						this.discountedItems = items;
						// TODO: erase the text inside the input, once we have a listing of promo codes to display...
						// this.promoCode = ''; // the text inside the input.
					}
				}
			}
			if ( failed ) {
				this.invalidPromoCode = true;
			}
			this.reCalcDisplayData();
		} );
	}

	public cashlessToggle( b: boolean ): void {
		this.accountData.cashlessSpending = b;
	}

	public reCaptchaSetToken( token: string | null ): void {
		this.reCaptchaUserToken = typeof token === 'string' ? token : '';
		// console.log( 'reCaptcha user token', token );
	}

	public reCaptchaErrored( nothing: any ): void {
		// this doesn't fire when you think it should. don't use this.
		console.log( 'reCaptcha errored', nothing );
	}
}
