import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppRouterLinks} from '../../app.router-links';
// ===== Interfaces ===== //
import {
	InterfaceAddToCart,
	InterfaceAppEvent,
	InterfaceDocletIDToTicketProps,
	InterfaceEventEntitlementLock,
	InterfaceEventEntitlementUnlock,
	InterfaceEventProductAvailability,
	InterfaceEventWorkspaceChanged,
	InterfaceHTTPGateway,
	InterfaceObjectId,
	InterfaceOWAPIEventProductAvailabilityResponse,
	InterfaceOWAPIGetDocletResponse,
	InterfaceOWAPIGetEventPassActionResponse,
	InterfaceOWAPIGetEventProductsActionResponse,
	InterfaceOWDoclet,
	InterfaceOWDocletWithEntitlement,
	InterfaceOWTemplateEventMerchandise,
	InterfaceOWTemplateEventPass,
	InterfaceOWTemplateEventProduct,
	InterfaceOWTemplateVenueEvent
} from '../../../../../../ow-framework/interfaces/interfaces';
interface InterfaceRoles {
	admin: string;
	pos: string;
	staff: string;
	web: string;
}
interface InterfaceYYYYMM1DD {
	year: number;
	month1: number; // 1 though 12
	day: number; // 1 through 31.
}
interface InterfaceMerchandiseByNameColorSize {
	[name: string]: {
		byColor: {
			[color: string]: {
				bySize: {
					[strSize: string]: InterfaceOWDoclet<InterfaceOWTemplateEventMerchandise>;
				};
				photo: string | undefined;
				sizes: string[]; // these are the keys found in bySize
				selectedSize: string;
			};
		};
		colors: string[]; // these are the keys found in byColor
	};
}
// ===== Services ===== //
import {ServiceAppEvents} from '../../../../../../ow-framework/services/app-events';
import {ServiceAuthentication} from '../../../../../../ow-framework/services/authentication';
import {ServiceCart} from '../../../../../../ow-framework/services/cart';
import {ServiceOWAPI} from '../../../../../../ow-framework/services/ow-api';
import {ServiceRegex} from '../../../../../../ow-framework/services/regex';
import {ServiceSorting} from '../../../../../../ow-framework/services/sorting';
// ===== Transformers ===== //
import {TransformerDate} from '../../../../../../ow-framework/transformers/date';
import {TransformerEventPasses} from '../../../../../../ow-framework/transformers/event-passes';
import {TransformerEventProductBase} from '../../../../../../ow-framework/transformers/event-product-base';
//
@Component( {
	selector: 'page-tickets',
	templateUrl: './tickets.html',
	styleUrls: [
		'./tickets.less'
	]
} )
export class PageTickets implements OnDestroy, OnInit {
	private readonly selectedEventID: string = '67c655b13deb2858c5998a89'; // Framework's block party
	public readonly routes: typeof AppRouterLinks = AppRouterLinks;
	public isSignedIn: boolean = false;
	public busy: boolean = false; // true when we're doing things that need to be in order/synchronous.
	// ===== Text, Strings, Content ===== //
	public readonly strAddToCart: string = 'Select'; // 'Add To Cart';
	public readonly strSelectDate: string = 'Select Date';
	// ===== Ticket Availability ===== //
	public readonly productAvailability: InterfaceEventProductAvailability = {}; // [YYYY-MM-DD] : { location: int } // MM is 01 - 12
	public passAvailabilityLoaded: boolean = false; // both passes and merch availability are sharing the product availability var.
	public merchAvailabilityLoaded: boolean = false;
	// ===== Role IDs ===== //
	private readonly strWebRole: string = this.appConfig.getRoleID( 'Web' );
	private readonly roleIDs: InterfaceRoles = {
		admin: this.appConfig.getRoleID( 'Admin' ),
		pos: this.appConfig.getRoleID( 'POS' ),
		staff: this.appConfig.getRoleID( 'Staff' ),
		web: this.appConfig.getRoleID( 'Web' )
	};
	// ===== product property cache ===== //
	public readonly passIDToPassProps: { [passID: string]: InterfaceDocletIDToTicketProps<InterfaceOWTemplateEventProduct>; } = {};
	// ===== Passes For Sale ===== //
	public multiUsePasses: InterfaceOWDoclet<InterfaceOWTemplateEventPass>[] = [];
	public singleUsePasses: InterfaceOWDoclet<InterfaceOWTemplateEventPass>[] = [];
	public addOnPasses: InterfaceOWDoclet<InterfaceOWTemplateEventPass>[] = [];
	// ===== Merchandise For Sale ===== //
	public merchandise: InterfaceMerchandiseByNameColorSize = {};
	public merchandiseNames: string[] = []; // these are the keys found in this.merchandise
	private merchIDs: { [docletID: string]: true; } = {}; // an indexed/map thing used for fast logic.
	public merchAddOns: InterfaceOWDoclet<InterfaceOWTemplateEventMerchandise>[] = [];
	// ===== Cart Items, Ticket Selection, Holdings, etc ===== //
	public showAddOverlay: boolean = false;
	public overlayBusy: boolean = false;
	public overlaySelectedProduct: InterfaceOWDoclet<InterfaceOWTemplateEventProduct> | undefined = undefined; // this should always exist, if the merch/pass are populated.
	public overlaySelectedMerch: InterfaceOWDoclet<InterfaceOWTemplateEventMerchandise> | undefined = undefined; // both the Product and the Merch should be assigned the same value.
	public overlaySelectedPass: InterfaceOWDoclet<InterfaceOWTemplateEventPass> | undefined = undefined; // both the Product and the Pass should be assigned the same value.
	public readonly overlayTicketSelection: { // this is a temp setup. it doesn't account for unique location IDs or anything else really.
		[passID: string]: number; // { <passID>: <qty> }
	} = {};
	public overlayTicketLocks: { // { <passID>: { "event" : [ id1, id2, ... ], { "any" : [ ... ], "2025-01-01" : [ ... ] } }
		[passID: string]: {
			[strDate: string]: string[];
		};
	} = {};
	public grandTotal: number = 0; // the cart won't hold the price tags of things, just their IDs and quantity.
	// public discountAmount: number = 0;
	// ===== Discount Codes ===== //
	private subPromoCodeChanged: Subscription | null = null;
	public promoCode: string | null = null;
	public invalidPromoCode: boolean = false;
	// ===== Calendar ===== //
	public readonly monthLabels: string[] = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
	private readonly calStartDate: Date = new Date( 2025, 2, 15, 0, 0, 0, 0 ); // new Date( now );
	private readonly calStopDate: Date = new Date( 2025, 2, 15, 23, 59, 59, 999 ); // TransformerDate.getEndOfRelativeMonth( now, 2 );
	public readonly calendarRangeStart: InterfaceYYYYMM1DD = {
		year: 2025, // this.calStartDate.getFullYear(),
		month1: 3, // 1 + this.calStartDate.getMonth(),
		day: 15// this.calStartDate.getDate()
	};
	public readonly calendarRangeEnd: InterfaceYYYYMM1DD = {
		year: 2025, //this.calStopDate.getFullYear(),
		month1: 3, //1 + this.calStopDate.getMonth(),
		day: 15 // this.calStopDate.getDate()
	};
	// UI States
	public didPickDate: boolean = true;
	public didPickQty: boolean = false;
	public selectedDate: InterfaceYYYYMM1DD = {
		year: 2025, // now.getFullYear(),
		month1: 3, // 1 + now.getMonth(),
		day: 15 // now.getDate()
	};
	// Copy
	public readonly copyMap: {
		[key: string]: string;
	} = {
		header: 'This is Framework',
		intro: 'Lorem ipsum odor amet, consectetuer adipiscing elit. Blandit duis neque leo praesent habitant tempus. Penatibus aptent a non lacinia senectus sodales. Curabitur bibendum nunc congue scelerisque mauris accumsan commodo ex laoreet.',
		passTerms: '',
		addonTerms: '',
		addonLegal: '',
		ticketTerms: '',
		ticketLegal: '',
		pageTitle: 'Framework Ticketing',
		merchandise: ''
	};
	private subWorkspaceChanged: Subscription | null = null;
	//
	public constructor(
		private readonly activeRoute: ActivatedRoute,
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly owapi: ServiceOWAPI,
		private readonly title: Title
	) {
		const queryKVP: { [key: string]: string; } = {};
		(window.location.search ?? '').replace( /^\?/, '' ).split( /&/i ).forEach( (kvp: string): void => {
			const arr: string[] = kvp.split( /=/g );
			if ( arr.length === 2 ) {
				queryKVP[ arr[0] ] = arr[1];
			}
		} );
		localStorage.setItem( 'ryst-kvp', JSON.stringify( queryKVP, null, 0 ) );
		this.isSignedIn = this.auth.isSignedIn();
		this.title.setTitle( this.copyMap['pageTitle'] );
		this.subPromoCodeChanged = ServiceCart.promoCodeChanged.subscribe( (): void => {
			this.promoCode = ServiceCart.getPromoCode();
			console.log( 'TODO: update displayed prices' ); // TODO: weave.ext.dates : string[]
		} );
		this.subWorkspaceChanged = ServiceAppEvents.listen( 'workspace:changed' ).subscribe( (E: InterfaceAppEvent<InterfaceEventWorkspaceChanged>): void => {
			// TODO: re-fetch a new copyMap and update everything, etc..
			this.title.setTitle( this.copyMap['pageTitle'] );
		} );
		// fetch the selected event, then the event's passes for sale, then the passes availability.
		this.fetchEventData();
		this.owapi.workspace.actions.core.recordResourceUse( this.appConfig.getContext(), AppRouterLinks.tickets, 'page', {
			eventID: this.selectedEventID,
			isSignedIn: this.isSignedIn,
			profileID: this.auth.getProfileID(),
			url: window.location.toString()
		} ).subscribe( (_:InterfaceHTTPGateway): void => {} ); // fire and forget
	}

	public ngOnInit(): void {
		if ( this.activeRoute.snapshot.params && typeof this.activeRoute.snapshot.params['promo'] === 'string' ) {
			const pCode: string = this.activeRoute.snapshot.params['promo'].replace( ServiceRegex.trimRegExp, '' );
			if ( pCode.length > 0 ) {
				ServiceCart.setPromoCode( this.activeRoute.snapshot.params['promo'] );
				console.log( 'TODO: update prices displayed' ); // but we don't know if we already fetched passes for sale or not.
			}
		}
	}

	public ngOnDestroy(): void {
		if ( this.subPromoCodeChanged ) {
			this.subPromoCodeChanged.unsubscribe();
			this.subPromoCodeChanged = null;
		}
		if ( this.subWorkspaceChanged ) {
			this.subWorkspaceChanged.unsubscribe();
			this.subWorkspaceChanged = null;
		}
	}

	private updateAvailability( availability: InterfaceEventProductAvailability ): void {
		// there is no great way to remove things from the cache.
		// currently you need to just wipe it out -- this.productAvailability = {};
		// and then re-request the date range all over again, which is expensive.
		Object.keys( availability ).forEach( (YYYYMMDD: string): void => {
			if ( !(YYYYMMDD in this.productAvailability) ) {
				this.productAvailability[YYYYMMDD] = {};
			}
			Object.keys( availability[YYYYMMDD] ).forEach( (passID: string): void => {
				this.productAvailability[YYYYMMDD][passID] = availability[YYYYMMDD][passID];
			} );
		} );
	}

	private fetchPassAvailability( passDoclets: InterfaceOWDoclet[], startDate: Date, stopDate: Date ): void {
		const arrIDs: string[] = passDoclets.map( (doclet: InterfaceOWDoclet): string => doclet._id.$oid );
		this.owapi.workspace.actions.core.getDailyAdmissionAvailabilityFromDateRangeV2(
			this.appConfig.getContext(),
			arrIDs,
			startDate,
			stopDate,
			this.selectedEventID,
			this.strWebRole
		).subscribe( (response: InterfaceHTTPGateway<InterfaceOWAPIEventProductAvailabilityResponse>): void => { // InterfaceHTTPGateway<InterfaceOWAPIEventProductAvailabilityResponse> // is correct, but fails
			if ( response?.success ) {
				const apiResponse: InterfaceOWAPIEventProductAvailabilityResponse | undefined = response.data;
				if ( (apiResponse?.data?.items ?? []).length < 1 ) {
					console.log( 'No availability from the API. Nothing to sell' );
				}
				if ( apiResponse && Array.isArray( apiResponse?.data?.items ) && apiResponse.data.items.length > 0 ) {
					// .data.items comes from a paginated setup, but there is always/only ever 1 element in the array.
					const availabilityByDate: InterfaceEventProductAvailability = apiResponse.data.items.pop() as InterfaceEventProductAvailability;
					this.updateAvailability( availabilityByDate );
					this.passAvailabilityLoaded = true;
					console.log( 'Pass Availability', availabilityByDate );
				}
			}
		} );
	}

	private fetchMerchAvailability( merchDoclets: InterfaceOWDoclet<InterfaceOWTemplateEventMerchandise>[], startDate: Date, stopDate: Date ): void {
		const arrIDs: string[] = merchDoclets.map( (doclet: InterfaceOWDoclet): string => doclet._id.$oid );
		this.owapi.workspace.actions.core.getDailyMerchandiseAvailabilityFromDateRange(
			this.appConfig.getContext(),
			arrIDs,
			startDate,
			stopDate,
			this.selectedEventID,
			this.strWebRole
		).subscribe( (response: InterfaceHTTPGateway<InterfaceOWAPIEventProductAvailabilityResponse>): void => {
			if ( response?.success ) {
				const apiResponse: InterfaceOWAPIEventProductAvailabilityResponse | undefined = response.data;
				if ( (apiResponse?.data?.items ?? []).length < 1 ) {
					console.log( 'No availability from the API. No merch for sale.' );
				}
				if ( apiResponse && Array.isArray( apiResponse?.data?.items ) && apiResponse.data.items.length > 0 ) {
					// .data.items comes from a paginated setup, but there is always/only ever 1 element in the array.
					const availabilityByDate: InterfaceEventProductAvailability = apiResponse.data.items.pop() as InterfaceEventProductAvailability;
					this.updateAvailability( availabilityByDate );
					this.merchAvailabilityLoaded = true;
					console.log( 'Merch Availability', availabilityByDate );
				}
			}
		} );
	}

	private processPassDoclets( passDoclets: InterfaceOWDocletWithEntitlement<InterfaceOWTemplateEventPass>[] ): void {
		this.singleUsePasses = [];
		this.multiUsePasses = [];
		this.addOnPasses = [];
		// converts InterfaceOWDoclet into InterfaceOWDocletEventPassTransformed
		for ( let x: number = 0; x < passDoclets.length; ++x ) {
			if ( passDoclets[x].data['status'] !== 'active' ) {
				console.log( 'Found an invalid pass for sale', passDoclets[x] );
				passDoclets.splice( x--, 1 );
				continue;
			}
			if ( (passDoclets[x].ow_roles ?? []).filter( (owRole: InterfaceObjectId): boolean => {
				return owRole.$oid === this.strWebRole;
			} ).length < 1 ) {
				console.log( 'Found an invalid pass for sale', passDoclets[x] );
				passDoclets.splice( x--, 1 );
			}
			const passID: string = passDoclets[x]._id.$oid;
			if ( !(passID in this.overlayTicketSelection) ) {
				this.overlayTicketSelection[passID] = 0;
			}
			TransformerEventProductBase.processCalendarBlockedAndValidDates( passDoclets[x] );
			this.passIDToPassProps[passID] = TransformerEventPasses.processPassProps( passDoclets[x], this.roleIDs );
			if ( this.passIDToPassProps[passID].isAddOn ) {
				this.addOnPasses.push( passDoclets[x] as InterfaceOWDoclet<InterfaceOWTemplateEventPass> );
			} else if ( this.passIDToPassProps[passID].isAllEvent ) {
				this.multiUsePasses.push( passDoclets[x] as InterfaceOWDoclet<InterfaceOWTemplateEventPass> );
			} else { // not an add-on, nor multi-use, therefore it has to be the plain old single use.
				this.singleUsePasses.push( passDoclets[x] as InterfaceOWDoclet<InterfaceOWTemplateEventPass> );
			}
		} // end for each doclet to bucket up.
		const eventPasses: InterfaceOWDoclet[][] = [
			this.singleUsePasses,
			this.multiUsePasses,
			this.addOnPasses
		];
		for ( let x: number = 0; x < eventPasses.length; ++x ) {
			eventPasses[x].sort( (A: InterfaceOWDoclet, B: InterfaceOWDoclet): number => {
				if ( 'sort' in A.data && 'sort' in B.data && A.data['sort'] !== B.data['sort'] ) {
					return A.data['sort'] - B.data['sort'];
				}
				return ServiceSorting.naturalSort( A.data['name'], B.data['name'] );
			} );
		}
		this.fetchPassAvailability( passDoclets, this.calStartDate, this.calStopDate );
	}

	private processMerchDoclets( merchDoclets: InterfaceOWDoclet<InterfaceOWTemplateEventMerchandise>[] ): void {
		this.merchandise = {};
		this.merchandiseNames = [];
		this.merchIDs = {};
		this.merchAddOns = []; // TODO: when showing add-ons in the overlay model, don't show ones that have zero availability.
		for ( let x: number = 0; x < merchDoclets.length; ++x ) {
			if ( merchDoclets[x].data['status'] !== 'active' ) {
				console.log( 'Found an invalid product/merch for sale', merchDoclets[x] );
				merchDoclets.splice( x--, 1 );
				continue;
			}
			if ( (merchDoclets[x].ow_roles ?? []).filter( (owRole: InterfaceObjectId): boolean => {
				return owRole.$oid === this.strWebRole;
			} ).length < 1 )  {
				console.log( 'Found an invalid merch/product for sale', merchDoclets[x] );
				merchDoclets.splice( x--, 1 );
				continue;
			}
			const merchID: string = merchDoclets[x]._id.$oid;
			this.merchIDs[merchID] = true;
			if ( !(merchID in this.overlayTicketSelection) ) {
				this.overlayTicketSelection[merchID] = 0;
			}
			TransformerEventProductBase.processCalendarBlockedAndValidDates( merchDoclets[x] );
			this.passIDToPassProps[merchID] = TransformerEventProductBase.processEventProductProps( merchDoclets[x], this.roleIDs );
			//
			const itemName: string = merchDoclets[x].data.name;
			const itemColor: string = String( merchDoclets[x].data.color ); // may be NULL / undefined
			const itemSize: string = String( merchDoclets[x].data.size ); // may be NULL / undefined
			if ( !( itemName in this.merchandise) ) {
				this.merchandiseNames.push( itemName );
				this.merchandise[itemName] = {
					byColor: {},
					colors: []
				};
			}
			if ( !( itemColor in this.merchandise[itemName].byColor) ) {
				this.merchandise[itemName].colors.push( itemColor );
				this.merchandise[itemName].byColor[itemColor] = {
					bySize: {},
					photo: undefined,
					selectedSize: itemSize,
					sizes: []
				};
			}
			if ( !( itemSize in this.merchandise[itemName].byColor[itemColor].bySize) ) {
				this.merchandise[itemName].byColor[itemColor].sizes.push( itemSize );
				this.merchandise[itemName].byColor[itemColor].bySize[itemSize] = merchDoclets[x];
				if ( !this.merchandise[itemName].byColor[itemColor].photo ) {
					if ( merchDoclets[x].data.photo ) {
						this.merchandise[itemName].byColor[itemColor].photo = merchDoclets[x].data.photo;
					}
				}
			}
			//
			if ( merchDoclets[x].data.is_addon ) {
				this.merchAddOns.push( merchDoclets[x] );
			}
		}
		//
		this.merchAddOns.sort( (A: InterfaceOWDoclet<InterfaceOWTemplateEventMerchandise>, B: InterfaceOWDoclet<InterfaceOWTemplateEventMerchandise> ): number => {
			if ( A.data.name === B.data.name ) {
				if ( A.data.color === B.data.color ) {
					return ServiceSorting.sortProductSizes( A.data.size, B.data.size );
				} else {
					return ServiceSorting.naturalSort( A.data.color, B.data.color );
				}
			} else {
				return ServiceSorting.naturalSort( A.data.name, B.data.name );
			}
		} );
		this.merchandiseNames.sort( ServiceSorting.naturalSort );
		this.merchandiseNames.forEach( (strName: string): void => {
			this.merchandise[strName].colors.sort( ServiceSorting.naturalSort );
			this.merchandise[strName].colors.forEach( (strColor: string): void => {
				this.merchandise[strName].byColor[strColor].sizes.sort( ServiceSorting.sortProductSizes );
			} );
		} );
		//
		this.fetchMerchAvailability( merchDoclets, this.calStartDate, this.calStopDate );
	}

	private fetchPassesForSale(): void {
		this.owapi.workspace.actions.core.getEventPasses(
			this.appConfig.getContext(),
			this.selectedEventID,
			this.strWebRole
		).subscribe( (response: InterfaceHTTPGateway<InterfaceOWAPIGetEventPassActionResponse>): void => {
			if ( response?.success ) {
				const apiResponse: InterfaceOWAPIGetEventPassActionResponse | undefined = response?.data;
				if ( apiResponse && Array.isArray( apiResponse?.data?.items ) ) {
					this.processPassDoclets( apiResponse.data.items );
				}
			}
		} );
	}

	private fetchMerchandiseForSale(): void {
		this.owapi.workspace.actions.core.getEventProducts(
			this.appConfig.getContext(),
			this.selectedEventID,
			this.strWebRole
		).subscribe( (response: InterfaceHTTPGateway<InterfaceOWAPIGetEventProductsActionResponse>): void => {
			if ( response?.success ) {
				const apiResponse: InterfaceOWAPIGetEventProductsActionResponse | undefined = response?.data;
				if ( apiResponse && Array.isArray( apiResponse?.data?.items ) ) {
					this.processMerchDoclets( apiResponse.data.items );
				}
			}
		} );
	}

	private fetchEventData(): void {
		const verbose: boolean = false;
		const withoutAuth: boolean = true;
		this.owapi.workspace.doclets.getDocletByID( this.appConfig.getContext(), this.selectedEventID, verbose, withoutAuth ).subscribe( (response: InterfaceHTTPGateway<InterfaceOWAPIGetDocletResponse>): void => {
			if ( response.success ) {
				const apiResponse: InterfaceOWAPIGetDocletResponse = response.data;
				if ( apiResponse?.data?._id && apiResponse?.data?.data?.['start_date'] ) {
					const eventDoclet: InterfaceOWDoclet<InterfaceOWTemplateVenueEvent> = apiResponse.data as InterfaceOWDoclet<InterfaceOWTemplateVenueEvent>;
					if ( eventDoclet.data.start_date.match( /^\d\d\d\d-\d\d-\d\d$/ ) ) {
						const arrYMD: string[] = eventDoclet.data.start_date.split( /-/g );
						this.selectedDate = {
							year: Number( arrYMD[0] ),
							month1: Number( arrYMD[1] ), // it's already 1-index based, so no need to (1 + month) here...
							day: Number( arrYMD[2] )
						};
					}
				}
			}
			this.fetchPassesForSale();
			this.fetchMerchandiseForSale();
		} );
	}

	private getTicketSoldCount( passID: string, year: number, month1: number, day: number ): number {
		const YYYY: string = String( year );
		const MM1: string = ('0' + month1).slice( -2 );
		const DD: string = ('0' + day).slice( -2 );
		const YYYYMMDD1: string = YYYY + '-' + MM1 + '-' + DD;
		return this.productAvailability?.[YYYYMMDD1]?.[passID]?.item_count ?? 0;
	}

	public getEventPassPrice( passID: string, year: number, month1: number, day: number ): number {
		const passForSale: InterfaceOWDoclet<InterfaceOWTemplateEventProduct> = this.passIDToPassProps[passID].doclet as InterfaceOWDoclet<InterfaceOWTemplateEventProduct>;
		if ( passForSale && Array.isArray( passForSale.data['price_matrix'] ) ) {
			const d: Date = new Date();
			const strPurchaseDate: string = TransformerDate.strYYYYMMDDFromYMDInts( d.getFullYear(), 1 + d.getMonth(), d.getDate() );
			const strTargetDate: string = TransformerDate.strYYYYMMDDFromYMDInts( year, month1, day );
			const soldCount: number = this.getTicketSoldCount( passID, year, month1, day );
			return TransformerEventPasses.getEventPassPriceV2( passForSale, strPurchaseDate, strTargetDate, soldCount );
		}
		return 0;
	}

	public daySelected( selectedYMD: InterfaceYYYYMM1DD ): void {
		if ( document.activeElement instanceof HTMLElement ) {
			document.activeElement.blur();
		}
		this.didPickDate = true;
		this.selectedDate = selectedYMD;
	}

	private clearSelectedProduct(): void {
		this.overlaySelectedProduct = undefined;
		this.overlaySelectedMerch = undefined;
		this.overlaySelectedPass = undefined;
		this.grandTotal = this.calculateOverlayTotal();
	}

	private setOverlayProduct( product: InterfaceOWDoclet<InterfaceOWTemplateEventProduct> ): void {
		this.overlaySelectedProduct = product; // the common interface. most HTML property-accessors should use this.
		if ( this.isMerchID( product._id.$oid ) ) {
			this.overlaySelectedMerch = product as InterfaceOWDoclet<InterfaceOWTemplateEventMerchandise>;
			this.overlaySelectedPass = undefined; // specialized product - Event Pass (currently has no extra properties as the generic Product)
		} else {
			this.overlaySelectedMerch = undefined // specialized product - Event Merch has extra properties: size, color, etc.
			this.overlaySelectedPass = product;
		}
	}

	private timerGfx: {
		closeModal: number | null;
	} = {
		closeModal: null
	};
	public addToOverlay( selectedProduct: InterfaceOWDoclet<InterfaceOWTemplateEventProduct> ): void {
		// When they add anything to the cart except a pass(?), if didPickDate is false,
		// Then we will show a date picker in a popup.
		if ( this.timerGfx.closeModal !== null ) {
			clearTimeout( this.timerGfx.closeModal );
		}
		this.setOverlayProduct( selectedProduct );
		if ( this.overlaySelectedPass ) { // if not Merch, basically
			// TODO: special treatment for when there is only 1 size choice?
			this.overlayChangeQty( selectedProduct._id.$oid, 1 );
		} else {
			// so long as `this.overlayChangeQty( selectedProduct._id.$oid, 1 );` is fired up, you don't need to calc the grand total again.
			this.grandTotal = this.calculateOverlayTotal();
		}
		this.showAddOverlay = true;
		ServiceAppEvents.broadcast( 'cart:close' );
	}

	private overlayClearSelections( keepingTicketIDs: boolean ): void {
		const heldIDs: string[] = [];
		Object.keys( this.overlayTicketSelection ).forEach( (passID: string): void => {
			this.overlayTicketSelection[passID] = 0;
			if ( passID in this.overlayTicketLocks ) {
				Object.keys( this.overlayTicketLocks[passID] ).forEach( (strDate: string): void => {
					this.overlayTicketLocks[passID][strDate].forEach( (heldID: string): void => {
						heldIDs.push( heldID );
					} );
				} );
			}
			this.overlayTicketLocks[passID] = {};
		} );
		if ( !keepingTicketIDs ) {
			const unlockData: InterfaceEventEntitlementUnlock = {
				id: heldIDs
			};
			ServiceAppEvents.broadcast( {
				topic: 'entitlement:unlock',
				data: unlockData
			} );
		}
		this.grandTotal = this.calculateOverlayTotal();
	}

	public overlayBack( clearTicketsOnClose: boolean ): void { // the "add to cart" used this, which cleared ticket IDs...
		if ( this.timerGfx.closeModal !== null ) {
			clearTimeout( this.timerGfx.closeModal );
		}
		this.timerGfx.closeModal = setTimeout( (): void => {
			this.clearSelectedProduct();
		}, 1100 );
		this.showAddOverlay = false;
		this.overlayClearSelections( !clearTicketsOnClose );
	}

	public overlayAddToCart(): void {
		if ( this.overlaySelectedProduct ) {
			const yr: number = this.selectedDate.year;
			const m1: number = this.selectedDate.month1;
			const d: number = this.selectedDate.day;
			const YYYYMM1DD: string = TransformerDate.strYYYYMMDDFromYMDInts( yr, m1, d );
			ServiceCart.addToCart( Object.keys( this.overlayTicketSelection ).map( (productID: string): InterfaceAddToCart[] => {
				const output: InterfaceAddToCart[] = [];
				if ( productID in this.passIDToPassProps && this.passIDToPassProps[productID].doclet ) {
					const product: InterfaceOWDocletWithEntitlement<InterfaceOWTemplateEventProduct> = this.passIDToPassProps[productID].doclet;
					const price: number = this.getEventPassPrice( productID, yr, m1, d );
					for ( let y: number = 0; y < this.overlayTicketSelection[productID]; ++y ) {
						output.push( {
							passForSale: product,
							eventID: this.selectedEventID,
							price: price,
							spaceID: null, // TODO: this
							ticketID: this.overlayTicketLocks?.[productID]?.[YYYYMM1DD]?.[y] ?? null,
							time: null, // TODO: this?
							visitDate: {
								year: yr,
								month1: m1,
								day: d,
								isAnyDay: this.passIDToPassProps[productID].isAnyDay ?? false,
								isEventLength: this.passIDToPassProps[productID].isAllEvent ?? false
							},
							consumerData: {
								_id: undefined, // TODO: this?
								firstName: null,
								lastName: null,
								dob: null,
								phone: null
							}
						} );
					}
				}
				return output;
			} ).reduce( (output: InterfaceAddToCart[], input: InterfaceAddToCart[]): InterfaceAddToCart[] => {
				for ( let y: number = 0; y < input.length; ++y ) {
					output.push( input[y] );
				}
				return output;
			}, [] ) );
			this.clearSelectedProduct();
			this.overlayBack( false ); // to close the modal.
			this.grandTotal = 0;
			this.didPickQty = false;
		}
	}

	public overlayChangeQty( passID: string, qty: -1 | 1 ): void {
		if ( this.overlayBusy ) {
			return;
		}
		if ( !(passID in this.overlayTicketSelection) ) {
			this.overlayTicketSelection[passID] = 0;
		}
		if ( !(passID in this.overlayTicketLocks) ) {
			this.overlayTicketLocks[passID] = {};
		}
		const YYYYMM1DD: string = TransformerDate.strYYYYMMDDFromYMDInts( this.selectedDate.year, this.selectedDate.month1, this.selectedDate.day );
		if ( qty > 0 ) {
			this.overlayBusy = true;
			const entitlementLockData: InterfaceEventEntitlementLock = {
				eventID: this.selectedEventID,
				passID: passID,
				spaceID: null,
				date: this.passIDToPassProps[passID].isAllEvent ? 'event' : this.selectedDate,
				roleID: this.appConfig.getRoleID( 'Web' ),
				callback: (entitlementID: string | false): void => {
					console.log( 'result of entitlement lock', entitlementID );
					if ( typeof entitlementID === 'string' ) {
						this.overlayTicketSelection[passID] = Math.max( 0, this.overlayTicketSelection[passID] + qty );
						if ( !(passID in this.overlayTicketLocks) ) {
							this.overlayTicketLocks[passID] = {};
						}
						if ( !(YYYYMM1DD in this.overlayTicketLocks[passID]) ) {
							this.overlayTicketLocks[passID][YYYYMM1DD] = [];
						}
						this.overlayTicketLocks[passID][YYYYMM1DD].push( entitlementID );
						this.didPickQty = Object.keys( this.overlayTicketSelection ).filter( (pID: string): boolean => {
							return this.overlayTicketSelection[pID] > 0;
						} ).length > 0;
						this.grandTotal = this.calculateOverlayTotal();
					} else {
						console.log( 'No availability for pass ' + passID );
						// tell the user there is no more allowed for that ticket...
						// TODO: fetch availability to refresh it.
					}
					this.overlayBusy = false;
				}
			};
			ServiceAppEvents.broadcast( {
				topic: 'entitlement:lock',
				data: entitlementLockData
			} );
		} else {
			const entitlementID: string | undefined = this.overlayTicketLocks[passID][YYYYMM1DD].shift();
			this.overlayTicketSelection[passID] = Math.max( 0, this.overlayTicketSelection[passID] + qty );
			this.didPickQty = Object.keys( this.overlayTicketSelection ).filter( (pID: string): boolean => {
				return this.overlayTicketSelection[pID] > 0;
			} ).length > 0;
			this.grandTotal = this.calculateOverlayTotal();
			if ( entitlementID !== undefined ) {
				const unlockData: InterfaceEventEntitlementUnlock = {
					id: entitlementID
				};
				ServiceAppEvents.broadcast( {
					topic: 'entitlement:unlock',
					data: unlockData
				} );
			}
		}
	}

	public calculateOverlayTotal( precision: number = 2 ): number {
		// this is called twice (sort of intentionally).
		// once when the overlay modal closes, and again when the fade-out animation completes on the overlay modal.
		// ...as well as when any qty -/+ occurs.
		const y: number = this.selectedDate.year;
		const m1: number = this.selectedDate.month1;
		const d: number = this.selectedDate.day;
		return Number( Object.keys( this.overlayTicketSelection ).map( (passID: string): number => {
			const price: number = this.getEventPassPrice( passID, y, m1, d );
			return price * this.overlayTicketSelection[passID];
		} ).reduce( (output: number, input: number): number => {
			return output + input;
		}, 0 ).toFixed( precision ) );
	}

	public isMerchID( id: string ): boolean {
		return id in this.merchIDs && this.merchIDs[id];
	}
}
