import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {InterfaceAppContext, InterfaceHTTPGateway} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIWorkspaceWorkspaces {
	private readonly routePrefix: string = 'workspace/';
	//
	public constructor(
		private readonly gateway: ServiceOWGateway
	) {
		//
	}

	public createWorkspace( name: string, description: string, notes: string, group?: string, appKey?: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix, JSON.stringify( {
			app_key: appKey, // optional - for when you want to create a workspace under a particular APP.
			description: description,
			name: name,
			notes: notes,
			group: group // for which category to show the action into, such as Passes for sale or Tickets, etc.
		} ) );
	}

	public setWorkspace( workspaceID: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( workspaceID ), JSON.stringify( {} ) );
	}

	public getWorkspace( workspaceID: string ): Observable<InterfaceHTTPGateway> {
		// acquire info about a particular workspace...
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( workspaceID ) );
	}

	public getActions( appContext: InterfaceAppContext ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/actions' );
		// response.data.items is of type: InterfaceOWWorkspaceActionList[];
		// response.data is of type: InterfaceOWAPIWorkspaceActionsListResponse;
	}

	public getRoles( appContext: InterfaceAppContext ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/roles' );
	}
	// TODO: make interfaces for the two role response(s)
	public getRoleByID( appContext: InterfaceAppContext, roleID: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/roles/' + encodeURIComponent( roleID ) );
	}
}
