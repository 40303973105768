import {Injectable} from '@angular/core';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceSorting { // not sure if this qualifies as a Service file. Issue with naming conventions.
	//
	public constructor() {
		//
	}

	public static naturalSort( A: any, B: any ): number { // sorts ASC
		// .sort( (A, B) => A.localeCompare( B, undefined, { "numeric" : true, "sensitivity" : "base" } ) )
		// .data.price.sort( (A,B) => { return A.date === "default" ? -1 : (B.date === "default" ? -1 : (A.date.localeCompare( B.date, undefined, { "numeric" : true, "sensitivity" : "base" } ))) } )
		return String( A ).toLowerCase().localeCompare( String( B ).toLowerCase(), undefined, {
			numeric: true,
			sensitivity: 'base'
		} );
	}

	public static productSizeToSortableNumber( size: string ): number {
		switch ( size.toLowerCase() ) {
			case 'xxxxxs':
			case '5xs': {
				return -5;
			}
			case 'xxxxs':
			case '4xs': {
				return -4;
			}
			case 'xxxs':
			case '3xs': {
				return -3;
			}
			case 'xxs':
			case '2xs': {
				return -2;
			}
			case 'xs':
			case '1xs': {
				return -1;
			}
			case 's': {
				return -0.5;
			}
			case 'm': {
				return 0;
			}
			case 'l': {
				return 0.5;
			}
			case 'xl':
			case '1xl': {
				return 1;
			}
			case 'xxl':
			case '2xl': {
				return 2;
			}
			case 'xxxl':
			case '3xl': {
				return 3;
			}
			case 'xxxxl':
			case '4xl': {
				return 4;
			}
			case 'xxxxxl':
			case '5xl': {
				return 5;
			}
			default: {
				return 42;
			}
		}
	}

	public static sortProductSizes( A: string, B: string ): number {
		return ServiceSorting.productSizeToSortableNumber( A ) - ServiceSorting.productSizeToSortableNumber( B );
	}
}
