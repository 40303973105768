import {Component, EventEmitter, Input, Output} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceNavMenuItem} from '../../../interfaces/interfaces';
//
@Component( {
	selector: 'nav-vertical',
	templateUrl: './nav-vertical.html',
	styleUrls: [
		'./nav-vertical.less'
	]
} )
export class ComponentNavVertical {
	@Input()
	public activeIndex: number = 0;
	//
	@Output()
	public selected: EventEmitter<number> = new EventEmitter<number>();
	//
	@Input()
	public entries: InterfaceNavMenuItem[] = [];
	//
	public constructor() {
		//
	}

	public itemSelected( idx: number ): void {
		this.selected.emit( idx );
	}
}
