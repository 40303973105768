import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
//
@Component( {
	selector: 'nav-frame',
	templateUrl: './nav-frame.html',
	styleUrls: [
		'./nav-frame.less'
	]
} )
export class ComponentNavFrame implements OnChanges {
	@Input()
	public heading: string | undefined = undefined;
	//
	@Input()
	public isActive: boolean = false;
	//
	public constructor() {
		//
	}

	public ngOnChanges( changes: SimpleChanges ): void {
		if ( typeof changes['isActive'] !== 'undefined' ) {
			// then the frame was just activated, or just hidden.
			if ( changes['isActive'].isFirstChange() ) {
				this.clear();
			}
		}
	}

	public clear(): void { // you'll need to override this, in each component that extends the NavFrame.
		//
	}
}
