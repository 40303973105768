import {Injectable} from '@angular/core';
//
@Injectable( {
	providedIn: 'root',
} )
export class TransformerFile {
	public static blobToBase64( b: Blob ): Promise<string> {
		return new Promise( (resolve: (output: string) => void, reject: (error: DOMException | Error) => void ): void => {
			const F: FileReader = new FileReader();
			F.onloadend = (): void => resolve( F.result as string );
			F.onerror = (E: ProgressEvent<FileReader>): void => {
				reject( E.target?.error ?? new Error( 'Unknown FileReader error' ) );
			};
			F.readAsDataURL( b );
		} );
	}
}
