import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../../../../../ow-framework/collections/profiles';
// ===== Image Cropper ===== //
import {ImageCroppedEvent, LoadedImage} from 'ngx-image-cropper'; // https://www.npmjs.com/package/ngx-image-cropper
// ===== Interfaces ===== //
import {
	InterfaceAppContext,
	InterfaceAppEvent,
	InterfaceAnyObject,
	InterfaceHTTPGateway,
	InterfaceNavMenuItem,
	InterfaceOWAPICardVault,
	InterfaceOWAPICardVaultResponse,
	InterfaceOWAPIGetDocletsResponse,
	InterfaceOWDoclet,
	InterfaceOWDocletAccount,
	InterfaceOWDocletConsumer,
	InterfaceOWUser,
	InterfaceOWUserProfileData
} from '../../../../../../ow-framework/interfaces/interfaces';
interface InterfaceProfileErrors {
	first_name: boolean;
	last_name: boolean;
	email: boolean;
}
interface InterfaceConsumerData {
	dob: string;
	phone: string;
	photo: string;
}
interface InterfaceConsumerErrors {
	dob: boolean;
	phone: boolean;
}
// ===== Services ===== //
import {ServiceAppEvents} from '../../../../../../ow-framework/services/app-events';
import {ServiceAuthentication} from '../../../../../../ow-framework/services/authentication';
import {ServiceNavigate} from '../../../../../../ow-framework/services/navigate';
import {ServiceOWAPI} from '../../../../../../ow-framework/services/ow-api';
import {ServiceRegex} from '../../../../../../ow-framework/services/regex';
// ===== Transformers ===== //
import {TransformerFile} from '../../../../../../ow-framework/transformers/file';
//
@Component( {
	selector: 'page-my-account',
	templateUrl: './my-account.html',
	styleUrls: [
		'./my-account.less'
	]
} )
export class PageMyAccount implements OnDestroy, OnInit {
	// TODO: allow the purchaser to change their email address.
	public readonly routes: typeof AppRouterLinks = AppRouterLinks;
	private readonly portalRealmID: string = this.appConfig.getRealmID( 'Portal' );
	private readonly strConsumerTemplateID: string = this.appConfig.getTemplateID( 'Consumer' );
	private readonly appContext: InterfaceAppContext = this.appConfig.getContext();
	private subUserReSync: Subscription | null = null;
	private updateUserCacheOnExit: boolean = false;
	//
	private subCardReSync: Subscription | null = null;
	private cardsOnFile: InterfaceOWAPICardVault[] = [];
	//
	private subAccountReSync: Subscription | null = null;
	public accountDoclet: InterfaceOWDoclet | null = null;
	public haveAccountInfo: boolean = false;
	private readonly strAccountTemplateID: string = this.appConfig.getTemplateID( 'Account' );
	//
	public mastHeading: string = 'Manage Your Account';
	public navMenuItems: InterfaceNavMenuItem[] = [
		{
			route: '/' + this.routes.dashboard,
			text: 'Dashboard'
		},
		{
			route: '/' + this.routes.family,
			text: 'Manage Family',
			shortText: 'Family'
		}, /*
		{
			route: '/' + this.routes.editGroup,
			text: 'Manage Group',
			shortText: 'Group',
			locked: false
		}, */
		{
			route: '/' + this.routes.orders,
			text: 'Orders & Billing',
			shortText: 'Billing'
		},
		{
			route: '/' + this.routes.myAccount,
			text: 'My Account',
			shortText: 'Account'
		}
	];
	public serialCode: string = '';
	//
	public imageChangedEvent: Event | undefined = undefined;
	public b64ProfileImageData: string = '';
	public usingImageCropper: boolean = false;
	public formBusy: boolean = false;
	//
	public userProfileData: InterfaceOWUserProfileData = {
		first_name: '',
		last_name: ''
	};
	public email: string = '';
	public consumerDocletID: string | null = null;
	public userProfileErrors: InterfaceProfileErrors = {
		first_name: false,
		last_name: false,
		email: false
	};
	public consumerData: InterfaceConsumerData = {
		dob: '',
		phone: '',
		photo: ''
	};
	public consumerErrors: InterfaceConsumerErrors = {
		dob: false,
		phone: false
	};
	public formHasErrors: boolean = false;
	//
	public cashlessSpending: boolean = false;
	//
	public recycleTB: boolean = true; // trying to fix an angular framework issue...
	//
	public constructor(
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly nav: ServiceNavigate,
		private readonly owapi: ServiceOWAPI,
		private readonly title: Title
	) {
		this.title.setTitle( 'Portal' );
		if ( this.auth.isSignedIn() ) {
			this.subAccountReSync = ServiceAppEvents.listen( 'account:re-sync' ).subscribe( (_:InterfaceAppEvent): void => {
				this.fetchAccountInfo(); // it seems nothing fires up "account:ry-sync"
			} );
			this.subCardReSync = ServiceAppEvents.listen( 'card:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
				this.fetchCardInfo();
			} );
			this.subUserReSync = ServiceAppEvents.listen( 'user:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
				this.fetchUserInfo();
			} );
			// don't listen to updates on the profile collection here, because it may trample over the users in-progress form data.
			// ex: other users profiles were updated, which causes the collection to emit it's update, which causes this component to re-load, etc.
		} else {
			this.nav.toURL( '/' + this.routes.signIn );
		}
	}

	private fetchUserInfo(): void {
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			return;
		}
		this.colProfiles.getMyUserProfile( (userData: InterfaceOWUser | null): void => {
			if ( userData ) {
				this.email = userData.email;
				const profileData: InterfaceAnyObject = userData.profile ?? {};
				this.userProfileData.first_name = typeof profileData['first_name'] === 'string' ? profileData['first_name'] : '';
				this.userProfileData.last_name = typeof profileData['last_name'] === 'string' ? profileData['last_name'] : '';
				this.validateProfileData( 'first_name' );
				this.validateProfileData( 'last_name' );
				this.colProfiles.getUserWorkspaceDoclet( this.appContext, userData._id.$oid, this.strConsumerTemplateID, (consumerDoclet: InterfaceOWDocletConsumer | null): void => {
					if ( consumerDoclet ) {
						this.consumerDocletID = consumerDoclet._id.$oid;
						const consumerData: InterfaceAnyObject = consumerDoclet.data;
						this.consumerData.phone = typeof consumerData['phone'] === 'string' ? consumerData['phone'] : '';
						this.consumerData.dob = typeof consumerData['dob'] === 'string' ? consumerData['dob'] : '';
						this.consumerData.photo = typeof consumerData['photo'] === 'string' ? consumerData['photo'] : '';
						this.validateConsumerData( 'dob' );
						this.validateConsumerData( 'phone' );
					} else {
						console.error( '===== Missing Consumer Doclet =====' );
					}
				} );
			} // end if we fetched this user's profile.
		} );
	}

	private fetchAccountInfo(): void {
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			return;
		}
		this.colProfiles.getAccountWorkspaceDoclet( this.appContext, profileID, this.strAccountTemplateID, (accountDoclet: InterfaceOWDocletAccount | null, _: InterfaceOWUser | null): void => {
			this.accountDoclet = accountDoclet;
			if ( this.accountDoclet && this.accountDoclet.data && this.accountDoclet.data['cashless_spending'] && this.cardsOnFile.length > 0 ) {
				this.setCashlessToggleBox( true );
			} else {
				this.setCashlessToggleBox( false );
			}
			console.log( 'account doc', this.accountDoclet );
			this.haveAccountInfo = true;
			if ( !this.accountDoclet ) {
				// cheating, this ought to create one..
				this.owapi.workspace.actions.core.getSavedPaymentMethod( this.appContext, profileID, this.portalRealmID ).subscribe( (r: InterfaceHTTPGateway): void => console.log( 'cache-miss: created account doclet', r ) );
			}
		} );
	}

	private fetchCardInfo(): void {
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			return;
		}
		this.owapi.workspace.actions.core.getSavedPaymentMethod( this.appContext, profileID, this.portalRealmID ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response && response.success ) {
				this.cardsOnFile = [];
				const apiResponse: InterfaceOWAPICardVaultResponse = response.data;
				if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) && apiResponse.data.items.length > 0 ) {
					this.cardsOnFile = apiResponse.data.items;
					console.log( 'cards on file', this.cardsOnFile );
					if ( this.accountDoclet ) {
						if ( this.cardsOnFile.length > 0 && this.accountDoclet.data['cashless_spending'] ) {
							this.setCashlessToggleBox( true );
						}
						if ( this.cardsOnFile.length < 1 || !this.accountDoclet.data['cashless_spending'] ) {
							this.setCashlessToggleBox( false );
						}
					} else { // cheating.. it may not have existed until we tried to fetch the card info
						console.log( 'cache miss, re-fetching account...' );
						this.fetchAccountInfo();
					}
				}
			}
		} );
	}

	public ngOnInit(): void {
		this.fetchAccountInfo();
		this.fetchCardInfo();
		this.fetchUserInfo();
	}

	public ngOnDestroy(): void {
		if ( this.updateUserCacheOnExit && this.consumerDocletID ) {
			this.colProfiles.fetchProfileByID( this.consumerDocletID );
		}
		if ( this.subAccountReSync ) {
			this.subAccountReSync.unsubscribe();
			this.subAccountReSync = null;
		}
		if ( this.subCardReSync ) {
			this.subCardReSync.unsubscribe();
			this.subCardReSync = null;
		}
		if ( this.subUserReSync ) {
			this.subUserReSync.unsubscribe();
			this.subUserReSync = null;
		}
	}

	public validateProfileData( key: keyof InterfaceProfileErrors ): void {
		// first/last name, not email
		switch ( key ) {
			case 'email': {
				this.userProfileErrors.email = !ServiceRegex.emailRegExp.test( this.email );
				break;
			}
			case 'first_name': {
				this.userProfileErrors.first_name = this.userProfileData.first_name.replace( ServiceRegex.trimRegExp, '' ).length < 1;
				break;
			}
			case 'last_name': {
				this.userProfileErrors.last_name = this.userProfileData.last_name.replace( ServiceRegex.trimRegExp, '' ).length < 1;
				break;
			}
		}
	}
	public validateConsumerData( key: keyof InterfaceConsumerErrors ): void {
		switch ( key ) {
			case 'dob': {
				this.consumerErrors.dob = !ServiceRegex.YYYYMMDDExp.test( this.consumerData.dob );
				break;
			}
			case 'phone': {
				this.consumerErrors.phone = this.consumerData.phone.replace( ServiceRegex.trimRegExp, '' ).length < 1;
				break;
			}
		}
	}

	public updateAccount(): void {
		this.validateProfileData( 'first_name' );
		this.validateProfileData( 'last_name' );
		this.validateConsumerData( 'dob' );
		this.validateConsumerData( 'phone' );
		const haveErrors: boolean = Object.keys( this.userProfileErrors ).some( (key: string): boolean => {
			return this.userProfileErrors[key as keyof InterfaceProfileErrors];
		} ) || Object.keys( this.consumerErrors ).some( (key: string): boolean => {
			return this.consumerErrors[key as keyof InterfaceConsumerErrors];
		} );
		if ( !haveErrors ) {
			const userProfilePayload: {
				[key: string]: string;
			} = {
				'first_name': this.userProfileData.first_name,
				'last_name': this.userProfileData.last_name
			};
			const consumerProfilePayload: {
				[key: string]: string;
			} = {
				'dob': this.consumerData.dob,
				'phone': this.consumerData.phone
			};
			if ( this.b64ProfileImageData.length > 0 ) {
				consumerProfilePayload['photo'] = this.b64ProfileImageData;
			}
			this.formBusy = true;
			this.updateUserCacheOnExit = true;
			let twoAPICalls: number = 2;
			if ( this.consumerDocletID ) {
				this.owapi.workspace.doclets.updateDocletData( this.appContext, this.consumerDocletID, consumerProfilePayload ).subscribe( _ => {
					if ( --twoAPICalls < 1 ) {
						this.formBusy = false;
					}
				} );
			}
			this.owapi.account.profile.updateUserProfileData( userProfilePayload ).subscribe( _ => {
				if ( --twoAPICalls < 1 ) {
					this.formBusy = false;
				}
			} );
		}
	}

	public blur( E: Event ): void {
		if ( E.target instanceof HTMLElement ) {
			void E.target.dispatchEvent( new Event( 'blur' ) );
		}
	}

	// ===== Image Cropper ===== //

	public inputTypeFileOnChange( E: Event ): void {
		this.imageChangedEvent = E;
	}

	public imageCropperOnChange( E: ImageCroppedEvent ): void {
		if ( typeof E.base64 === 'string' ) {
			this.b64ProfileImageData = E.base64;
		} else if ( E.blob ) {
			TransformerFile.blobToBase64( E.blob ).then( (b64: string): void => {
				this.b64ProfileImageData = b64;
			} ).catch( (fail): void => {
				console.log( 'Failed to get base64 img', fail );
			} );
		} else {
			this.imageCropperFail();
		}
	}

	public imageCropperOnImageLoad( image: LoadedImage ): void {
		console.log( 'img loaded', image );
		// show cropper
		this.usingImageCropper = true;
	}

	public cropperReady(): void {
		this.usingImageCropper = true;
	}

	public loadImageFailed(): void {
		this.imageCropperFail();
	}

	private imageCropperFail(): void {
		// TODO: this.
		// photo is too small.
		// file is not a valid photo. must be one of: png/jpeg/webp/bmp
	}

	public setProfilePhoto(): void {
		if ( this.b64ProfileImageData && this.b64ProfileImageData.length > 0 && this.consumerDocletID ) {
			this.owapi.workspace.doclets.updateDocletData( this.appContext, this.consumerDocletID, {
				photo: this.b64ProfileImageData
			} ).subscribe( (response: InterfaceHTTPGateway): void => {
				if ( response?.success ) {
					this.updateUserCacheOnExit = true; // don't trigger a user:re-sync, it may trample over the users profile fields.
					this.consumerData.photo = this.b64ProfileImageData;
					this.clearImageCropper();
				}
			} );
		}
	}

	public clearImageCropper(): void {
		this.usingImageCropper = false;
		this.b64ProfileImageData = '';
		this.imageChangedEvent = undefined;
	}

	public cashlessToggle( b: boolean ): void {
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			return;
		}
		this.owapi.workspace.actions.core.toggleCashlessSpending( this.appContext, profileID, b, this.portalRealmID ).subscribe( (response: InterfaceHTTPGateway): void => {
			let failed: boolean = true;
			if ( response && response.success ) {
				const apiResponse: InterfaceOWAPIGetDocletsResponse = response.data;
				if ( apiResponse && apiResponse.data && apiResponse.data.items ) {
					failed = false;
					const justAPIThings: ({} | InterfaceOWAPICardVault)[] = apiResponse.data.items;
					this.cashlessSpending = justAPIThings.length > 0 && 'vault_id' in justAPIThings[0];
				}
			}
			if ( failed ) {
				this.cashlessSpending = false;
			}
		} );
	}

	private setCashlessToggleBox( b: boolean ): void {
		// angular won't update the child component because the @Input() doesn't see a change.
		// it looks at references rather than values, and the variable
		this.cashlessSpending = b;
		// setTimeout( () => { console.log( 'set parent to', this.purchaserData.cashlessSpending ); }, 1400 );
		this.recycleTB = false;
		setTimeout( (): void => {
			this.recycleTB = true;
		}, 10 );
		// */
	}
}
