import {Injectable} from '@angular/core';
// ===== Interfaces ===== //
import {
	InterfaceDocletIDToTicketProps,
	InterfaceObjectId,
	InterfaceOWAPITicketPriceByDatePriceTier,
	InterfaceOWDoclet,
	InterfaceOWDocletWithEntitlement,
	InterfaceOWTemplateEventProduct,
	InterfacePriceMatrix
} from '../interfaces/interfaces';
interface InterfaceRoles {
	admin: string;
	pos: string;
	staff: string;
	web: string;
}
// ===== Services ===== //
import {ServiceRegex} from '../services/regex';
// ===== Transformers ===== //
import {TransformerDate} from './date';
//
@Injectable( {
	providedIn: 'root'
} )
export class TransformerEventProductBase { // common code between event products, such as Event Passes and Event Merchandise. possibly more...
	//
	public constructor() {
		//
	}

	public static processCalendarBlockedAndValidDates( product: InterfaceOWDoclet<InterfaceOWTemplateEventProduct> ): void {
		if ( Array.isArray( product.data.blocked_dates ) ) {
			product.data['__blocked_dates'] = {};
			product.data.blocked_dates.forEach( (blockedDate: string): void => {
				product.data['__blocked_dates'][ blockedDate ] = true;
			} );
		} else {
			product.data.blocked_dates = [];
			product.data['__blocked_dates'] = {};
		}
		if ( Array.isArray( product.data.dates_valid ) && product.data.dates_valid.length > 0 ) {
			product.data['__datesValid'] = {}; // if __datesValid exists, it triggers the calendar to block out ALL dates, except what's in this object.
			for ( let y: number = 0; y < product.data.dates_valid.length; ++y ) {
				const YYYYMMDD1: string = product.data.dates_valid[y]; // YYYY-MM-DD where MM is 01-12
				product.data['__datesValid'][ YYYYMMDD1 ] = true; // this forces the calendar to only allow 'these' dates to be accessible.
			}
		}
	}

	public static processEventProductProps( product: InterfaceOWDocletWithEntitlement<InterfaceOWTemplateEventProduct>, roleIDs: InterfaceRoles ): InterfaceDocletIDToTicketProps<InterfaceOWTemplateEventProduct> {
		let roleAdmin: boolean = false;
		let rolePOS: boolean = false;
		let roleStaff: boolean = false;
		let roleWeb: boolean = false;
		(product?.ow_roles ?? []).forEach( (owRole: InterfaceObjectId): void => {
			switch ( owRole.$oid ) {
				case roleIDs.admin: {
					roleAdmin = true;
					break;
				}
				case roleIDs.pos: {
					rolePOS = true;
					break;
				}
				case roleIDs.staff: {
					roleStaff = true;
					break;
				}
				case roleIDs.web: {
					roleWeb = true;
					break;
				}
			}
		} );
		return {
			doclet: product,
			isAddOn: product.data.is_addon ?? false,
			isAllEvent: product.data.is_event_length ?? false,
			isAnyDay: undefined,
			isComplexBundle: false,
			isLimitPerOrder: (product.data?.limit_per_order ?? 0) > 0,
			isPrimary: product?.entitlement_type_data?.primary ?? false,
			isPromoted: product.data?.is_promoted ?? false,
			name: product.data.name,
			priceMatrix: product.data.price_matrix,
			skipCapacityCheck: !product.data.require_capacity || product.data.is_any_day,
			role: {
				admin: roleAdmin,
				pos: rolePOS,
				staff: roleStaff,
				web: roleWeb
			},
			sort: product.data['sort'] ?? 0
		};
	}

	private static getPriceFromPriceMatrix( priceMatrix: InterfacePriceMatrix, targetDateYYYYMM1DD: 'default' | string, soldCount: number ): number {
		// helper method for getEventProductPrice
		let output: number = 0;
		if ( Array.isArray( priceMatrix.calendar_price ) ) {
			const pricesByDate: InterfaceOWTemplateEventProduct['price_matrix'][number]['calendar_price'] = priceMatrix.calendar_price;
			let targetDateFound: boolean = false;
			let theDefaultCalendarPrice: InterfaceOWTemplateEventProduct['price_matrix'][number]['calendar_price'][number] | null = null;
			for ( let y: number = 0; y < pricesByDate.length; ++y ) {
				if ( pricesByDate[y].date === 'default' ) {
					theDefaultCalendarPrice = pricesByDate[y];
				}
				if ( targetDateYYYYMM1DD === pricesByDate[y].date ) {
					targetDateFound = true;
					output = pricesByDate[y].price; // using the default date unless overridden by the price tiers.
					if ( Array.isArray( pricesByDate[y].price_tier ) ) {
						const priceTiers: InterfaceOWAPITicketPriceByDatePriceTier[] = pricesByDate[y].price_tier;
						// tiered pricing is assumed to be pre-sorted.
						for ( let z: number = 0; z < priceTiers.length; ++z ) {
							if ( priceTiers[z].count <= soldCount  ) {
								output = priceTiers[z].price;
							}
						} // end for each price tier.
					} // end if the price_tiers exist.
				} // end if we found the target date.
			} // end for each calendar date
			if ( !targetDateFound && theDefaultCalendarPrice !== null ) {
				output = theDefaultCalendarPrice.price;
			}
		}
		return output;
	}

	public static getEventProductPrice( product: InterfaceOWDoclet<InterfaceOWTemplateEventProduct>, purchaseDateYYYYMM1DD: string, targetDateYYYYMM1DD: string, soldCount: number = 0 ): number {
		// this is the most up to date way to grab a price tag, by purchase date and visit date.
		let output: number = 0;
		const pDate: Date | null = ServiceRegex.YYYYMMDDExp.test( purchaseDateYYYYMM1DD ) ? TransformerDate.dateFromYYYYMM1DD( purchaseDateYYYYMM1DD ) : null;
		if ( pDate === null ) {
			return output;
		} else {
			pDate.setHours( 11, 0, 0, 0 ); // noon
		}
		if ( Array.isArray( product.data.price_matrix ) ) {
			const priceMatrix: InterfaceOWTemplateEventProduct['price_matrix'] = product.data.price_matrix;
			let purchaseDateFound: boolean = false;
			let theDefaultPurchaseDateMatrix: InterfaceOWTemplateEventProduct['price_matrix'][number] | null = null;
			for ( let x: number = 0; x < priceMatrix.length; ++x ) {
				if ( priceMatrix[x].purchase_date === 'default' ) {
					theDefaultPurchaseDateMatrix = priceMatrix[x]; // we'll need this later in-case we don't find a match...
				} else {
					const arrPurchaseDateRange: string[] = priceMatrix[x].purchase_date.split( /:/g );
					const startDate: Date | null = arrPurchaseDateRange.length > 0 && ServiceRegex.YYYYMMDDExp.test( arrPurchaseDateRange[0] )
						? TransformerDate.dateFromYYYYMM1DD( arrPurchaseDateRange[0] )
						: null;
					let stopDate: Date | null = null;
					if ( arrPurchaseDateRange.length > 1 ) { // if there is a stop date and it wasn't just an array of length 1...
						if ( ServiceRegex.YYYYMMDDExp.test( arrPurchaseDateRange[1] ) ) {
							stopDate = TransformerDate.dateFromYYYYMM1DD( arrPurchaseDateRange[1] );
						}
					} else {
						if ( startDate !== null ) {
							stopDate = new Date( startDate );
						}
					}
					if ( startDate === null || stopDate === null ) {
						console.log( 'Discovered an invalid purchase_date on a (product).data.price_matrix[' + x + '].purchase_date => ', priceMatrix[x].purchase_date, product );
						continue;
					} else {
						startDate.setHours( 0, 0, 0, 0 );
						// pDate is set to noon, so if the start & stop dates are the same Y/M/D, then pDate will still be in between start & stop dates.
						stopDate.setHours( 23, 59, 59, 999 );
					}
					let purchaseDateWithinRange: boolean = startDate.getTime() < pDate.getTime() && stopDate.getTime() > pDate.getTime();
					if ( purchaseDateWithinRange ) {
						purchaseDateFound = true;
						output = TransformerEventProductBase.getPriceFromPriceMatrix( priceMatrix[x], targetDateYYYYMM1DD, soldCount );
					} // end if the purchase date is within the range of dates on the product's purchase_date.
				} // end else we did not stumble upon the default price matrix case.
			} // end for each price matrix
			if ( !purchaseDateFound && theDefaultPurchaseDateMatrix !== null ) {
				// we didn't find a matching purchase_date and had to use the default.
				output = TransformerEventProductBase.getPriceFromPriceMatrix( theDefaultPurchaseDateMatrix, targetDateYYYYMM1DD, soldCount );
			}
		} // end if the price matrix exists. this should always be true.
		return output;
	}
}
