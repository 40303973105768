import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIAssetsFiles {
	private readonly routePrefix: string = 'workspace/';
	//
	public constructor(
		private readonly gateway: ServiceOWGateway
	) {
		//
	}

	private getFile( fileID: string, asBase64: boolean ): Observable<InterfaceHTTPGateway> {
		// grabbing files out of OW requires an auth token. so you will *not* be able to use them in img tags. (normally)
		return this.gateway.fetch( 'get', this.routePrefix + 'file/' + encodeURIComponent( fileID ) + (asBase64 ? '/b64' : '') );
	}

	// ========================= Files ========================= //

	public getFileAsBase64( fileID: string ): Observable<InterfaceHTTPGateway> {
		return this.getFile( fileID, true );
		// { "status" : 200 , "results" : [ { "image_data" : "data:image/png;base64,iVBORw0KGg..." } ] }
		// response.body.results[0].image_data //
	}
}
