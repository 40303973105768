import {Pipe, PipeTransform} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceOWDoclet, InterfaceOWTemplateVenueEvent} from '../interfaces/interfaces';
// ===== Services ===== //
import {ServiceRegex} from '../services/regex';
// ===== Transformers ===== //
import {TransformerDate} from '../transformers/date';
// ===== Types =====
type datePipeOverloading = {
	// key: value. //
	// style: 3rd param's <T> here. //
	'day': undefined;
	'event-m/d/y-m/d/y': InterfaceOWDoclet<InterfaceOWTemplateVenueEvent>;
	'event-month-day-year': InterfaceOWDoclet<InterfaceOWTemplateVenueEvent>;
	'm/d/y': undefined;
	'short-month': string[];
	'long-month': string[];
}
//
@Pipe( {
	name: 'dateFromYYYYMM1DD',
	pure: true
} )
export class PipeDateFromYYYYMM1DD implements PipeTransform {
	private static typeIsVenueEventDoclet( obj: unknown ): obj is InterfaceOWDoclet<InterfaceOWTemplateVenueEvent> {
		return !!(obj && typeof obj === 'object' && 'data' in obj && obj.data && typeof obj.data === 'object' && 'start_date' in obj.data && 'end_date' in obj.data);
	}

	private static getMonthFromYYYYMMDD( YYYYMMDD: string, monthLabels?: string[] ) {
		const labels: string[] = Array.isArray( monthLabels ) && monthLabels.length > 0 ? monthLabels : TransformerDate.getCalendarMonths();
		const m: number = Number.parseInt( YYYYMMDD.split( /-/g )[1], 10 );
		return labels[m - 1];
	}

	public transform<T extends keyof datePipeOverloading>( YYYYMM1DD: string, style?: T, extraContent?: datePipeOverloading[T] ): string {
		switch ( style ) {
			// for each switch-case entry, it must already exist in the datePipeOverloading object as a key.
			// the value of the KVP is simply the type of the 3rd param for extra content.
			default:
			case 'm/d/y': { // TODO: change the name of this. the POS uses it and needs to be updated/replaced.
				return TransformerDate.strDisplayMDYFromYYYYMM1DD( YYYYMM1DD );
			}
			case 'event-m/d/y-m/d/y': {
				// it's an event length date, meaning we should have two YYYY-MM-DD strings to deal with somehow.
				if ( PipeDateFromYYYYMM1DD.typeIsVenueEventDoclet( extraContent ) ) {
					const startDate: string = TransformerDate.strEventDateDisplayFromYYYYMMD1DD( extraContent.data.start_date );
					const stopDate: string = TransformerDate.strEventDateDisplayFromYYYYMMD1DD( extraContent.data.end_date );
					return startDate + ' - ' + stopDate;
				} // else is the old logic? this behaviour is kind of obsolete...
				if ( YYYYMM1DD === 'event' || YYYYMM1DD.length === 24 ) {
					return '--'; // something forgot to send in the event doclet when this was called. race condition issue, etc..
				}
				return TransformerDate.strEventDateDisplayFromYYYYMMD1DD( YYYYMM1DD );
			}
			case 'event-month-day-year': { // TODO: rename this to be more precise.
				// TODO: "October 25 - 27, 2024"  or "October 25 - November 02, 2024" or "December 29, 2024 - January 04, 2025", etc...
				return '--';
			}
			case 'short-month': {
				if ( ServiceRegex.YYYYMMDDExp.test( YYYYMM1DD ) ) {
					const month: string = PipeDateFromYYYYMM1DD.getMonthFromYYYYMMDD( YYYYMM1DD, extraContent as undefined | string[] );
					return month.substring( 0, 3 );
				}
				if ( YYYYMM1DD === 'any' ) {
					return 'Any';
				}
				if ( YYYYMM1DD === 'event' ) {
					return 'Event Length';
				}
				return 'N/A';
			}
			case 'long-month': {
				if ( ServiceRegex.YYYYMMDDExp.test( YYYYMM1DD ) ) {
					return PipeDateFromYYYYMM1DD.getMonthFromYYYYMMDD( YYYYMM1DD, extraContent as undefined | string[] );
				}
				return 'N/A';
			}
			case 'day': {
				if ( ServiceRegex.YYYYMMDDExp.test( YYYYMM1DD ) ) {
					return YYYYMM1DD.split( /-/g )[2];
				} else {
					if ( YYYYMM1DD === 'any' ) {
						return '--';
					} else if ( YYYYMM1DD === 'event' ) {
						return '--';
					}
				}
				return '--';
			}
		}
	}
}
