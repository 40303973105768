<div class="busy" *ngIf="busy">Loading Order History...</div>
<div class="order-history" *ngIf="!busy">
	<div class="nothing-to-show" *ngIf="orderHistoryTree.orderIDs.length < 1"></div>
	<div class="order"
		*ngFor="let oID of orderHistoryTree.orderIDs;"
	>
		<div class="transactions"
			*ngFor="let tID of orderHistoryTree.orders[oID].transactionIDs;"
		>
			<div class="meta">
				<span class="date">{{ orderHistoryTree.orders[oID].transactions[tID].data.ts.$date | date : 'mediumDate' }}</span>
				<span class="status">{{ orderHistoryTree.orders[oID].transactions[tID] | transactionStatus }}</span>
				<span class="charge-refund">{{ orderHistoryTree.orders[oID].transactions[tID] | transactionAction }}</span>
			</div>
			<div class="items"
				*ngFor="let item of orderHistoryTree.orders[oID].transactions[tID].data.items; let idx = index;"
			>
				<span class="ticket-name">{{ item.item }}</span>
				<span class="ticket-date">{{ item.date | dateFromYYYYMM1DD }}</span>
				<span class="ticket-price">{{ item.price | currency : 'USD':'symbol-narrow':'1.0-0' }}</span>
			</div>
		</div>
	</div>
</div>
