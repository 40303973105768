import {Injectable} from '@angular/core';
// ===== Interfaces ===== //
import {
	InterfaceAdmissionQRCode,
	InterfaceMemberEntitlements,
	InterfaceMemberEntitlementsByEventDateMember,
	InterfaceOWDoclet,
	InterfaceOWDocletConsumer,
	InterfaceScannedTicket
} from '../interfaces/interfaces';
// ===== Transformers ===== //
import {TransformerText} from './text';
//
@Injectable( {
	providedIn: 'root'
} )
export class TransformerEventTicket { // intended to be used on the tickets sold, not the passes for sale.
	//
	public constructor() {
		//
	}

	public static getTicketTimeDisplayFromHHMMSS( HHMMSS: string ): string {
		// HH:MM:SS ranges from 00:00:00 to 23:59:59
		// 16:00:00 should output "4:00 PM"
		let output: string = '';
		const arr: string[] = HHMMSS.split( /:/g );
		if ( arr.length === 3 ) {
			const hour: number = Number( arr[0] ) % 24;
			const minutes: number = Number( arr[1] );
			// const seconds: number = Number( arr[2] );
			const isPM: boolean = hour > 11;
			const hour12: number = (hour % 12) === 0 ? 12 : hour % 12;
			output = String( hour12 );
			if ( Number( minutes ) > 0 ) {
				output += ':' + String( '0' + minutes ).slice( -2 );
			}
			output += ' ' + (isPM ? 'PM' : 'AM');
		}
		return output;
	}

	public static getTicketTimeDisplayFromTicketDoclet( ticket: InterfaceOWDoclet ): string {
		let HHMMSS: string | undefined = ticket?.data?.['time'];
		if ( typeof HHMMSS === 'string' ) {
			return TransformerEventTicket.getTicketTimeDisplayFromHHMMSS( HHMMSS );
		}
		return '';
	}
/*
	public static getTicketTimeFromPriceType( ticketPricing: InterfaceEventPassPriceType | undefined, selectedYYYYMMDD1?: string ): string | undefined {
		let HHMMSS: string | undefined = undefined; // 'HH:MM:SS' or undefined if it cannot be found.
		if ( ticketPricing ) {
			if ( selectedYYYYMMDD1 && selectedYYYYMMDD1 in ticketPricing ) {
				if ( typeof ticketPricing[selectedYYYYMMDD1]['time'] === 'string' ) {
					HHMMSS = ticketPricing[selectedYYYYMMDD1]['time'] as string;
				}
			} else if ( 'default' in ticketPricing ) {
				if ( typeof ticketPricing['default']['time'] === 'string' ) {
					HHMMSS = ticketPricing['default']['time'];
				}
			}
		}
		return HHMMSS;
	}

	public static getTicketTimeDisplayFromPriceType( ticketPricing: InterfaceEventPassPriceType | undefined, selectedYYYYMMDD1?: string ): string {
		let output: string = '';
		let HHMMSS: string | undefined = TransformerEventTicket.getTicketTimeFromPriceType( ticketPricing, selectedYYYYMMDD1 );
		if ( typeof HHMMSS === 'string' ) {
			output = TransformerEventTicket.getTicketTimeDisplayFromHHMMSS( HHMMSS );
		}
		return output;
	}
*/

	// TODO: make this obsolete
	public static getAdmissionTicketTypeDisplay( ticket: InterfaceOWDoclet ): string {
		let output: string = '';
		/* [ // Daily Admission Pass
			"Adopt Marine",
			"Adult Admission Pass",
			"Child Fund",
			"Employee Admission Pass",
			"Evening Admission Pass",
			"General",
			"General Admission Pass",
			"General Admission Pass 50",
			"General Admission Pass 55",
			"General Admission Pass Comp",
			"Group Admission Pass",
			"Junior",
			"Junior Admission Pass",
			"Junior Admission Pass 30",
			"Junior Admission Pass 35",
			"Junior Admission Pass Comp",
			"Memorial Day Admission Pass",
			"Military Admission Pass",
			"Mother's Day Special",
			"Opening Weekend Special",
			"Senior Admission Pass"
		] */

		if ( typeof ticket?.tc_raw?.$date === 'number' ) {
			if ( ticket.tc_raw.$date < 1655872878101 ) { // old tickets didn't have a type...
				// adults/general were 65 and 60.
				// junior were 40 and 35
				if ( ticket.data['purchase_price'] && ticket.data['purchase_price'] < 60 ) {
					return 'Junior'
				}
			}
		}

		if ( typeof ticket?.data?.['type'] === 'string' ) {
			const ticketType: string = ticket.data['type'];
			switch ( ticketType.toLowerCase() ) {
				case 'general': {
					return 'General';
				}
				case 'junior': {
					return 'Junior';
				}
				default: {
					if ( ticketType.match( /admission pass/i ) ) { // 'Whatever Admission Pass 123'
						const arr: string[] = ticketType.split( /admission pass/i );
						return arr[0].replace( /^\s+|\s+$/g, '' ); // 'Whatever'
					}
					break;
				}
			}
		}
		return output;
	}

	public static getConsumerNameFromScannedTicket( scannedTicket?: InterfaceScannedTicket ): string {
		let firstName: string = scannedTicket?.consumer?.data?.['first_name'] ?? '';
		let lastName: string = scannedTicket?.consumer?.data?.['last_name'] ?? '';
		return TransformerText.getCombinedStringsOrUndefined( firstName, lastName ) ?? 'UNASSIGNED';
	}

	public static getAssignedNameFromScannedTicket( scannedTicket?: InterfaceScannedTicket ): string {
		let firstName: string = scannedTicket?.ticket?.data?.['assigned_first_name'] ?? '';
		let lastName: string = scannedTicket?.ticket?.data?.['assigned_last_name'] ?? '';
		return TransformerText.getCombinedStringsOrUndefined( firstName, lastName ) ?? 'UNASSIGNED';
	}

	public static memberEntitlementsSortedByEventDateMember( memberEntitlements: InterfaceMemberEntitlements[] ): InterfaceMemberEntitlementsByEventDateMember {
		const output: InterfaceMemberEntitlementsByEventDateMember = {
			eventIDs: [],
			eventData: {}
		};
		memberEntitlements.forEach( (entry: InterfaceMemberEntitlements): void => {
			// ===== shared entitlements - BEGIN ===== //
			for ( let x: number = 0; x < entry.entitlements.length; ++x ) {
				const eventID: string = entry.entitlements[x].data.event_id.$oid;
				if ( !(eventID in output.eventData) ) {
					output.eventIDs.push( eventID );
					output.eventData[eventID] = {
						visitDates: [],
						visitData: {}
					};
				}
				// YYYY-MM-DD:YYYY-MM-DD is 22 chars. the mongo ID is 24.
				const visitDate: string = (entry.entitlements[x].data.date?.length === 24) ? 'event' : (entry.entitlements[x].data.date ?? '');
				if ( !(visitDate in output.eventData[eventID].visitData) ) {
					output.eventData[eventID].visitDates.push( visitDate );
					output.eventData[eventID].visitData[visitDate] = {
						consumerIDs: [],
						consumerData: {},
						sharedEntitlements: []
					};
				} // end if we haven't seen this visit date yet.
				output.eventData[eventID].visitData[visitDate].sharedEntitlements.push( entry.entitlements[x] );
			} // end for each shared entitlement for this bucket.
			// ===== shared entitlements - END ===== //
			// ===== consumer entitlements - BEGIN ===== //
			for ( let x: number = 0; x < entry.consumers.length; ++x ) {
				// for each consumer, make sure they have at least 1 Admission QR Code.
				// otherwise skip them, because they don't have enough info to display to be useful.
				if ( entry.consumers[x].admissions.length > 0 ) {
					const consumer: InterfaceOWDocletConsumer = entry.consumers[x].consumer;
					const consumerID: string = consumer._id.$oid;
					for ( let y: number = 0; y < entry.consumers[x].admissions.length; ++y ) {
						const admissionQRCode: InterfaceOWDoclet<InterfaceAdmissionQRCode> = entry.consumers[x].admissions[y];
						const eventID: string = admissionQRCode.data.event_id.$oid;
						if ( !(eventID in output.eventData) ) {
							output.eventIDs.push( eventID );
							output.eventData[eventID] = {
								visitDates: [],
								visitData: {}
							};
						}
						let visitDate: string = (admissionQRCode.data.date?.length === 24) ? 'event' : (admissionQRCode.data.date ?? '');
						if ( !(visitDate in output.eventData[eventID].visitData) ) {
							output.eventData[eventID].visitDates.push( visitDate );
							output.eventData[eventID].visitData[visitDate] = {
								consumerIDs: [],
								consumerData: {},
								sharedEntitlements: []
							};
						} // end if we haven't seen this visit date yet.
						if ( !(consumerID in output.eventData[eventID].visitData[visitDate].consumerData) ) {
							output.eventData[eventID].visitData[visitDate].consumerIDs.push( consumerID );
							output.eventData[eventID].visitData[visitDate].consumerData[consumerID] = {
								admissions: [],
								consumer: consumer // this shouldn't make loads of copies of the consumer. it should make pointers to the one JS object.
							};
						} // end if we haven't seen this consumer by ID yet for this visit date.
						output.eventData[eventID].visitData[visitDate].consumerData[consumerID].admissions.push( admissionQRCode );
					} // end for each Admission QR Code assigned to this consumer. (which might be the purchaser)
				} // end if this consumer has any entitlements.
			} // end for each consumer in this bucket
			// ===== consumer entitlements - END ===== //
		} );
		return output;
	}
}
