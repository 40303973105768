<span
	[ngClass]="{
		'drop-down': true,
		'error': isError,
		'selecting-an-item': selectingAnItem
	}"
>
	<span class="selected-item"
		(click)="toggleMenu();"
	>{{ selectedIndex === null ? placeholderText : items[selectedIndex] }}</span>
	<ul>
		<li
			*ngFor="let item of items; let idx = index;"
			[ngClass]="{
				'selected': idx === selectedIndex
			}"
			(click)="setSelected( idx )"
		>{{ item }}</li>
	</ul>
</span>
