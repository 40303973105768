// TODO: make this interface obsolete
export interface InterfaceOWSeasonPassAdmissionAvailability { // action 629e5e5ae32a107779d1da3b
	[YYYMMDD: string]: string[]; // [ YYYY-MM-DD ]: string[];
}
/*
{
	"2022-07-21": [
		"62a3a5bf09c5d46674cd1e82",
		"62a3a5c009c5d46674cd1e84",
		"62a3a5c009c5d46674cd1e86"
	],
	"2022-07-22": [
		"62a3a5bf09c5d46674cd1e82",
		"62a3a5c009c5d46674cd1e84",
		"62a3a5c009c5d46674cd1e86"
	],
	...
}
*/

// TODO: make this interface obsolete.
export interface InterfaceOWDailyAdmissionAvailability { // action 62aa7829e32a1077798236aa
	[YYYMMDD: string]: { // { "YYYY-MM-DD" : { ... } }
		[settings_key: string]: number; // ticket_availability: 0
	};
}

// TODO: make this interface the primary/default capacity interface.
export interface InterfaceEventProductAvailability {
	[YYYYMM1DD: string]: { // [ { "YYYY-MM-DD" : { ... } } ]
		[docletID: string]: {
			item_count: number; // amount sold.
			item_availability: number; // amount remaining.
		};
	};
}

// TODO: find out if this interface can become obsolete?
export interface InterfaceOWDailyReservedSeating {
	[ticket_key: string]: string[];
}
