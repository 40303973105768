// ===== Interfaces ===== //
import {InterfaceObjectId} from '../ow/mongo/object-id';
import {InterfaceOWDoclet} from '../ow/doclets';
//
export interface InterfaceOWTemplateConsumer { // database fields
	// the only two fields that exist 99% of the time, are first and last name.
	first_name?: string;
	last_name?: string;
	dob?: string; // DoB and Photo are around, if they bother to supply it...
	photo?: string; // base64 string -- "data:image/png;base64,iVBORw0K...
	profile_locked?: boolean; // once the POS approves the profile photo for season pass users, their profile data cannot be changed.
	// Purchasers can turn on/off cashless spending. I forget if this field was moved ot the account doclet or not..
	cashless_spending?: boolean; // purchasers have this, family members do not.
	// the rest of these fields were never implemented
	phone?: string;
	street_address?: string;
	suite?: string;
	city?: string;
	state?: string;
	zip_code?: string;
	drivers_license?: string;
	//
	guest_order?: boolean; // POS anonymous users are auto-created, and have this flag. No profile is linked to this Consumer.
	// i forget where these next two fields are used... Groups, maybe.
	invited_email?: string;
	invited_status?: string; // pending, accepted, declined
}

export interface InterfaceOWDocletConsumer extends InterfaceOWDoclet {
	data: InterfaceOWTemplateConsumer;
	realm?: {
		id: InterfaceObjectId;
		pid: InterfaceObjectId;
	};
}

/*
{
	"_id" : ObjectId("627959e6f99ef9cce0fba2b3"),
	"name" : "Consumer",
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"fields" : [
		{
			"type" : "string",
			"sort" : 1,
			"label" : "First Name",
			"key" : "first_name"
		},
		{
			"type" : "string",
			"sort" : 2,
			"label" : "Last Name",
			"key" : "last_name"
		},
		{
			"type" : "string",
			"sort" : 4,
			"label" : "Phone",
			"key" : "phone"
		},
		{
			"type" : "string",
			"sort" : 5,
			"label" : "Street Address",
			"key" : "street_address"
		},
		{
			"type" : "string",
			"sort" : 6,
			"label" : "Suite",
			"key" : "suite"
		},
		{
			"type" : "string",
			"sort" : 7,
			"label" : "City",
			"key" : "city"
		},
		{
			"type" : "string",
			"sort" : 8,
			"label" : "State",
			"key" : "state"
		},
		{
			"type" : "string",
			"sort" : 9,
			"label" : "Zip Code",
			"key" : "zip_code"
		},
		{
			"type" : "string",
			"sort" : 10,
			"label" : "Drivers License",
			"key" : "drivers_license"
		},
		{
			"type" : "string",
			"sort" : 11,
			"label" : "Photo",
			"key" : "photo"
		},
		{
			"type" : "string",
			"sort" : 12,
			"label" : "Date of Birth",
			"key" : "dob"
		},
		{
			"label" : "Profile Locked",
			"key" : "profile_locked",
			"sort" : 13,
			"type" : "boolean"
		},
		{
			"label" : "Email to Invite",
			"key" : "invited_email",
			"sort" : 14,
			"type" : "string"
		},
		{
			"label" : "Invite Status",
			"key" : "invited_status",
			"sort" : 15,
			"type" : "string"
		}
	],
	"realms" : [
		ObjectId("625f38c561b6eb3efaebceb3")
	],
	"tags" : [
		"Consumer"
	]
}
*/
