// ===== Interfaces ===== //
import {InterfaceAnyObject} from '../angular/vanilla-js';
import {InterfaceObjectDate} from './mongo/object-date';
import {InterfaceObjectId} from './mongo/object-id';
import {InterfaceOWWorkspaceEntry} from './workspaces';
//
export interface InterfaceOWUserProfileData {
	first_name: string;
	last_name: string;
}
//
interface InterfaceOWUserTimestampsKVP {
	tc?: string;
	tc_raw?: InterfaceObjectDate;
	created_timestamp?: string;
	created_timestamp_raw?: InterfaceObjectDate;
	created_timestamp_utc?: string;
	created_timestamp_utc_raw?: InterfaceObjectDate;
	updated_timestamp?: string;
	updated_timestamp_raw?: InterfaceObjectDate;
	updated_timestamp_utc?: string;
	updated_timestamp_utc_raw?: InterfaceObjectDate;
}

interface InterfaceOWUserRegistrationKVP {
	email: string;
	confirmed: boolean;
	email_to_confirm?: string;
	confirmed_emails?: string[];
}

interface InterfaceOWUserProfileKVP { // this is specific to the ow_user profile, not a workspace.
	profile: InterfaceOWUserProfileData; // extra fields on the user's profile, separate from the workspace's extra fields.
}

interface InterfaceOWUserWorkspaceKVP {
	active_workspace_id: string; // not an ObjectId
	workspace_map: InterfaceOWWorkspaceEntry[];
}

export interface InterfaceOWUser extends InterfaceOWUserProfileKVP, InterfaceOWUserRegistrationKVP, InterfaceOWUserTimestampsKVP, InterfaceOWUserWorkspaceKVP {
	// this is the latest data-struct. April 2024.
	_id: InterfaceObjectId;
	account_id: InterfaceObjectId;
	ow_roles: InterfaceObjectId[];
	realm_id: InterfaceObjectId;
	status: 0 | 1 | number;
}

interface InterfaceOWUserProfile { // db.user_profiles.findOne()
	_id: InterfaceObjectId;
	account_id: InterfaceObjectId;
	email: string;
	email_to_confirm?: string;
	companies: InterfaceObjectId[]; // defunct
	component_workspace: InterfaceObjectId[];
	confirmed: boolean;
	created_timestamp: string;
	created_timestamp_raw: InterfaceObjectDate;
	created_timestamp_utc: string;
	created_timestamp_utc_raw: InterfaceObjectDate;
	realm_id: InterfaceObjectId;
	status: 0 | 1;
	updated_timestamp: string;
	updated_timestamp_raw: InterfaceObjectDate;
	updated_timestamp_utc: string;
	updated_timestamp_utc_raw: InterfaceObjectDate;
}
