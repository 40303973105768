<app-masthead
	[mastHeading]="mastHeading"
	[navMenuItems]="navMenuItems"
	[serialCode]="serialCode"
></app-masthead>
<div id="page-orders" class="page wrapper">
	<section class="card columned cc-on-file">
		<div>
			<h3>
				Cashless Spending
				<ui-toggle-box *ngIf="haveAccountInfo && recycleTB" [checked]="cashlessSpending" (changed)="cashlessToggle( $event );"></ui-toggle-box>
			</h3>
			<div class="cashless-toggle-wrapper">
				<div class="cashless-spending-note">
					<div>Activate in venue cashless spending by safely storing your credit card. <span class="bold">No need to reach for your wallet or your smartphone</span>. Simply tap your venue issued wristband to make any purchase throughout the venue.</div>
				</div>
			</div>
		</div>
		<div>
			<h3>Card On File<span (click)="showCCModal();">{{ cardsOnFile.length > 0 ? 'Change' : 'Add' }}</span></h3>
			<div class="nothing-to-show" *ngIf="cardsOnFile.length < 1">There are no credit cards to show.</div>
			<div class="cc-list" *ngIf="cardsOnFile.length > 0">
				<ul class="cc-entry" *ngFor="let ccOnFile of cardsOnFile; let idx = index; trackBy: ccTrackBy; ">
					<li><span class="cc-type">{{ ccOnFile.account_type }}</span><span class="cc4">{{ ermahgerdItsFullOfStars( ccOnFile.account_type, ccOnFile.last_four ) }}</span></li>
					<li *ngIf="ccOnFile.card_exp_year && ccOnFile.card_exp_month"><span class="cc-exp-label">Exp. Date</span><span class="cc-exp-mm">{{ ('0' + ccOnFile.card_exp_month).slice( -2 ) }}</span><span class="sep">/</span><span class="cc-exp-yy">{{ ccOnFile.card_exp_year }}</span></li>
				</ul>
			</div>
		</div>
	</section>
	<section class="card twin-half-columns billing-shipping">
		<div class="form">
			<h3>Billing</h3>
			<label class="wide">
				<span class="frame-label">Street Address <b>*</b></span>
				<input type="text" name="b-address" autocomplete="off"
					[class.error]="accountBillingErrors.street"
					[(ngModel)]="accountBillingData.street"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateAccountBilling( 'street' );"
				/>
			</label>
			<label>
				<span class="frame-label">City <b>*</b></span>
				<input type="text" name="b-city" autocomplete="off"
					[class.error]="accountBillingErrors.city"
					[(ngModel)]="accountBillingData.city"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateAccountBilling( 'city' );"
				/>
			</label>
			<label>
				<span class="frame-label">State <b>*</b></span>
				<input type="text" name="b-state" autocomplete="off"
					[class.error]="accountBillingErrors.state"
					[(ngModel)]="accountBillingData.state"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateAccountBilling( 'state' );"
				/>
			</label>
			<label>
				<span class="frame-label">Zip Code <b>*</b></span>
				<input type="text" name="b-zip" autocomplete="off"
					[class.error]="accountBillingErrors.zip"
					[(ngModel)]="accountBillingData.zip"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateAccountBilling( 'zip' );"
				/>
			</label>
			<label>
				<span class="frame-label">Phone</span>
				<input type="text" name="b-phone" autocomplete="off"
					[(ngModel)]="accountBillingData.phone"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateAccountBilling( 'phone' );"
				/>
			</label>
		</div>
		<div class="form">
			<h3>Shipping<ui-check-box
				[cssSize]="22"
				[text]="strSameAsBilling"
				[checked]="sameAsBilling"
				(changed)="setSameAsBilling( $event );"
			></ui-check-box></h3>
			<label class="wide">
				<span class="frame-label">Street Address <b>*</b></span>
				<input type="text" name="s-address" autocomplete="off"
					[readonly]="sameAsBilling"
					[class.error]="accountShippingErrors.street"
					[(ngModel)]="accountShippingData.street"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateAccountShipping( 'street' );"
				/>
			</label>
			<label>
				<span class="frame-label">City <b>*</b></span>
				<input type="text" name="s-city" autocomplete="off"
					[readonly]="sameAsBilling"
					[class.error]="accountShippingErrors.city"
					[(ngModel)]="accountShippingData.city"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateAccountShipping( 'city' );"
				/>
			</label>
			<label>
				<span class="frame-label">State <b>*</b></span>
				<input type="text" name="s-state" autocomplete="off"
					[readonly]="sameAsBilling"
					[class.error]="accountShippingErrors.state"
					[(ngModel)]="accountShippingData.state"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateAccountShipping( 'state' );"
				/>
			</label>
			<label>
				<span class="frame-label">Zip Code <b>*</b></span>
				<input type="text" name="s-zip" autocomplete="off"
					[readonly]="sameAsBilling"
					[class.error]="accountShippingErrors.zip"
					[(ngModel)]="accountShippingData.zip"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateAccountShipping( 'zip' );"
				/>
			</label>
			<label>
				<span class="frame-label">Phone</span>
				<input type="text" name="s-phone" autocomplete="off"
					[readonly]="sameAsBilling"
					[(ngModel)]="accountShippingData.phone"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateAccountShipping( 'phone' );"
				/>
			</label>
		</div>
		<div class="actions" *ngIf="haveAccountInfo">
			<span
				[ngClass]="{
					'action': true,
					'button': true,
					'save': true,
					'busy': formBusy
				}"
				(click)="saveBillingShipping();"
			>Save Changes<i class="busy-icon fa-solid fa-spin fa-circle-notch"></i></span>
		</div>
	</section>
	<section class="card" style="display: none;">
		<app-order-history [purchaserDocletID]="purchaserDocletID"></app-order-history>
	</section>
</div>
