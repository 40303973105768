import {Injectable} from '@angular/core';
// ===== Interfaces ===== //
import {
	InterfaceDocletIDToTicketProps,
	InterfaceOWDoclet,
	InterfaceOWDocletWithEntitlement,
	InterfaceOWTemplateEventPass
} from '../interfaces/interfaces';
interface InterfaceRoles {
	admin: string;
	pos: string;
	staff: string;
	web: string;
}
// ===== Transformers ===== //
import {TransformerEventProductBase} from './event-product-base';
//
@Injectable( {
	providedIn: 'root'
} )
export class TransformerEventPasses { // intended to be used on the passes for sale, not the sold tickets.
	//
	public constructor() {
		//
	}

	public static processPassProps( eventPass: InterfaceOWDocletWithEntitlement<InterfaceOWTemplateEventPass>, roleIDs: InterfaceRoles ): InterfaceDocletIDToTicketProps<InterfaceOWTemplateEventPass> {
		// TODO: swap ref's from the old fn names to the new ones??
		return TransformerEventProductBase.processEventProductProps( eventPass, roleIDs );
	}

	public static getEventPassPriceV2( passForSale: InterfaceOWDoclet<InterfaceOWTemplateEventPass>, purchaseDateYYYYMM1DD: string, targetDateYYYYMM1DD: string, soldCount: number = 0 ): number {
		return TransformerEventProductBase.getEventProductPrice( passForSale, purchaseDateYYYYMM1DD, targetDateYYYYMM1DD, soldCount );
	}
}
