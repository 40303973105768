import {Injectable} from '@angular/core';
// ===== Services ===== //
import {ServiceOWAPIAccount} from './api/ow/account';
import {ServiceOWAPIAssets} from './api/ow/assets';
import {ServiceOWAPICore} from './api/ow/core';
import {ServiceOWAPIMessage} from './api/ow/message';
import {ServiceOWAPIRealm} from './api/ow/realm';
import {ServiceOWAPISupport} from './api/ow/support';
import {ServiceOWAPIWorkspace} from './api/ow/workspace';
//
@Injectable( {
	providedIn : 'root'
} )
export class ServiceOWAPI {
	//
	public constructor(
		public readonly account: ServiceOWAPIAccount, // sign-in, sign-up, forgot password, verify email address, profile, update password
		public readonly assets: ServiceOWAPIAssets, // files
		public readonly core: ServiceOWAPICore, // workspace assignment, invite users, remove users
		public readonly message: ServiceOWAPIMessage, // chat
		public readonly realm: ServiceOWAPIRealm, // realm
		public readonly support: ServiceOWAPISupport, // support tickets
		public readonly workspace: ServiceOWAPIWorkspace // doclets, workflow tasks, custom actions
	) {
		//
	}
}
