import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {InterfaceAnyObject, InterfaceAppContext, InterfaceHTTPGateway} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../authentication';
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIAccountAuth {
	private readonly routePrefix: string = 'account/';
	//
	public constructor(
		private readonly auth: ServiceAuthentication,
		private readonly gateway: ServiceOWGateway
	) {
		//
	}

	public validateAuthToken(): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + 'auth' );
	}

	public guestLogin( appContext: InterfaceAppContext, email: string, templateID: string, templateProperties: InterfaceAnyObject, profileProperties: InterfaceAnyObject ): Observable<InterfaceHTTPGateway> {
		// this will always succeed (HTTP 200), unless you purposely botch it with invalid values.
		return this.gateway.fetch( 'post', this.routePrefix + 'auth/guest', JSON.stringify( {
			auth: {
				email: email.toLowerCase()
				// no password required, nor is even looked for.
			},
			realm_id: appContext.realmID,
			profile: profileProperties, // { first_name: string, last_name: string }
			template_id: templateID,
			template: templateProperties, // { first_name: value, last_name: value, ... }
			app_key: appContext.appKey, // db.services.findOne( ... ).app_key -- defines which workspaces are subscribed to this app...
			workspace_id: appContext.workspaceID // the instance of the app (the venue, physical location, water park, whatever)
		} ), true );
	}

	public login( appContext: InterfaceAppContext, email: string, password: string, appKey?: string ): Observable<InterfaceHTTPGateway> {
		// you log into an instance of the app (the realm and workspace)
		// not into the application definition (appRealm appWorkspace)
		return this.gateway.fetch( 'post', this.routePrefix + 'auth', JSON.stringify( {
			app_key: appKey, // if supplied, you will ONLY get workspaces back that belongs to that app_key. you get your user back as always, though.
			realm_id: appContext.realmID,
			workspace_id: appContext.workspaceID.length < 1 ? undefined : appContext.workspaceID, // the instance of the app (the venue, the park, etc)
			auth: { // this is the new field name
				email: email.toLowerCase(), // "email" is the new field name, and "username" was the old one"
				username: email.toLowerCase(),
				password: password
			},
			auth_params: { // this is an old, defunct field, will eventually be removed.
				username: email.toLowerCase(),
				password: password
			}
		} ), true );
	}

	public updatePassword( appContext: InterfaceAppContext, email: string, oldPassword: string, newPassword: string ): Observable<InterfaceHTTPGateway> {
		// regardless of the email address used, the currently logged in users password is changed. it doesn't even have to match...
		return this.gateway.fetch( 'post', this.routePrefix + 'auth/password', JSON.stringify( {
			profile_id: this.auth.getProfileID(),
			realm_id: appContext.realmID,
			email: email,
			old_password: oldPassword,
			password: newPassword
		} ) );
		// returns status code 417 if the old password is wrong. returns status 400 if the password is incorrect.
	}

	public redeemPasswordResetToken( token: string, newPassword: string ): Observable<InterfaceHTTPGateway> {
		if ( token.length > 0 && newPassword.length > 7 ) {
			return this.gateway.fetch( 'post', this.routePrefix + 'auth/password/reset/' + encodeURIComponent( token ), JSON.stringify( {
				password: newPassword
			} ), true );
		} else {
			return this.gateway.requestDenied();
		}
		// returns 200 on success. 400 if the token is bad.
	}
}
