// ===== Modules ===== //
import {NgModule} from '@angular/core';
// ===== Transformers ===== //
import {TransformerDate} from './date';
import {TransformerEventMerchandise} from './event-merch';
import {TransformerEventPasses} from './event-passes';
import {TransformerEventProductBase} from './event-product-base';
import {TransformerEventTicket} from './event-ticket';
import {TransformerFile} from './file';
import {TransformerMongoObjectId} from './mongo/ObjectId';
import {TransformerNFC} from './nfc';
import {TransformerOWAPI} from './owapi';
import {TransformerText} from './text';
import {TransformerTransactions} from './transactions';
// ===== //
const transformers: any[] = [ // if the transformers (or anything else) have the {"providedIn": "root"}, then they're already part of the "app", and don't need to also be in the "providers" array...
	TransformerDate,
	TransformerEventMerchandise,
	TransformerEventPasses,
	TransformerEventProductBase,
	TransformerEventTicket,
	TransformerFile,
	TransformerMongoObjectId,
	TransformerNFC,
	TransformerOWAPI,
	TransformerText,
	TransformerTransactions
];
//
@NgModule( {
	declarations: [],
	exports: [],
	imports: [],
	providers: transformers // should leave this as a blank array, if you also provide a .forRoot() function that returns the same junk.
} )
export class ModuleTransformers {
	/*
	public static forRoot() { // this method is obsolete if the `providers: []` was populated properly.
		return { // this is the magic angular uses to ensure the module is treated like a Singleton...
			ngModule: ModuleTransformer,
			providers: transformer
		}
	}
	*/
}
