// ===== Interfaces ===== //
import {InterfaceAnyObject} from '../angular/vanilla-js';
import {InterfaceObjectDate} from './mongo/object-date';
import {InterfaceObjectId} from './mongo/object-id';
//
export interface InterfaceOWDocletWidgetComponent {
	type: string;
	name: string;
	data: any;
}
//
export interface InterfaceOWDocletWidget { // widgets may be obsolete
	name: string;
	components: {
		[componentId: string]: InterfaceOWDocletWidgetComponent;
	};
}

export interface InterfaceOWDocletOwner { // unsure if this still exists
	profile_id: InterfaceObjectId;
	name: string;
	email: string;
}

export interface InterfaceOWDocletVerboseWovenDoclets {
	_id: InterfaceObjectId;
	title: string | null;
}

export interface InterfaceOWDocletVerboseKeyMap {
	key: string | any; // for now, it's just strings, but it really could be any data type going forward...
	value: string | any; // same thing for "value" as well, any data type.
}

export interface InterfaceOWDocletVerbose {
	woven_doclets?: InterfaceOWDocletVerboseWovenDoclets[];
	key_map?: InterfaceOWDocletVerboseKeyMap[];
	__KVP?: {
		[docletID: string]: {
			display: string;
		};
	};
}

export interface InterfaceOWDoclet<T = InterfaceAnyObject> {
	_id: InterfaceObjectId;
	data: T;
	ow_roles?: InterfaceObjectId[]; // old records won't have this. new ones might.
	tags: string[];
	template?: string;
	template_id: InterfaceObjectId;
	title: string;
	workspace_id: InterfaceObjectId;

	verbose?: InterfaceOWDocletVerbose;

	meta?: InterfaceAnyObject; // a custom-made thing that typically is only created by editing DB records directly...

	tc: string; // time created, in a human readable format.
	tc_raw: InterfaceObjectDate;
	tc_utc: string;
	tc_utc_raw: InterfaceObjectDate;

	user_id_created: undefined | any; // TODO: what is this about?
	stats: undefined | any; // TODO: what is this about?
	status: undefined | any; // TODO: what is this about?
}

export interface InterfaceDocletVerboseKVPByDocletID {
	[docletID: string]: {
		display: string;
		// more fields may exist in the future...
	};
}
// ===== Types ===== //
export type TypeDocletReference = InterfaceObjectId | null;
