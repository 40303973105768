import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {InterfaceAppContext, InterfaceHTTPGateway} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIWorkspaceSettings {
	private readonly routePrefix: string = 'workspace/';
	//
	public constructor(
		private readonly gateway: ServiceOWGateway
	) {
		//
	}

	public getSettings( appContext: InterfaceAppContext ): Observable<InterfaceHTTPGateway> { // apiResponse.data.items[]
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/settings' );
	} // groups of settings are a thing. this gets them all.

	public getSettingsByID( appContext: InterfaceAppContext, id: string ): Observable<InterfaceHTTPGateway> { // apiResponse.data === settings
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/settings/' + id );
	} // just get 1 group of settings. because maybe you want to put a bunch of flags and settings together, but not everything into one fat document.

	// TODO: remove a setting

	// TODO: update a setting

	// TODO: create a setting
}
