import {Pipe, PipeTransform} from '@angular/core';
// ===== Services ===== //
import {ServiceRegex} from '../services/regex';
//
@Pipe( {
	name: 'initials'
} )
export class PipeInitials implements PipeTransform {
	private static readonly regexpOneLine = /[\n\r\t]+/g;
	private static readonly regexpAlphaSpaceOnly = /[^a-z A-Z]/g;
	private static readonly regexpCollapseSpaces = / +/g;
	public transform( value: string ): string {
		// "  First 77Name here " => "F H"
		const arr: string[] = value
			.replace( PipeInitials.regexpOneLine , ' ' ) // convert things to one fat line.
			.replace( PipeInitials.regexpAlphaSpaceOnly , '' ) // strip away all non a-z, keeping all spaces.
			.replace( PipeInitials.regexpCollapseSpaces, ' ' ) // smash together all whitespaces
			.replace( ServiceRegex.trimRegExp, '' ) // strip away whitespace on the edges, if any.
			.split( / /g ).map( (str: string): string => str.length > 0 ? str[0] : '' )
			.filter( (str: string): boolean => str.length > 0 );
		return (arr[0] ?? '').toUpperCase() + ' ' + (arr.pop() ?? '').toUpperCase();
	}
}
