import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, ParamMap} from '@angular/router';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../../../../../ow-framework/collections/profiles';
// ===== Interfaces ===== //
import {
	InterfaceAppContext,
	InterfaceHTTPGateway,
	InterfaceOWAPIGetUserProfileResponse,
	InterfaceOWUser,
	InterfaceOWAPIResponse
} from '../../../../../../ow-framework/interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../../../../ow-framework/services/authentication';
import {ServiceNavigate} from '../../../../../../ow-framework/services/navigate';
import {ServiceOWAPI} from '../../../../../../ow-framework/services/ow-api';
//
@Component( {
	selector: 'page-check-email',
	templateUrl: './check-email.html',
	styleUrls: [
		'./check-email.less'
	]
} )
export class PageCheckEmail implements OnInit {
	//
	public readonly routes: typeof AppRouterLinks = AppRouterLinks;
	public shownFrame: 0 | 1 | 2 | 3 = 0; // 0-3, 0 = "Please check your email", 1 = "Verifying...", 2 = "all good, plz sign in", 3 = "invalid ID".
	public emailResent: boolean = false;
	private verifyID: string = '';
	public isSignedIn: boolean = false;
	//
	public constructor(
		private readonly activeRoute: ActivatedRoute,
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly nav: ServiceNavigate,
		private readonly owapi: ServiceOWAPI,
		private readonly title: Title
	) {
		this.title.setTitle( 'Portal' );
	}

	public ngOnInit(): void {
		this.isSignedIn = this.auth.isSignedIn();
		if ( this.isSignedIn ) {
			// if the user is already signed in, they also already may have verified their email address...
			this.owapi.account.profile.getUserProfile().subscribe( (response: InterfaceHTTPGateway): void => {
				let isVerified: boolean = false;
				if ( response?.success ) {
					const apiResponse: InterfaceOWAPIGetUserProfileResponse = response.data;
					if ( apiResponse?.data ) {
						const userData: InterfaceOWUser = apiResponse.data;
						isVerified = userData && userData.confirmed;
					}
				} // else they're not really signed in...?!?
				if ( isVerified ) {
					// go home user, you're drunk.
					this.nav.toURL( '/' + this.routes.dashboard );
				} else {
					this.processToken();
				}
			} );
			// doesn't matter if they're signed in or not. we only care about verifying their email address.
		} else { // else the user is not yet signed in.
			this.processToken();
		}
	}

	private getVerificationID(): void {
		if ( this.activeRoute.snapshot.params && typeof this.activeRoute.snapshot.params['id'] === "string" && this.activeRoute.snapshot.params['id'].length > 0 ) {
			// from the route: /registration/check-email/:id
			this.verifyID = this.activeRoute.snapshot.params['id'];
		} else {
			// else from the route: /registration/check-email?id=
			this.activeRoute.queryParamMap.subscribe( (query: ParamMap): void => {
				const id: string | null = query.get( 'id' );
				if ( typeof id === 'string' && id.length > 0 ) {
					this.verifyID = id;
				}
			} );
		}
	}

	private processToken(): void {
		// don't call this, if the user is already signed in and verified.
		this.getVerificationID();
		if ( this.verifyID ) {
			this.verifyEmail();
		} else {
			this.shownFrame = 0; // check your email. (maybe they haven't even registered yet)
		}
	}

	private verifyEmail(): void {
		this.shownFrame = 1; // verifying your email...
		this.owapi.account.registration.verifyEmail( this.verifyID ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response?.success ) {
				// there is no response.data, it is just {}.
				this.shownFrame = 2;
			} else {
				this.shownFrame = 3;
			}
		} );
	}

	public resendEmail() : void {
		if ( this.auth.isSignedIn() ) {
			this.colProfiles.getMyUserProfile( (userProfile: InterfaceOWUser | null): void => {
				if ( userProfile && userProfile.email && userProfile.email.length > 0 ) {
					const ac: InterfaceAppContext = this.appConfig.getContext();
					this.owapi.account.registration.resendVerifyEmail( ac, userProfile.email ).subscribe( (response: InterfaceHTTPGateway): void => {
						if ( response && response.success && response.status === 200 ) {
							const apiResponse: InterfaceOWAPIResponse = response.data;
							if ( apiResponse ) {
								this.emailResent = true;
							}
						} else {
							this.shownFrame = 3;
							// need to let the user know that their token is bogus, and that they should go try to log in.
						}
					} );
				} else {
					this.shownFrame = 3; // user needs to sign in.
				}
			} );
		} else {
			this.shownFrame = 3; // user needs to sign in.
		}
	}
}
