import {Component, Input} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceNavMenuItem} from '../../../interfaces/interfaces';
//
@Component( {
	selector: 'nav-horizontal',
	templateUrl: './nav-horizontal.html',
	styleUrls: [
		'./nav-horizontal.less'
	]
} )
export class ComponentNavHorizontal {
	@Input()
	public navItems: InterfaceNavMenuItem[] = [];
	//
	public constructor() {
		//
	}
}
