import {Component, EventEmitter, Input, Output} from '@angular/core';
//
@Component( {
	selector: 'ui-check-box',
	templateUrl: './check-box.html',
	styleUrls: [
		'./check-box.less'
	]
} )
export class ComponentCheckBox {
	public isChecked: boolean = false;
	//
	@Input()
	public text: string = '';
	//
	@Input()
	public error: boolean = false;
	//
	@Input()
	public checked: boolean = false;
	//
	@Input()
	public cssSize: number | undefined = undefined;
	//
	@Output()
	public changed: EventEmitter<boolean> = new EventEmitter<boolean>();
	//
	public constructor() {
		//
	}

	public toggle(): void {
		this.error = false;
		this.isChecked = !this.isChecked;
		this.changed.emit( this.isChecked );
	}
}
