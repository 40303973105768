// ===== Interfaces ===== //
import {InterfaceOWDoclet} from './doclets';
//
export interface InterfaceOWTemplateAccount {
	billing_street?: string;
	billing_city?: string;
	billing_state?: string;
	billing_zip?: string;
	billing_country?: string;
	billing_phone?: string;
	shipping_street?: string;
	shipping_city?: string;
	shipping_state?: string;
	shipping_zip?: string;
	shipping_country?: string;
	shipping_phone?: string;
	status: 'active' | string;
	cashless_spending?: boolean;
}

export interface InterfaceOWAccountBillingData {
	street: string;
	city: string;
	state: string;
	zip: string;
	country: string;
	phone: string;
}

export interface InterfaceOWAccountShippingData {
	street: string;
	city: string;
	state: string;
	zip: string;
	country: string;
	phone: string;
}

export interface InterfaceOWDocletAccount extends InterfaceOWDoclet<InterfaceOWTemplateAccount> {}
