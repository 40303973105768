import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../../../../../ow-framework/collections/profiles';
// ===== Interfaces ===== //
import {
	InterfaceAppContext,
	InterfaceAppEvent,
	InterfaceHTTPGateway,
	InterfaceNavMenuItem,
	InterfaceOWAccountBillingData,
	InterfaceOWAccountShippingData,
	InterfaceOWAPICardVault,
	InterfaceOWAPICardVaultResponse,
	InterfaceOWAPIGetDocletResponse,
	InterfaceOWAPIGetDocletsResponse,
	InterfaceOWDoclet,
	InterfaceOWDocletAccount,
	InterfaceOWTemplateAccount,
	InterfaceOWUser
} from '../../../../../../ow-framework/interfaces/interfaces';
interface InterfaceAccountBillingErrors {
	street: boolean;
	city: boolean;
	state: boolean;
	zip: boolean;
}
interface InterfaceAccountShippingErrors {
	street: boolean;
	city: boolean;
	state: boolean;
	zip: boolean;
}
// ===== Services ===== //
import {ServiceAppEvents} from '../../../../../../ow-framework/services/app-events';
import {ServiceAuthentication} from '../../../../../../ow-framework/services/authentication';
import {ServiceNavigate} from '../../../../../../ow-framework/services/navigate';
import {ServiceOWAPI} from '../../../../../../ow-framework/services/ow-api';
import {ServiceRegex} from '../../../../../../ow-framework/services/regex';
//
@Component( {
	selector: 'page-orders',
	templateUrl: './orders.html',
	styleUrls: [
		'./orders.less'
	]
} )
export class PageOrders implements OnDestroy, OnInit {
	private readonly portalRealmID: string = this.appConfig.getRealmID( 'Portal' );
	private readonly appContext: InterfaceAppContext = this.appConfig.getContext();
	public purchaserDocletID: string | undefined = undefined;
	public readonly routes: typeof AppRouterLinks = AppRouterLinks;
	public mastHeading: string = 'Hello';
	public serialCode: string = '';
	public readonly navMenuItems: InterfaceNavMenuItem[] = [
		{
			route: '/' + this.routes.dashboard,
			text: 'Dashboard'
		},
		{
			route: '/' + this.routes.family,
			text: 'Manage Family',
			shortText: 'Family'
		}, /*
		{
			route: '/' + this.routes.editGroup,
			text: 'Manage Group',
			shortText: 'Group',
			locked: false
		}, */
		{
			route: '/' + this.routes.orders,
			text: 'Orders & Billing',
			shortText: 'Billing'
		},
		{
			route: '/' + this.routes.myAccount,
			text: 'My Account',
			shortText: 'Account'
		}
	];
	//
	public formBusy: boolean = false;
	//
	private readonly strAccountTemplateID: string = this.appConfig.getTemplateID( 'Account' );
	//
	private subUserReSync: Subscription | null = null;
	public cashlessSpending: boolean = false;
	//
	private subCardReSync: Subscription | null = null;
	public haveAccountInfo: boolean = false;
	public cardsOnFile: InterfaceOWAPICardVault[] = [];
	// ===== Billing / Shipping ===== //
	private subAccountReSync: Subscription | null = null;
	private accountDoclet: InterfaceOWDoclet | null = null;
	public accountBillingData: InterfaceOWAccountBillingData = {
		street: '',
		city: '',
		state: '',
		zip: '',
		country: '',
		phone: ''
	};
	public accountBillingErrors: InterfaceAccountBillingErrors = {
		street: false,
		city: false,
		state: false,
		zip: false
	};
	public sameAsBilling: boolean = false;
	public strSameAsBilling: string = 'Same As Billing';
	public accountShippingData: InterfaceOWAccountShippingData = {
		street: '',
		city: '',
		state: '',
		zip: '',
		country: '',
		phone: ''
	};
	public accountShippingErrors: InterfaceAccountShippingErrors = {
		street: false,
		city: false,
		state: false,
		zip: false
	};
	//
	public recycleTB: boolean = true; // trying to fix an angular framework issue...
	//
	public constructor(
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly nav: ServiceNavigate,
		private readonly owapi: ServiceOWAPI,
		private readonly title: Title
	) {
		this.title.setTitle( 'Portal' );
		// need to first fetch the CC info, so that it creates an account doclet, so that we can find it...etc.
		// TODO: gather the users billing and shipping info from their account...
		this.subAccountReSync = ServiceAppEvents.listen( 'account:re-sync' ).subscribe( (_:InterfaceAppEvent): void => {
			this.fetchAccountInfo(); // it seems nothing fires up "account:re-sync"
		} );
		this.subUserReSync = ServiceAppEvents.listen( 'user:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
			this.fetchUserInfo();
		} );
		this.subCardReSync = ServiceAppEvents.listen( 'card:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
			this.fetchCardInfo();
		} );
	}

	private fetchUserInfo(): void {
		this.colProfiles.getMyUserProfile( (userData: InterfaceOWUser | null): void => {
			if ( userData && userData.profile ) {
				this.mastHeading = 'Hello, ' + (userData.profile?.first_name ?? '') + ' ' + (userData.profile?.last_name ?? '');
			}
		} );
	}

	private fetchCardInfo(): void {
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			return;
		}
		this.owapi.workspace.actions.core.getSavedPaymentMethod( this.appContext, profileID, this.portalRealmID ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response && response.success ) {
				this.cardsOnFile = [];
				const apiResponse: InterfaceOWAPICardVaultResponse = response.data;
				if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) && apiResponse.data.items.length > 0 ) {
					this.cardsOnFile = apiResponse.data.items.filter( (item: InterfaceOWAPICardVault): boolean => {
						return 'vault_id' in item;
					} );
					console.log( 'cards on file', this.cardsOnFile );
					if ( this.accountDoclet ) {
						if ( this.cardsOnFile.length > 0 && this.accountDoclet.data['cashless_spending'] ) {
							console.log( 'fetch card info - cards on file > 0 && enabled cashless. setting toggle to true' );
							this.setCashlessToggleBox( true );
						}
						if ( !this.accountDoclet.data['cashless_spending'] || this.cardsOnFile.length < 1 ) {
							console.log( 'fetch card info - account cashless disabled, or cards on file < 1. setting toggle to false', this.accountDoclet.data['cashless_spending'], this.cardsOnFile.length );
							this.setCashlessToggleBox( false );
						}
					} else { // cheating.. it may not have existed until we tried to fetch the card info
						console.log( 'cache miss, re-fetching account...' );
						this.fetchAccountInfo();
					}
				}
			}
		} );
	}

	private fetchAccountInfo(): void {
		this.colProfiles.getMyUserProfile( (userProfile: InterfaceOWUser | null): void => {
			if ( userProfile && userProfile.account_id ) {
				const ac: InterfaceAppContext = this.appConfig.getContext();
				this.colProfiles.getAccountWorkspaceDoclet( ac, userProfile._id.$oid, this.strAccountTemplateID, (accountDoclet: InterfaceOWDocletAccount | null, _: InterfaceOWUser | null): void => {
					this.accountDoclet = accountDoclet;
					if ( this.accountDoclet && this.accountDoclet.data ) {
						if ( this.cardsOnFile.length > 0 && this.accountDoclet.data['cashless_spending'] ) {
							this.setCashlessToggleBox( true );
						}
						if ( this.cardsOnFile.length < 1 || !this.accountDoclet.data['cashless_spending'] ) {
							this.setCashlessToggleBox( false );
						}
						const accData: InterfaceOWTemplateAccount = this.accountDoclet.data as InterfaceOWTemplateAccount;
						this.accountBillingData.street = accData.billing_street ?? '';
						this.accountBillingData.city = accData.billing_city ?? '';
						this.accountBillingData.state = accData.billing_state ?? '';
						this.accountBillingData.zip = accData.billing_zip ?? '';
						this.accountBillingData.country = accData.billing_country ?? '';
						this.accountBillingData.phone = accData.billing_phone ?? '';
						this.accountShippingData.street = accData.shipping_street ?? '';
						this.accountShippingData.city = accData.shipping_city ?? '';
						this.accountShippingData.state = accData.shipping_state ?? '';
						this.accountShippingData.zip = accData.shipping_zip ?? '';
						this.accountShippingData.country = accData.shipping_country ?? '';
						this.accountShippingData.phone = accData.shipping_phone ?? '';
					}
					console.log( 'account doc', this.accountDoclet );
				} );
			} else {
				console.log( 'No user account ID, cannot fetch account data.' );
				this.haveAccountInfo = true;
			}
		} );
	}

	public ngOnInit(): void {
		if ( this.auth.isSignedIn() ) {
			this.fetchAccountInfo();
			this.fetchUserInfo();
			this.fetchCardInfo();
		} else {
			this.nav.toURL( '/' + this.routes.signIn );
		}
	}

	public ngOnDestroy(): void {
		if ( this.subAccountReSync ) {
			this.subAccountReSync.unsubscribe();
			this.subAccountReSync = null;
		}
		if ( this.subCardReSync ) {
			this.subCardReSync.unsubscribe();
			this.subCardReSync = null;
		}
		if ( this.subUserReSync ) {
			this.subUserReSync.unsubscribe();
			this.subUserReSync = null;
		}
	}

	// ===== Card On File ===== //

	public showCCModal(): void {
		ServiceAppEvents.broadcast( 'modal:open:credit-card' );
	}

	public ccTrackBy( arrIdx: number, ccOnFile: InterfaceOWAPICardVault ): string {
		return ccOnFile.vault_id + '';
	}

	public ermahgerdItsFullOfStars( type: string, cc4: string ): string {
		const last4: string = cc4.slice( -4 );
		switch ( type.toLowerCase().replace( / /g, '' ) ) {
			case 'amex':
			case 'americanexpress': {
				// 4 6 5
				return '**** ****** *' + last4;
			}
		}
		return '**** **** **** ' + last4;
	}

	public cashlessToggle( b: boolean ): void {
		console.log( 'Trying to set cashless to ', b );
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			return;
		}
		if ( this.accountDoclet && this.cardsOnFile.length > 0 ) {
			this.owapi.workspace.actions.core.toggleCashlessSpending( this.appContext, profileID, b, this.portalRealmID ).subscribe( (response: InterfaceHTTPGateway): void => {
				let failed: boolean = true;
				if ( response && response.success ) {
					const apiResponse: InterfaceOWAPIGetDocletsResponse = response.data;
					if ( apiResponse && apiResponse.data && apiResponse.data.items ) {
						failed = false;
						const accountDoclets: InterfaceOWDoclet[] = apiResponse.data.items;
						if ( accountDoclets && Array.isArray( accountDoclets ) && accountDoclets.length > 0 ) {
							const accDoc: InterfaceOWDoclet = accountDoclets[0];
							if ( (accDoc.data as InterfaceOWTemplateAccount).cashless_spending ) {
								this.setCashlessToggleBox( true );
							} else {
								this.setCashlessToggleBox( false );
							}
						} else {
							this.setCashlessToggleBox( false );
						}
					}
				}
				if ( failed ) {
					console.log( 'request failed, setting cashless to false' );
					this.setCashlessToggleBox( false );
				}
			} );
		} else {
			console.log( 'cards on file is zero, forcing it to be false, showing CC modal' );
			this.setCashlessToggleBox( false );
			this.showCCModal();
		}
	}

	// ===== Billing / Shipping ===== //

	public validateAccountBilling( field: keyof InterfaceOWAccountBillingData ): void {
		this.accountBillingData[field] = this.accountBillingData[field]?.replace( ServiceRegex.trimRegExp, '' ) ?? '';
		if ( field in this.accountBillingErrors ) {
			this.accountBillingErrors[field as keyof InterfaceAccountBillingErrors] = this.accountBillingData[field].length < 1;
		}
		if ( this.sameAsBilling && field in this.accountShippingData ) {
			this.accountShippingData[field] = this.accountBillingData[field];
			if ( field in this.accountShippingErrors ) {
				this.accountShippingErrors[field as keyof InterfaceAccountShippingErrors] = this.accountBillingErrors[field as keyof InterfaceAccountShippingErrors];
			}
		}
	}

	public setSameAsBilling( b: boolean ): void {
		this.sameAsBilling = b;
		if ( b ) {
			const keys: (keyof InterfaceOWAccountBillingData)[] = Object.keys( this.accountBillingData ) as (keyof InterfaceOWAccountBillingData)[];
			for ( let x: number = 0; x < keys.length; ++x ) {
				if ( keys[x] in this.accountShippingData ) {
					this.accountShippingData[ keys[x] ] = this.accountBillingData[ keys[x] ];
				}
				if ( keys[x] in this.accountShippingErrors ) {
					this.accountShippingErrors[ keys[x] as keyof InterfaceAccountShippingErrors ] = this.accountBillingErrors[ keys[x] as keyof InterfaceAccountShippingErrors ];
				}
			}
		}
	}

	public validateAccountShipping( field: keyof InterfaceOWAccountShippingData ): void {
		this.accountShippingData[field] = this.accountShippingData[field]?.replace( ServiceRegex.trimRegExp, '' ) ?? '';
		if ( field in this.accountShippingErrors ) {
			this.accountShippingErrors[field as keyof InterfaceAccountShippingErrors] = this.accountShippingData[field].length < 1;
		}
	}

	public saveBillingShipping(): void {
		if ( !this.accountDoclet ) {
			return;
		}
		const bKeys: (keyof InterfaceAccountBillingErrors)[] = Object.keys( this.accountBillingErrors ) as (keyof InterfaceAccountBillingErrors)[];
		let haveErrors: boolean = false;
		for ( let x: number = 0; x < bKeys.length; ++x ) {
			this.accountBillingErrors[ bKeys[x] ] = this.accountBillingData[ bKeys[x] ].length < 1;
			haveErrors = haveErrors || this.accountBillingErrors[ bKeys[x] ];
		}
		const sKeys: (keyof InterfaceAccountShippingErrors)[] = Object.keys( this.accountShippingErrors ) as (keyof InterfaceAccountShippingErrors)[];
		for ( let x: number = 0; x < sKeys.length; ++x ) {
			this.accountShippingErrors[ sKeys[x] ] = this.accountShippingData[ sKeys[x] ].length < 1;
			haveErrors = haveErrors || this.accountShippingErrors[ sKeys[x] ];
		}
		if ( haveErrors ) {
			return;
		}
		this.formBusy = true;
		const ac: InterfaceAppContext = this.appConfig.getContext();
		this.owapi.workspace.doclets.updateDocletData( ac, this.accountDoclet._id.$oid, {
			billing_street: this.accountBillingData.street,
			billing_city: this.accountBillingData.city,
			billing_state: this.accountBillingData.state,
			billing_zip: this.accountBillingData.zip,
			billing_country: this.accountBillingData.country,
			billing_phone: this.accountBillingData.phone,
			shipping_street: this.accountShippingData.street,
			shipping_city: this.accountShippingData.city,
			shipping_state: this.accountShippingData.state,
			shipping_zip: this.accountShippingData.zip,
			shipping_country: this.accountShippingData.country,
			shipping_phone: this.accountShippingData.phone
		} as InterfaceOWTemplateAccount ).subscribe( (response: InterfaceHTTPGateway): void => {
			let failed: boolean = true;
			if ( response && response.success ) {
				const apiResponse: InterfaceOWAPIGetDocletResponse = response.data;
				if ( apiResponse && apiResponse.data && apiResponse.data._id ) {
					failed = false;
				}
			}
			if ( failed ) {
				console.log( 'failed to update doclet' );
			} else {
				console.log( response.data.data ?? 'no doclet' );
			}
			this.formBusy = false;
		} );
	}

	private setCashlessToggleBox( b: boolean ): void {
		// angular won't update the child component because the @Input() doesn't see a change.
		// it looks at references rather than values, and the variable
		this.cashlessSpending = b;
		// setTimeout( () => { console.log( 'set parent to', this.purchaserData.cashlessSpending ); }, 1400 );
		this.recycleTB = false;
		setTimeout( (): void => {
			this.recycleTB = true;
		}, 10 );
	}
}
