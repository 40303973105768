import {Injectable} from '@angular/core';
//
@Injectable( {
	providedIn : 'root',
} )
export class ServiceAuthentication {
	private authToken: string | null = null; // JWT
	private profileID: string | null = null;
	//
	public constructor() {
		this.deserialize();
	}

	public clear(): void {
		this.authToken = null;
		this.profileID = null;
		this.serialize();
	}

	public isSignedIn(): boolean {
		return typeof this.authToken === 'string' && this.authToken.length > 0 && typeof this.profileID === 'string' && this.profileID.length > 0;
	}

	public getProfileID(): string | null {
		return this.profileID;
	}

	public getAuthToken(): string | null {
		return this.authToken;
	}

	public setTokens( JWT: string, profileID: string ): void {
		this.authToken = JWT;
		this.profileID = profileID;
		this.serialize();
	}

	public serialize(): void {
		if ( typeof this.authToken === 'string' && this.authToken.length > 0 ) {
			localStorage.setItem( 'auth_key', this.authToken );
		} else {
			localStorage.removeItem( 'auth_key' );
		}
		if ( typeof this.profileID === 'string' && this.profileID.length > 0 ) {
			localStorage.setItem( 'profileID', this.profileID );
		} else {
			localStorage.removeItem( 'profileID' );
		}
	}

	public deserialize(): void {
		let tmpAuthKey: string | null = localStorage.getItem( 'auth_key' );
		if ( tmpAuthKey !== null && tmpAuthKey.length > 0 ) {
			this.authToken = tmpAuthKey;
		} else {
			this.authToken = null;
		}
		let tmpProfileID: string | null = localStorage.getItem( 'profileID' );
		if ( tmpProfileID !== null && tmpProfileID.length > 0 ) {
			this.profileID = tmpProfileID;
		} else {
			this.profileID = null;
		}
	}
}
