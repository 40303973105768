import {Injectable} from '@angular/core';
// ===== Configs ===== //
import {ConfigOWEnvironmentIDs} from '../../../ow-framework/environment-ids/ow-staging-ids';
// ===== Interfaces ===== //
import {InterfaceAppContext} from '../../../ow-framework/interfaces/interfaces';
//
@Injectable( {
	providedIn: 'root'
} )
export class BaseConfig extends ConfigOWEnvironmentIDs { // --- DEV --- // ...the baseAPI and ioHost should be different than live.
	//
	public constructor() {
		super();
		console.log( '========================= Staging MODE =========================' );
	}
	// ===== //
	protected readonly appKey: string = '66146ec4c2913c3555867501'; // RYST
	protected workspaceID: string = '67b6637284bb9f7ab7d5ff72'; // Framework
	protected readonly posStaffRealmID: string = '66907621e8e57780feaa7709'; // POS Staff
	protected readonly appShortName: string = 'RYST'; // used for support tickets. needs the short-code (acronym) of the project name.
	protected readonly reCaptchaSiteKey: string = '6LferCoqAAAAAIG62qrrYHNdplVT1JdZUKlBU-Gn'; // both dev and live
	// ===== Roles ===== //
	protected readonly roleIDs: { // roles are app-specific, just like templates.
		[key: string]: string;
	} = {
		'Admin': '67c797ac6d014f0c38cb7ae3',
		'Staff': '67c797cd6d014f0c38cb7ae4',
		'POS': '67c797e76d014f0c38cb7ae5',
		'Web': '67c797fc6d014f0c38cb7ae6',
		'OmniPlatform': '67c7983f6d014f0c38cb7ae7'
	};
	// ===== Settings ===== //
	protected readonly settingsIDs: {
		[key: string]: string;
	} = { // 'nickname' : (doclet).setting_id // ObjectId // don't use the ._id key's value in the API, it doesn't return anything.
		// 'Holiday Schedule': '66048559be3d8159082f931c' // <= 'setting_id' vs the '_id' => '66048569be3d8159082f931e'
	};
	// ===== Realm IDs ===== //
	protected readonly realmIDs: {
		[key: string]: string;
	} = {
		'Portal': this.RYSTRealmID,
		'POS': this.posStaffRealmID
	};
	// ===== //
	public getContext(): InterfaceAppContext { // most of the API service files needs these values, but they only exist at run-time, per app.
		return {
			appKey: this.appKey,
			appRealmID: this.RYSTRealmID,
			appWorkspaceID: this.RYSTWorkspaceID,
			realmID: this.RYSTRealmID,
			workspaceID: this.workspaceID
		};
	}

	public setWorkspaceID( workspaceID: string ): void {
		this.workspaceID = workspaceID;
	}
}
