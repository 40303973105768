// ===== Interfaces ===== //
import {InterfaceObjectDate} from '../ow/mongo/object-date';
import {InterfaceOWDoclet} from '../ow/doclets';
import {InterfaceOWDocletConsumer} from './consumer';
//
export interface InterfaceOWTemplateOrder {
	order_date: InterfaceObjectDate; // ISODate()
	status: string;
	currency: string; // USD, ...
	sub_total: string;
	tax: string;
	total: string;
}

export interface InterfaceCustomerOrderHistoryItemTypes {
	bundles: InterfaceOWDoclet[]; // TODO: verify this is a real thing
	cabana: InterfaceOWDoclet[];
	certs: InterfaceOWDoclet[];
	daily_tickets: InterfaceOWDoclet[]; // normal daily tickets.
	parking: InterfaceOWDoclet[];
	sp: InterfaceOWDoclet[]; // Event Passes
	spp: InterfaceOWDoclet[]; // Event Parking Passes
}

export interface InterfaceCustomerOrderHistory extends InterfaceCustomerOrderHistoryItemTypes {
	consumer: InterfaceOWDocletConsumer;
	order: InterfaceOWDoclet;
	order_source: 'Web' | 'POS' | string;
	order_total?: string; // the grand total of the order.
	__isGuestOrder?: boolean
}

export interface InterfaceOrderHistoryTicketExchange { // obsolete? // TODO: update this
	historyItemType: 'daily_tickets'
	historySelectionIdx: number; // this represents the equivalent passes-for-sale doclet position.
	// so if general is [0] and jr is [1] for passes to buy, and if we know which history item belonged to which ticket (which array index),
	// ...then we know that adult => adult happens when type is the same and array index is the same.
	newSelectionType: 'daily_tickets';
	newSelectionIdx: number; // the position in the passes array (daily, parking, whatever) for the new selected ticket.
}

export interface InterfaceSeasonPassUpgrade {
	upgradeFromLevel: number;
	upgradeToLevel: number;
	upgradeToPrice: number;
	upgradeToName: string;
}

export interface InterfaceOrderToEditNewCartItem {
	source: 'pos' | 'web' | string;
	passID: string; // doclet ID of the new pass to sell.
	ticketHoldID: string | null; // server-side's ticket instance that's being reserved.
	price: number; // amount to charge
	date: string; // YYYY-MM-DD // all tickets use this one way or another.
	time?: string; // HH:MM:SS // evening admissions use this.
	year?: string; // 2023 // season passes use this.
	location_id?: string; // cabana only.
	assigned_first_name?: string; // these x3 fields are on season passes
	assigned_last_name?: string; // these x3 fields are on season passes
	assigned_dob?: string; // these x3 fields are on season passes
	_strVisitDate: string; // MM/DD/YY // display value used on the ticket exchange and edit-order, i think.
}

export interface InterfaceOrderToEdit {
	historyItem: InterfaceCustomerOrderHistory; // don't modify the data on historyItem, we need it to "revert" changes back to.
	oldCartModifications: {
		[ticketID: string]: {
			oldPrice: number; // warning --- sometimes this is a string
			newPrice?: number;
			isRefund: boolean; // if true, this won't be used for an exchange.
			isExchange: boolean; // if true, this won't be used for a refund.
			isUpgrade: boolean;
			newTicketHoldID?: string; // the held ticket ID. need to track and release these as they're created.
		};
	};
	ticketExchanges: {
		// any old ticket, can be exchanged, into any new ticket.
		// Junior => Cabana. which makes no sense, but whatever.
		[oldTicketID: string]: InterfaceOrderToEditNewCartItem;
	};
	totalRefundable: number;
	totalCollectable: number;
	refundReason: string;
	// total chargeable = totalCollectable - totalRefundable
}
