// ===== Interfaces ===== //
import {InterfaceOWDocletConsumer} from './consumer';
import {InterfaceOWDoclet} from '../ow/doclets';
import {InterfaceObjectId} from '../ow/mongo/object-id';
//
export interface InterfaceOWTemplateEntitlementType {
	name: string;
	primary: boolean;
}

export interface InterfaceOWTemplateConsumerEntitlement {
	date: string | null; // YYYY-MM-DD | 'any' | 'event' // TODO: discover why some dates are NULL. they should never be NULL. i suspect they're meant to be "event".
	event_id: InterfaceObjectId; // the ._id of the Venue Event this was purchased for.
	parent_id: InterfaceObjectId; // the ._id of the Event Pass bought to obtain the entitlement.
	purchase_price: number;
	status: 'active' | 'canceled' | 'pending' | string;
	type_name: string; // the name of the Event Pass purchased.
	type_id: string; // the ._id of the Entitlement Type
	// ===== //
	// TODO: verify these remaining KVP:
	dates_valid?: string[]; // extra restrictions in-case it's an 'any' or multi-use 'event' date.
	location_id?: string; // (cabana) unique space ID
	remaining_uses?: number; // default ought to be 1 or 0, and is needed for the "event" date (multi-use setup)
}

export interface InterfaceOWTemplateEntitlementActivation {
	// TODO: this.
}

export interface InterfaceEventEntitlementLock {
	eventID: string;
	passID: string;
	spaceID: string | null;
	date: { year: number; month1: number; day: number; } | 'any' | 'event' | string; // the date needs to be either individual year,month,day numbers, or the YYYY-MM-DD string, or the special-case `event` or `any` day dates.
	roleID: string; // appConfig.getRole( 'Web' )
	callback: (entitlementID: string | false) => void; // false when it's out of stock or something fails. otherwise you get the item_id of the entitlement as a string.
}

export interface InterfaceEventEntitlementUnlock {
	id: string | string[];
}

export interface InterfaceAdmissionQRCode { // 628e5c7935d8cb22efe21145
	date: string; // YYYY-MM-DD format. possibly other formats.
	event_id: InterfaceObjectId; // the ._id of the event this was purchased for.
	name: string; // comes from the (primary) Event Pass purchased.
	order_id: InterfaceObjectId;
	parent_id: InterfaceObjectId; // the ._id of the Event Pass purchased.
	status: 'active' | 'canceled' | string;
}

export interface InterfaceMemberEntitlements {
	consumers: {
		admissions: InterfaceOWDoclet<InterfaceAdmissionQRCode>[];
		consumer: InterfaceOWDocletConsumer;
	}[];
	entitlements: InterfaceOWDoclet<InterfaceOWTemplateConsumerEntitlement>[];
	group_id: string;
	purchaser: InterfaceOWDocletConsumer;
}

export interface InterfaceMemberEntitlementsConsumerDataByID { // layer 1
	admissions: InterfaceOWDoclet<InterfaceAdmissionQRCode>[];
	consumer: InterfaceOWDocletConsumer;
}

export interface InterfaceMemberEntitlementsByMemberAdmission { // layer 2
	consumerIDs: string[];
	consumerData: {
		[consumerID: string]: InterfaceMemberEntitlementsConsumerDataByID;
	};
	sharedEntitlements: InterfaceOWDoclet<InterfaceOWTemplateConsumerEntitlement>[];
}

export interface InterfaceMemberEntitlementsByDateMember { // layer 3
	visitDates: string[];
	visitData: {
		[visitDate: 'any' | 'event' | string]: InterfaceMemberEntitlementsByMemberAdmission;
	};
}

export interface InterfaceMemberEntitlementsByEventDateMember { // layer 4
	eventIDs: string[];
	eventData: {
		[eventID: string]: InterfaceMemberEntitlementsByDateMember;
	};
	// eventData[eventID].visitData[visitDate].consumerData[consumerID].admissions[x]
}
