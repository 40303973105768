export interface InterfaceOWTemplateGroup {
	name: string;
	max_members: number;
	valid_dates: string; // YYYY-MM-DD,YYYY-MM-DD,YYYY-MM-DD,...
}

/*
{ // template
	"_id" : ObjectId("63bf0eb4f918220ad3172b4e"),
	"name" : "Group",
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"fields" : [
		{
			"label" : "Name",
			"key" : "name",
			"sort" : 0,
			"type" : "string"
		},
		{
			"label" : "Max Members",
			"key" : "max_members",
			"sort" : 1,
			"type" : "number"
		},
		{
			"label" : "Valid Dates",
			"key" : "valid_dates",
			"sort" : 2,
			"type" : "string"
		}
	],
	"realms" : [
		ObjectId("625f38c561b6eb3efaebceb3")
	],
	"tags" : "Group"
}


{ // doclet
	"_id" : ObjectId("63c6f442907b90600e84df7f"),
	"title" : "Test Group",
	"template" : "",
	"template_id" : ObjectId("63bf0eb4f918220ad3172b4e"),
	"description" : null,
	"user_id_created" : null,
	"workspace_id" : ObjectId("6222b56fb41f06d49ea56fc7"),
	"data" : {
	"name" : "Test Group",
		"max_members" : 7,
		"valid_dates" : "2023-01-17,2023-01-18,2023-01-19,2023-01-20,2023-01-21,2023-01-22,2023-01-23"
	},
	"context" : null,
	"stats" : null,
	"status" : null,
	"tags" : [ ],
	"tc" : "01/17/2023 13:17:22",
	"tc_raw" : ISODate("2023-01-17T19:17:22.905Z"),
	"tc_utc" : "01/17/2023 19:17:22",
	"tc_utc_raw" : ISODate("2023-01-18T01:17:22Z")
}
*/
