// ===== Interfaces ===== //
import {InterfaceObjectId} from './mongo/object-id';
import {InterfaceOWTemplateField} from './templates';
//
export interface InterfaceOWAction {
	_id: InterfaceObjectId;
	app_id?: InterfaceObjectId;
	fields: InterfaceOWTemplateField[];
	group?: string;
	public?: boolean;
	resource: string;
	title: string;
	type: string;
	workspace_id?: InterfaceObjectId;
}
