import {Injectable} from '@angular/core';
// ===== Interfaces ===== //
import {
	InterfaceDocletIDToTicketProps,
	InterfaceOWDoclet,
	InterfaceOWTemplateEventProduct
} from '../interfaces/interfaces';
interface InterfaceRoles {
	admin: string;
	pos: string;
	staff: string;
	web: string;
}
// ===== Transformers ===== //
import {TransformerEventProductBase} from './event-product-base';
//
@Injectable( {
	providedIn: 'root'
} )
export class TransformerEventMerchandise {
	//
	public constructor() {
		//
	}

	public static processMerchProps( merchPass: InterfaceOWDoclet<InterfaceOWTemplateEventProduct>, roleIDs: InterfaceRoles ): InterfaceDocletIDToTicketProps<InterfaceOWTemplateEventProduct> {
		// TODO: swap ref's from the old fn names to the new ones??
		return TransformerEventProductBase.processEventProductProps( merchPass, roleIDs );
	}

	public static getEventMerchPrice( merchForSale: InterfaceOWDoclet<InterfaceOWTemplateEventProduct>, purchaseDateYYYYMM1DD: string, targetDateYYYYMM1DD: string, soldCount: number = 0 ): number {
		return TransformerEventProductBase.getEventProductPrice( merchForSale, purchaseDateYYYYMM1DD, targetDateYYYYMM1DD, soldCount );
	}
}
