<span class="radio-group">
	<span class="radio-item"
		*ngFor="let value of this.radioGroupValues; index as x;"
	>
		<span class="radio"
			[ngClass]="{
				'radio': true,
				'checked': x === this.selectedIndex
			}"
			(click)="setSelected( x );"
		></span>
		<span class="radio-entry-display">{{ value }}</span>
	</span>
</span>
