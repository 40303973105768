export interface InterfaceOWTemplateFamily {
	name: string;
}

/*
{
	"_id" : ObjectId("627146b1e32a107779d0d88b"),
	"name" : "Family",
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"fields" : [
		{
			"label" : "Name",
			"key" : "name",
			"sort" : 0,
			"type" : "string"
		}
	],
	"realms" : [
		ObjectId("625f38c561b6eb3efaebceb3")
	]
}
*/
