import {Pipe, PipeTransform} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceOWDoclet} from '../interfaces/interfaces';
//
@Pipe( {
	name: 'passFilterSPH',
	pure: true
} )
export class PipePassFilterSPH implements PipeTransform {
	public transform( doclets: InterfaceOWDoclet[], isSPH: boolean ): InterfaceOWDoclet[] {
		if ( isSPH ) {
			return doclets; // don't filter away anything.
		} // else take away all passes requiring a season pass.
		return doclets.filter( (doclet: InterfaceOWDoclet): boolean => {
			return !doclet?.data?.['requires_season_pass'];
		} );
	}
}
