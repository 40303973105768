import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {InterfaceAppContext, InterfaceHTTPGateway} from '../../../../interfaces/interfaces';
interface InterfaceWorkspaceUserInvite {
	email: string;
	name: string;
}
// ===== Services ===== //
import {ServiceOWGateway} from '../ow-gateway';
import {ServiceRegex} from '../../../regex';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPICoreWorkspace {
	private readonly routePrefix: string = 'workspace/';
	//
	public constructor(
		private readonly gateway: ServiceOWGateway
	) {
		//
	}

	public inviteUserToWorkspace( appContext: InterfaceAppContext, data: InterfaceWorkspaceUserInvite, differentRealmID?: string ): Observable<InterfaceHTTPGateway> {
		// name is just purely for what to put in the email as a greeting, if any.
		// outbound data is a simple array, not the usual { 'data': <things> }
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/members', JSON.stringify( [ {
			name: data.name,
			email: data.email.toLowerCase(),
			realm_id: differentRealmID ? differentRealmID : appContext.realmID
		} ] ) );
	}

	public inviteUsersToWorkspace( appContext: InterfaceAppContext, data: InterfaceWorkspaceUserInvite[], differentRealmID?: string ): Observable<InterfaceHTTPGateway> {
		// outbound data is a simple array. not the usual { 'data': <things> }
		const payload: (InterfaceWorkspaceUserInvite & { realm_id?: string; })[] = [];
		for ( let x: number = 0; x < data.length; ++x ) {
			if ( data[x] && data[x].email.length > 0 && data[x].name.replace( ServiceRegex.trimRegExp, '' ).length > 0 ) {
				payload.push( {
					email: data[x].email.toLowerCase(),
					name: data[x].name.replace( ServiceRegex.trimRegExp, '' ),
					realm_id: differentRealmID ? differentRealmID : appContext.realmID
				} );
			}
		}
		if ( payload.length > 0 ) {
			return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/members', JSON.stringify( payload ) );
		} else {
			return this.gateway.requestDenied();
		}
	}

	public resendInviteToWorkspace( somethingUser: any, workspaceID: string ): Observable<InterfaceHTTPGateway> {
		// STUB
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( '' ), JSON.stringify( {
			// JSON key: value
		} ) );
	}

	public getUsersInWorkspace( workspaceID: string ): Observable<InterfaceHTTPGateway> {
		// STUB
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( '' ), JSON.stringify( {
			// JSON key: value
		} ) );
	}

	public removeUserFromWorkspace( somethingUser: any, workspaceID: string ): Observable<InterfaceHTTPGateway> {
		// STUB
		return this.gateway.fetch( 'delete', this.routePrefix + encodeURIComponent( '' ), JSON.stringify( {
			// JSON key: value
		} ) );
	}

	// edit user something workspace ( 'put', prefix, JSON.stringify( {} ) );
}
