<modal-back-drop id="modal-tiny-sign-in-wrapper">
	<span id="modal-tiny-sign-in" class="modal-body">
		<span class="content-wrapper">
			<span class="scroll-wrapper">
				<span class="title">Log In</span>
				<input type="text" name="email" placeholder="Email"
					[class.error]="badLogIn || badEmail"
					[(ngModel)]="email"
					[ngModelOptions]="{ updateOn : 'blur' }"
					(ngModelChange)="checkEmail();"
					(focus)="badLogIn = false; badEmail = false;"
					(blur)="checkEmail();"
					(keydown.enter)="blur( $event ); !busy && signIn();"
				/>
				<input type="password" name="password" placeholder="Password"
					[class.error]="badLogIn"
					[(ngModel)]="password"
					[ngModelOptions]="{ updateOn : 'blur' }"
					(focus)="badLogIn = false;"
					(keydown.enter)="blur( $event ); !busy && signIn();"
				/>
				<span class="actions">
					<span class="action button close" (click)="closeModal();">Close</span>
					<span class="action button submit" (click)="!busy && signIn();">Log In</span>
				</span>
			</span>
		</span>
	</span>
</modal-back-drop>
