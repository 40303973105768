import {InterfaceOWDoclet} from '../ow/doclets';

export interface InterfaceOWTemplateCashTransport {
	cash: string; // the total amount of money being moved.
	denominations: { // { '20': 16, '10': 28, '5': 8, '1': 220 }
		[denomination: string]: number; // dollar unit and quantity.
	};
	status: 'pending' | 'completed' | string;
	date_start?: string;
	date_complete: string; // InterfaceObjectDate ?
	tracking?: string; // whatever the user wants to put in, for identification // 'MM/DD/YY - LastName, FirstName' // deposit/serial ID //
}

export interface InterfaceOWDocletCashTransport extends InterfaceOWDoclet {
	data: InterfaceOWTemplateCashTransport;
}

/*
{
	"_id" : ObjectId("6439e1856eb7049cf875f814"),
	"name" : "Cash Transport",
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"fields" : [
		{
			"label" : "Cash",
			"key" : "cash",
			"sort" : 0,
			"type" : "string"
		},
		{
			"label" : "Denominations",
			"key" : "denominations",
			"sort" : 1,
			"type" : "object"
		},
		{
			"label" : "Status",
			"key" : "status",
			"sort" : 2,
			"type" : "string"
		},
		{
			"label" : "Date Start",
			"key" : "date_start",
			"sort" : 3,
			"type" : "string"
		},
		{
			"label" : "Date Complete",
			"key" : "date_complete",
			"sort" : 4,
			"type" : "string",
			"name" : "Date Complete"
		},
		{
			"label" : "Tracking",
			"key" : "tracking",
			"type" : "string",
			"sort" : 5
		}
	],
	"realms" : [
		ObjectId("625f38c561b6eb3efaebceb3")
	],
	"tags" : [
		"Cash"
	]
}
*/

export interface InterfaceOWTemplateBankVault { // bank vaults are for depositing-only. they cannot be used to fetch money out of it.
	cash: string; // total amount of money deposited into the bank vault.
}

/*
{
	"_id" : ObjectId("6439e0bc6eb7049cf875f813"),
	"name" : "Bank Vault",
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"fields" : [
		{
			"label" : "Cash",
			"key" : "cash",
			"sort" : 0,
			"type" : "string"
		}
	],
	"realms" : [
		ObjectId("625f38c561b6eb3efaebceb3")
	],
	"tags" : [
		"Vault",
		"Cash"
	]
}
*/

export interface InterfaceCashVault { // cash vaults are used keep money secure. they can be used to withdraw/deposit throughout the day.
	cash: string; // total amount of money in a cash vault.
	denominations: { // { '20': 16, '10': 28, '5': 8, '1': 220 }
		[denomination: string]: number; // dollar unit and quantity
	};
}

/*
{
	"_id" : ObjectId("6439dfeb6eb7049cf875f812"),
	"name" : "Cash Vault",
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"fields" : [
		{
			"label" : "Cash",
			"key" : "cash",
			"sort" : 0,
			"type" : "string"
		},
		{
			"label" : "Denominations",
			"key" : "denominations",
			"sort" : 1,
			"type" : "object"
		}
	],
	"realms" : [
		ObjectId("625f38c561b6eb3efaebceb3")
	],
	"tags" : [
		"Vault",
		"Cash"
	]
}
*/

export interface InterfaceOWTemplateCashDRawerTill {
	cash: string; // total amount of money in a staff members Till.
}

/*
{
	"_id" : ObjectId("6439d9496eb7049cf875f811"),
	"name" : "Cash Drawer Till",
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"fields" : [
		{
			"label" : "Cash",
			"key" : "cash",
			"sort" : 0,
			"type" : "string"
		}
	],
	"realms" : [
		ObjectId("625f38c561b6eb3efaebceb3")
	],
	"tags" : [
		"Till",
		"Cash"
	]
}
*/
