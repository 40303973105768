<header id="masthead">
	<div class="wrapper">
		<h2>{{ mastHeading }}</h2>
		<ul *ngIf="navMenuItems.length > 0 || serialCode">
			<li
				*ngFor="let item of navMenuItems"
				[ngClass]="{
					'tab': true,
					'locked' : item.locked,
					'active': router.url === item.route
				}"
				(click)="!item.locked && navTo( item.route ? [ item.route ] : [] );"
			><i class="icon fa-solid fa-lock"></i> <span class="full">{{ item.text ? item.text : '' }}</span><span class="short">{{ item.shortText ? item.shortText : item.text }}</span></li>
			<li *ngIf="serialCode && serialCode.length > 0" class="serial-code"><i class="fa-solid fa-qrcode"></i>&nbsp;{{ serialCode }}</li>
		</ul>
	</div>
</header>
