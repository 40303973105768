// ===== Interfaces ===== //
import {InterfaceAnyObject} from '../angular/vanilla-js';
import {InterfaceObjectId} from './mongo/object-id';
import {TypeOWTemplateFields} from './primitives';
//
export interface InterfaceOWFieldConfig {
	key: string;
	label: string;
	sort: number;
	type: TypeOWTemplateFields;
	default: any;
	readonly: boolean;
	enum?: string[];
	object_schema?: {
		[key: string]: TypeOWTemplateFields | InterfaceAnyObject;
	}; // the interface for when it's type: 'object' or type 'object_array'
}

export interface InterfaceOWTemplate {
	_id: InterfaceObjectId;
	name: string;
	fields: InterfaceOWFieldConfig[];
	tags: string[];
	group?: string;
	workspace: InterfaceObjectId[];
}

export interface InterfaceOWTemplateField {
	default?: any | undefined | null;
	enum?: string[];
	hideIf?: string;
	key: string;
	label: string;
	optional?: boolean;
	readonly?: boolean;
	schema?: {
		[key: string]: InterfaceOWTemplateField;
	};
	sort: number;
	type: string | TypeOWTemplateFields;
	template_id?: InterfaceObjectId;
	create_new?: boolean; // if NOT TRUE, it stops the "Create New" entry in drop-downs from appearing....
}
