import {Injectable} from '@angular/core';
// ===== Collections ===== //
import {Collection} from './collection';
// ===== Interfaces ===== //
import {
	InterfaceAppContext,
	InterfaceHTTPGateway,
	InterfaceOWAPIGetDocletsResponse,
	InterfaceOWAPIGetUserProfileResponse,
	InterfaceOWDoclet,
	InterfaceOWDocletAccount,
	InterfaceOWTemplateAccount,
	InterfaceOWUser
} from '../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../services/authentication';
import {ServiceOWAPI} from '../services/ow-api';
// ===== Types ===== //
import {typeAfterRequestForRecord, typeOptionallySuppressUpdate} from './types/flow-control';
//
@Injectable( {
	providedIn: 'root',
} )
export class CollectionProfiles extends Collection {
	//
	public constructor(
		private readonly auth: ServiceAuthentication,
		private readonly owapi: ServiceOWAPI
	) {
		super();
	}

	public cacheUserProfiles( data: InterfaceOWUser[] ): void {
		this.cacheRecords( data );
	}

	public fetchProfileByID( profileID: string, callback?: (userProfile: InterfaceOWUser | null) => any ): void {
		this.fetchRecordByID( profileID, (cbAfterRequestForRecord: typeAfterRequestForRecord): void => {
			this.owapi.account.profile.getUserProfile().subscribe( (response: InterfaceHTTPGateway<InterfaceOWAPIGetUserProfileResponse>): void => {
				cbAfterRequestForRecord( (cbAfterProcessingRecord: typeOptionallySuppressUpdate): void => {
					let didUpdate: boolean = false;
					if ( response && response.success && response.status === 200 ) {
						const apiResponse: InterfaceOWAPIGetUserProfileResponse = response.data;
						if ( apiResponse && apiResponse.data ) {
							this.cacheUserProfiles( [ apiResponse.data ] );
							didUpdate = true;
						}
					}
					cbAfterProcessingRecord( didUpdate );
				} );
			} );
		}, callback );
	}

	public getMyUserProfile( callback?: (userProfile: InterfaceOWUser | null) => void, fetchNew?: true ): void {
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			if ( typeof callback === 'function' ) {
				callback( null ); // user is not signed in.
			}
		} else {
			const myProfile: InterfaceOWUser | null = this.getCachedRecordByID( profileID );
			if ( !fetchNew && myProfile ) {
				if ( typeof callback === 'function' ) {
					callback( myProfile );
				}
			} else {
				this.fetchProfileByID( profileID, callback );
			}
		} // end else we have a profileID to work on.
	}

	public getUserWorkspaceDoclet( ac: InterfaceAppContext, userProfileID: string, strTemplateID: string, callback: (workspaceDoclet: InterfaceOWDoclet | null) => void ): void {
		this.owapi.workspace.doclets.getDocletsByTemplateID( ac, strTemplateID, {
			'realm.pid': '{id}' + userProfileID
		}, false, undefined, false ).subscribe( (response: InterfaceHTTPGateway<InterfaceOWAPIGetDocletsResponse>): void => {
			let workspaceDoclet: InterfaceOWDoclet | null = null;
			if ( response?.success ) {
				const apiResponse: InterfaceOWAPIGetDocletsResponse | undefined = response?.data;
				if ( apiResponse && Array.isArray( apiResponse?.data?.items ) && apiResponse.data.items.length > 0 ) {
					workspaceDoclet = apiResponse.data.items[0];
				}
			}
			callback( workspaceDoclet );
		} );
	}

	public getAccountWorkspaceDoclet( ac: InterfaceAppContext, userProfileID: string, strTemplateID: string, callback: (accountDoclet: InterfaceOWDocletAccount | null, userProfile: InterfaceOWUser | null) =>  void ): void {
		let userProfile: InterfaceOWUser | null = this.getCachedRecordByID( userProfileID );
		if ( !userProfile && this.auth.getProfileID() === userProfileID ) {
			// cache miss? maybe this was the first time around and we didn't yet fetch the user profile?
			this.getMyUserProfile( (_: InterfaceOWUser | null): void => {
				if ( this.getCachedRecordByID( userProfileID ) ) {
					// should be good, now that getCachedRecordByID worked as intended...
					this.getAccountWorkspaceDoclet( ac, userProfileID, strTemplateID, callback ); // try it again...
				} else { // fail, tried to acquire the user profile from the API, but obviously it didn't work...
					callback( null, null );
				}
			} );
		} else if ( userProfile && userProfile.account_id ) {
			this.owapi.workspace.doclets.getDocletsByTemplateID( ac, strTemplateID, {
				'realm.aid': '{id}' + userProfile.account_id.$oid
			}, false, undefined, false ).subscribe( (response: InterfaceHTTPGateway<InterfaceOWAPIGetDocletsResponse<InterfaceOWTemplateAccount>>): void => {
				let accountDoclet: InterfaceOWDoclet<InterfaceOWTemplateAccount> | null = null;
				if ( response?.success ) {
					const apiResponse: InterfaceOWAPIGetDocletsResponse<InterfaceOWTemplateAccount> | undefined = response?.data;
					if ( apiResponse && Array.isArray( apiResponse?.data?.items ) && apiResponse.data.items.length > 0 ) {
						accountDoclet = apiResponse.data.items[0];
					}
				}
				callback( accountDoclet, userProfile );
			} );
		} else {
			callback( null, null );
		}
	}
}
