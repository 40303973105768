import {Injectable} from '@angular/core';
//
@Injectable( {
	providedIn: 'root', // this file probably should be deleted.
} )
export class ServiceWorkspaces {
	private workspaces: any[] = []; // TODO: figure out the workspace object that users get when they sign in.
	private activeWorkspace: null | any = null;
	//
	public constructor() {
		//
	}

	public clear(): void {
		this.workspaces = [];
		this.activeWorkspace = null;
	}

	public setActiveWorkspace( ws: string ): void {
		this.activeWorkspace = ws ? ws.toString() : null;
	}
}
