import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {
	InterfaceAnyObject,
	InterfaceAppContext,
	InterfaceHTTPGateway,
	InterfaceOWAPIGetUserProfileResponse,
	InterfaceOWProfilePhoto
} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../authentication';
import {ServiceOWGateway} from '../ow-gateway';
import {ServiceRegex} from '../../../regex';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIAccountProfile {
	private readonly routePrefix: string = 'account/';
	//
	public constructor(
		private readonly auth: ServiceAuthentication,
		private readonly gateway: ServiceOWGateway
	) {
		//
	}

	public getUserProfile( profileID?: string ): Observable<InterfaceHTTPGateway<InterfaceOWAPIGetUserProfileResponse>> {
		const pID: string | null | undefined = profileID ? profileID : this.auth.getProfileID();
		if ( typeof pID === 'string' && pID.length > 0 ) {
			const route: string = this.routePrefix + 'profile/' + encodeURIComponent( pID );
			return this.gateway.fetch( 'get', route );
		} else {
			return this.gateway.requestDenied();
		}
	}

	public updateEmail( newEmail: string ): Observable<InterfaceHTTPGateway> {
		const profileID: string | null = this.auth.getProfileID();
		if ( typeof profileID === 'string' && profileID.length > 0 && newEmail.length > 0 ) {
			return this.gateway.fetch( 'post', this.routePrefix + 'profile/' + encodeURIComponent( profileID ) + '/' + encodeURIComponent( newEmail ) );
			// { 'results' : [ { 'email' : <new_email>, 'confirmed' : <bool> } ] }
		} else {
			return this.gateway.requestDenied();
		}
	}

	// cheaty temp method. this is used to set arbitrary fields on the users profile. normally this is restricted by the template used to define the profile...
	public updateUserProfileKVP( key: string, value: string ): Observable<InterfaceHTTPGateway> {
		const profileID: string | null = this.auth.getProfileID();
		if ( typeof profileID === 'string' && profileID.length > 0 ) {
			if ( key === 'email' ) {
				return this.updateEmail( value.toLowerCase() );
			} else {
				return this.gateway.fetch( 'post', this.routePrefix + 'profile/' + encodeURIComponent( profileID ), JSON.stringify( {
					[key]: value
				} ) );
			}
		} else {
			return this.gateway.requestDenied();
		}
	}

	public updateUserProfileData( dataKVP: InterfaceAnyObject ): Observable<InterfaceHTTPGateway> {
		// this is intended to update the ow_user's .profile{} object.
		const profileID: string | null = this.auth.getProfileID();
		if ( typeof profileID === 'string' && profileID.length > 0 ) {
			return this.gateway.fetch( 'post', this.routePrefix + 'profile/' + encodeURIComponent( profileID ), JSON.stringify( dataKVP ) );
		} else {
			return this.gateway.requestDenied();
		}
	}

	public updateUserProfileInstanceData( appContext: InterfaceAppContext, dataKVP: InterfaceAnyObject ): Observable<InterfaceHTTPGateway> {
		// this is intended to update the (consumer) doclets' data{} object
		return this.gateway.requestDenied();
	}

	public setProfilePicture( appContext: InterfaceAppContext, b64Data: string, photoID?: string ): Observable<InterfaceHTTPGateway> {
		const matches: RegExpMatchArray | null = b64Data.match( ServiceRegex.profilePhotoB64RegExp );
		const profileID: string | null = this.auth.getProfileID();
		if ( typeof profileID === 'string' && profileID.length > 0 && Array.isArray( matches ) && matches.length > 0 ) {
			// 0 is the entire b64 string // data:image/png;base64,iVBORw0K...
			// 1 is the mine type 'image/' + [1] (png|jpeg)
			// 2 is the raw data. not needed.
			// 3+ are the last chunks that are around only for validation. not needed.
			const route: string = this.routePrefix + 'profile/' + encodeURIComponent( profileID ) + '/photo';
			let fileExt: string = '';
			switch ( matches[1] ) {
				case 'png': {
					fileExt = 'png';
					break;
				}
				case 'jpg': case 'jpeg' : { // 'jpeg' is the correct case.
					fileExt = 'jpg';
					break;
				}
			}
			const payload: InterfaceOWProfilePhoto = {
				image_data: matches[0],
				image_ext: fileExt,
				image_name: profileID + (fileExt.length > 0 ? '.' + fileExt : ''),
				image_type: 'image/' + matches[1]
			};
			if ( typeof photoID === 'string' && photoID.length > 0 ) {
				payload.image_id = photoID;
			}
			return this.gateway.fetch( 'post', route, JSON.stringify( payload ) );
			// OK : { 'status' : 200 , 'results' : [ { 'image_id' : '5f4434835e0e4e67c63df94e' } ] }
			// bad b64 : { 'status' : 500 , 'errors' : [ { 'error' : 'Invalid Request' } ] }
			// bad auth : 401
			// bad realm, profile, etc : 500
		} // else the base64 data is invalid
		return this.gateway.requestDenied();
	}
}
