export interface InterfaceToast {
	title: string;
	text: string;
	icon: string;
	actions?: InterfaceToastAction[];
	duration: 'short' | 'long';
	expiresAt?: number;
}

export interface InterfaceToastAction {
	text: string;
	callback: any;
}
