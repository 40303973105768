import {Injectable} from '@angular/core';
/*
 * Each project must swap out the ow-live-ids.ts for the ow-staging-ids.ts as needed, as well as each of their app's configs.
 * This is done in the angular.json file, in the "projects.$PROJECT_NAME.architect.build.configurations.$CONFIG.fileReplacements[]
 */
@Injectable( {
	providedIn: 'root'
} )
export class ConfigOWEnvironmentIDs { // Dev Version
	protected readonly mode: string = 'DEV';
	public getMode(): string {
		return this.mode;
	}
	// ===== //
	public readonly RYSTRealmID: string = '660f48da20855d74d6ffdcea';
	public readonly RYSTWorkspaceID: string = '660f436420855d74d6ffdce9';
	// ===== //
	protected readonly baseAPI: string = 'https://api.omniplatform.dev/'; // live: .com and staging: .dev
	public getBaseAPI(): string {
		return this.baseAPI;
	}
	// ===== Socket IO ===== //
	protected readonly ioHost: string = ''; // https://service.omniplatform.io:8200'; // don't add the trailing slash.
	// live's ioHost's port number ought to be in the 9K's. testing ought to be in the 8K's.  8200, 9200, etc.
	public getIOHost(): string {
		return this.ioHost;
	}
	// ===== Categories ===== //
	protected readonly categoryIDs: { // RYST categories
		[key: string]: string;
	} = {
		'Pass': '6699b49b8a01791304eace67', // these are used in workflow actions to fetch doclets, whose template are tagged with these. <template>.category = [ id1, id2, id3, ... ]
		'Merchandise': '67cb3c76b95cc4e7b82ae593'
	};
	public getCategoryID( name: string ): string {
		if ( !( name in this.categoryIDs ) ) {
			console.trace( 'Missing ID for category:', name );
		}
		return this.categoryIDs[name] ?? '';
	}
	// ===== Template IDs ===== //
	protected readonly templateIDs: { // this is specific to the RYST catalog (cat id 6614718ac2913c3555867508)
		[key: string]: string;
	} = {
		'Event Pass': '62d889e5f56d739da86c9b76', // pass for sale. buying one of these grants you various entitlements.
		'Event Merchandise': '67cb3f2db95cc4e7b82ae594', // merch for sale. nearly the same as Event Pass. it produces entitlements, etc.
		'Entitlement Type': '66a8149efa1ace3d740949ad', // woven to a pass for sale. simply identifies a 'kind' for something to access/acquire/do.
		'Admission QR Code': '628e5c7935d8cb22efe21145', // proof-of-purchase of an event pass. container of consumer entitlements. woven to a Consumer.
		'Consumer Entitlement': '66c6453bf419399cd9484346', // describes what the purchaser can do in the venue. woven to the QR Code, the container of consumer entitlements.
		'Entitlement Activation': '66ce174d6c9036014fa09887', // used for tracking and reporting.
		// Places
		'Venue Facility': '669195f62ec6833212811353', // the physical location of a venue, such as a theme park or theatre.
		'Venue Event': '669199542ec6833212811354', // the name and date/time for participating in an event.
		'Venue Event Type': '675727ec1f0a3ec7086b44d1', // this should probably be something different...
		'Location': '6615d534fb193854f8a24079', // (more like an access terminal location) // instances of named location. used for extra logic to restrict/allow users to travel places.
		// Marketing
		'Brand': '67534c0c23a73f2ad5f92201', // just a name field.
		'Market': '675348e423a73f2ad5f92200', // city, state, zipcode.
		// Infrastructure:
		'Access Terminal': '66425c1b3193e09d313698d0', // instance of a smart-card access terminal. (gated entry)
		'Bank Vault': '6439e0bc6eb7049cf875f813', // physical storage for money. cannot move out of it. See Cash Vault.
		'Cash Vault': '6439dfeb6eb7049cf875f812', // Physical temp-storage for money. Used to refill the Tills. See Bank Vault.
		// Orders, Discounts
		'Product Discount': '62e3282ae32a107779388488', // tickets are woven to discount codes...
		'Order': '62a10966c63b0837fce2892c', // container for order data.
		'Smart Terminal': '62a132514b309b4bc51d8b22', // instance of a credit-card smart terminal. (pos money collection)
		'Transaction': '62a10aaec63b0837fce2892d', // things and stuff for orders.
		'Affiliate': '66e9f9d45f2d0d840fcfefe7', // holds an address, phone, and a token to use.
		// Portal Consumer Records:
		'Account': '62fc07948a2a570900d74b23', // billing and shipping info
		'Card Vault': '62fc10378a2a570900d74b24', // CC on file.
		'Consumer': '627959e6f99ef9cce0fba2b3', // either the family member or the purchaser.
		'Consumer Group': '627146b1e32a107779d0d88b', // the container of consumers and entitlements for the group.
		'Wristband': '62b53a13e32a1077794a5506', // RYST wristband.
		// POS & Staff Records:
		'Cash Drawer Till': '6439d9496eb7049cf875f811', // POS Staff Members Till.
		'Cash Transport': '6439e1856eb7049cf875f814', // virtual container, used to track money being moved from the Till to somewhere else.
		'POS Account': '660f231c20855d74d6ffdce8', // account for the POS (staff) members.
		'Staff': '62c342c6e32a1077795cfb4a', // POS user.
		// Settings
		'Category': '6699addf620ae701e9126e2f', // special tagging system used on templates to fetch doclets by category.
		'Capacity Settings': '65fcb5053d0fe71b680a732f', // determines the capacity (by date, by role) for seating or general admittance.
		'Domain Config': '669ff48359b2f78c1558fa56', // holds the URL & workspace ID combos...
		'Holiday Schedule': '6691afba2ec683321281135b', // `data.days` is an array of YYYY-MM-DD strings for blocked dates.
	};
	public getTemplateID( name: string ): string {
		if ( !( name in this.templateIDs ) ) {
			console.trace( 'Missing ID for template:', name );
		}
		return this.templateIDs[ name ] ?? '';
	}
	// =========== Weaves =========== //
	// last updated March 20th, 2025.
	// ============================== //
	// (Event Pass)->(Capacity Settings) // availability is determined by the woven capacity settings on a pass for sale.
	// (Entitlement Type)->(Event Pass) // entitlements types are granted when buying a pass for sale.
	// (Consumer)->(Admission QR Code)->(Consumer Entitlement)<-?->(Consumer) // consumer entitlements describe what the customer can do/go/access.
	// (Venue Event)<->(Event Pass)<->(Consumer Entitlement) // Venue Events have Event Passes, Event Passes have Consumer Entitlements.
	// (Event Merchandise)->(Capacity Settings) // event merch are just like event passes. they're part of the Event Product interface.
	// (Event Merchandise)<->(Entitlement Type)
	// (Product Discount)->(Event Merchandise)
	// (Venue Event)<->(Event Merchandise)<->(Consumer Entitlement)<-?->(Consumer) // TODO: check if entitlements link back to consumers...
	// (Consumer:P)->(Family)->(Consumer:FM) // .data.role: "Purchaser" has a family with "Pass Holder"
	// (Consumer:P)->(Order)->(Transaction) // "Purchaser" has orders, "Pass Holder" do not.
	// (Consumer:FM)->(Wristband)
	// (Consumer:P)->(Account)->(Card Vault) // family members do not have an account doclet nor a card on file. only purchasers do.
	// (Staff)<-(Cash Drawer Till) // POS staff members have Tills. Portal front-end users should not. Staff are not Consumer.
	// (Staff)<-(Cash Transport) // Whose transporting cash (or whose responsible for it, etc.)
	// (Cash Drawer Till)<-(Cash Transport) // when Cash Out occurs, we track it as a Transport, which needs to go to a Vault next.
	// (Venue Facility)->(Venue Event)->(everything_for_sale) // Venue Facilities have Venue Events, which have passes for sale.
	// (Venue Facility)->(Holiday Schedule) // the Venue Facilities have blocked dates. the Events currently do not.
	// ===== //
}
