<span class="ui-toggle-wrapper"
	(click)="toggle();"
>
	<span class="ui-toggle-state-wrapper">
		<span class="ui-toggle-state state-on">On</span>
		<span class="ui-toggle-state state-off">Off</span>
	</span>
	<span class="ui-toggle-plate"
		[ngClass]="{
			'ui-toggle-plate': true,
			'toggle-on': checked,
			'toggle-off': !checked
		}"
	>&nbsp;</span>
</span>
