import {TypeDocletReference} from '../ow/doclets';

export interface InterfaceOWTemplateVenueFacility { // 669195f62ec6833212811353
	// venue facilities are the physical address. they're generally not the sub-sections with-in an amusement park, but the address of the park itself.
	name: string;
	street_address: string;
	city: string;
	state: string;
	zip: string;
//	latitude: number;
//	longitude: number;
	status: 'active' | 'hidden';
}

export interface InterfaceOWTemplateVenueEvent { // 669199542ec6833212811354
	// events are held at a venue. they may exist with-in a sub-section instead...
	name: string;
	start_date: string; // date // YYYY-MM-DD
	start_time: string;
	end_date: string; // date // YYYY-MM-DD
	end_time: string;
//	latitude: number;
//	longitude: number;
//	location: string; // (floor, room #, directions, etc.)
	status: 'active' | 'hidden';
	external_id: string;
	market: TypeDocletReference; // 675348e423a73f2ad5f92200
	brand: TypeDocletReference; // 67534c0c23a73f2ad5f92201
	type: TypeDocletReference; // 675727ec1f0a3ec7086b44d1
	lifecycle: TypeVenueEventLifecycle | string;
}

export interface InterfaceOWTemplateVenueEventType { // 675727ec1f0a3ec7086b44d1
	type: string;
}

// ===== Types ===== //
export type TypeVenueEventLifecycle = 'Unknown' | 'Booked' | 'Contract Pending' | 'Approval Needed' | 'Searching' | 'Canceled';
