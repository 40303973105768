// ===== App ===== //
import {AppComponent} from './app.component';
import {AppConfig} from './app.config';
import {AppRoutingModule} from './app-routing.module';
// ===== Collections ===== //
import {Collection} from '../../../../ow-framework/collections/collection';
import {CollectionProfiles} from '../../../../ow-framework/collections/profiles';
// ===== Components ===== //
// see: ModuleAppComponents
// see: ModuleVPComponents
// ===== Modals ===== //
import {ModalBackDrop} from './modals/back-drop/back-drop';
import {ModalCancelReservation} from './modals/cancel-reservation/cancel-reservation';
import {ModalCreditCard} from './modals/credit-card/credit-card';
import {ModalEditGroupMember} from './modals/edit-group-member/edit-group-member';
import {ModalInviteGroupMember} from './modals/invite-group-member/invite-group-member';
import {ModalLiabilityWaiver} from './modals/liability-waiver/liability-waiver';
import {ModalTinySignIn} from './modals/tiny-sign-in/tiny-sign-in';
// ===== Modules ===== //
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ModuleAppComponents} from './components/components.module';
import {BrowserModule, Title} from '@angular/platform-browser';
import {ModuleVPComponents} from '../../../../ow-framework/components/components.module';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ImageCropperModule} from 'ngx-image-cropper';
import {NgModule} from '@angular/core';
import {RecaptchaModule} from 'ng-recaptcha';
import {ModulePipes} from '../../../../ow-framework/pipes/pipes.module';
import {QRCodeModule} from 'angularx-qrcode';
import {ModuleServices} from '../../../../ow-framework/services/services.module';
import {ModuleTransformers} from '../../../../ow-framework/transformers/transformers.module';
// ===== Pages ===== //
import {PageCheckEmail} from './pages/check-email/check-email';
import {PageCheckout} from './pages/checkout/checkout';
import {PageCompleteAccount} from './pages/complete-account/complete-account';
import {PageCreateGroup} from './pages/create-group/create-group';
import {PageDashboard} from './pages/dashboard/dashboard';
import {PageEditFamily} from './pages/edit-family/edit-family';
import {PageEditGroup} from './pages/edit-group/edit-group';
import {PageFamily} from './pages/family/family';
import {PageMyAccount} from './pages/my-account/my-account';
import {PageOrders} from './pages/orders/orders';
import {PageResetPassword} from './pages/reset-password/reset-password';
import {PageSignIn} from './pages/sign-in/sign-in';
import {PageThankYou} from './pages/thank-you/thank-you';
import {PageTickets} from './pages/tickets/tickets';
// ===== Pipes ===== //
// see: ModulePipes
// ===== Services ===== //
// see: ModuleServices
// ===== Transformers ===== //
// see: ModuleTransformer
//
@NgModule( {
	bootstrap: [
		AppComponent
	],
	declarations: [ // components, modals (not modules), pages, pipes
		AppComponent,
		ModalBackDrop,
		ModalCancelReservation,
		ModalCreditCard,
		ModalEditGroupMember,
		ModalInviteGroupMember,
		ModalLiabilityWaiver,
		ModalTinySignIn,
		PageTickets,
		PageCheckEmail,
		PageCheckout,
		PageCompleteAccount,
		PageCreateGroup,
		PageDashboard,
		PageEditFamily,
		PageEditGroup,
		PageFamily,
		PageMyAccount,
		PageOrders,
		PageResetPassword,
		PageSignIn,
		PageThankYou
	],
	exports: [ // modules
	],
	imports: [ // modules (not modals)
		ModuleAppComponents,
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		ImageCropperModule,
		RecaptchaModule,
		ModulePipes,
		QRCodeModule,
		ModuleServices,
		ModuleTransformers,
		ModuleVPComponents
	],
	providers: [ // collections, services, transformers, injectables (things dragged through constructors)
		AppConfig,
		Collection,
		CollectionProfiles,
		Title
	]
} )
export class AppModule {}
