import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, ParamMap} from '@angular/router';
// ===== App ===== //
import {AppRouterLinks} from '../../app.router-links';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway, InterfaceOWAPIResponse} from '../../../../../../ow-framework/interfaces/interfaces';
interface InterfaceResetPasswordData {
	password1: string;
	password2: string;
}
interface InterfaceResetPasswordErrors {
	password1: boolean;
	password2: boolean;
}
// ===== Services ===== //
import {ServiceAuthentication} from '../../../../../../ow-framework/services/authentication';
import {ServiceNavigate} from '../../../../../../ow-framework/services/navigate';
import {ServiceOWAPI} from '../../../../../../ow-framework/services/ow-api';
//
@Component( {
	selector: 'page-reset-password',
	templateUrl: './reset-password.html',
	styleUrls: [
		'./reset-password.less'
	]
} )
export class PageResetPassword implements OnInit { // this is part 2 of forgot password. part 1 is on the log-in page.
	public readonly routes: typeof AppRouterLinks = AppRouterLinks;
	public resetToken: string = '';
	public invalidToken: boolean = false;
	public resetPasswordCompleted: boolean = false;
	//
	public resetPasswordData: InterfaceResetPasswordData = {
		password1: '',
		password2: ''
	};
	public resetPasswordErrors: InterfaceResetPasswordErrors = {
		password1: false,
		password2: false
	};
	private busy: boolean = false;
	//
	public constructor(
		private readonly activeRoute: ActivatedRoute,
		private readonly auth: ServiceAuthentication,
		private readonly nav: ServiceNavigate,
		private readonly owapi: ServiceOWAPI,
		private readonly title: Title
	) {
		this.title.setTitle( 'Portal' );
	}

	public ngOnInit(): void {
		if ( this.auth.isSignedIn() ) {
			this.nav.toURL( '/' + this.routes.dashboard );
		} else { // else the user is not yet signed in.
			this.processToken();
		}
	}

	private getResetToken(): void {
		if ( this.activeRoute.snapshot.params && typeof this.activeRoute.snapshot.params['t'] === 'string' && this.activeRoute.snapshot.params['t'].length > 0 ) {
			// from the route: /reset-password/:t
			this.resetToken = this.activeRoute.snapshot.params['t'];
		} else {
			// else from the route: /reset-password?id=
			this.activeRoute.queryParamMap.subscribe( (query: ParamMap): void => {
				const id: string | null = query.get( 't' );
				if ( typeof id === 'string' && id.length > 0 ) {
					this.resetToken = id;
				}
			} );
		}
	}

	private processToken(): void {
		this.getResetToken();
		if ( !this.resetToken || this.resetToken.length < 1 ) {
			this.nav.toURL( '/' + this.routes.dashboard );
		}
	}

	public validatePassword( key: 'password1' | 'password2' ): void {
		this.invalidToken = false; // don't bother the user with a stale error.
		//
		if ( this.resetPasswordData[key].length < 8 ) {
			this.resetPasswordErrors[key] = true;
		} else if ( key === 'password1' ) {
			if ( this.resetPasswordData.password2.length > 0 ) {
				this.resetPasswordErrors.password2 = this.resetPasswordData.password1 !== this.resetPasswordData.password2;
			}
		} else if ( key === 'password2' ) {
			if ( this.resetPasswordData.password1.length > 0 ) {
				this.resetPasswordErrors.password1 = this.resetPasswordData.password2 !== this.resetPasswordData.password1;
			}
		}
	}

	public resetPassword(): void {
		if ( !this.busy ) {
			this.invalidToken = false;
			this.validatePassword( 'password1' );
			this.validatePassword( 'password2' );
			if ( !this.resetPasswordErrors.password1 && !this.resetPasswordErrors.password2 ) {
				this.busy = true;
				this.owapi.account.auth.redeemPasswordResetToken( this.resetToken, this.resetPasswordData.password1 ).subscribe( (response: InterfaceHTTPGateway): void => {
					this.busy = false;
					if ( response && response.success && response.status === 200 ) {
						const apiResponse: InterfaceOWAPIResponse = response.data;
						if ( apiResponse ) {
							this.resetPasswordCompleted = true;
						} else {
							this.invalidToken = true;
						}
					} else {
						this.invalidToken = true;
					}
				} );
			}
		}
	}

	public blur( E: Event ): void {
		if ( E.target instanceof HTMLElement ) {
			void E.target.dispatchEvent( new Event( 'blur' ) );
		}
	}
}
