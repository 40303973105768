import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway} from '../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceOWGateway} from './ow-gateway';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPISupport {
	private readonly routePrefix: string = 'support/';
	//
	public constructor(
		private readonly gateway: ServiceOWGateway
	) {
		//
	}

	// ========================= Support Tickets ========================= //

	public submitSupport( // 8 params
		appShortCode: string,
		firstName: string, lastName: string,
		email: string, phone: string,
		description: string, byEmail: boolean, byPhone: boolean
	): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix, JSON.stringify( {
			issue_title: appShortCode, // this is the 2 or 3 character code for the project that matches against our project board.
			issue_description: description,
			issuer_name: firstName + ' ' + lastName,
			issuer_phone: phone,
			issuer_email: email,
			issuer_pref_contact: byEmail && byPhone ? 'Email/Phone' : ((byEmail ? 'Email' : '') + (byPhone ? 'Phone' : ''))
		} ), true );
	}
}
