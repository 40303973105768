import {Component, OnDestroy, OnInit} from '@angular/core';
//
@Component( {
	selector: 'modal-back-drop',
	templateUrl: './back-drop.html',
	styleUrls: [
		'./back-drop.less'
	]
} )
export class ModalBackDrop implements OnDestroy, OnInit {
	//
	public constructor() {
		//
	}

	public ngOnInit(): void {
		document.body.classList.add( 'modal' );
	}

	public ngOnDestroy(): void {
		document.body.classList.remove( 'modal' );
	}
}
