import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppRouterLinks} from '../../app.router-links';
// ===== Interfaces ===== //
import {
	InterfaceAppEvent,
	InterfaceCartData,
	InterfaceCartDataChanged,
	InterfaceEventEntitlementLock,
	InterfaceEventWorkspaceChanged
} from '../../../../../../ow-framework/interfaces/interfaces';
interface InterfaceDisplayedCartData {
	name: string;
	qty: number; // just going to be cartItems.length
	date: {
		display: string; // either the intended string for display, or some pre-calc'd thing to shove through a display-pipe type of thing.
		y?: number; // YYYY
		m?: number; // MM where it ought to be 01 to 12, but 00 is a special case.
		d?: number // 01 to 31
	};
	lineItemTotal: number; // the total cost of just this array of items
	cartItems: InterfaceCartData[];
}
// ===== Services ===== //
import {ServiceAppEvents} from '../../../../../../ow-framework/services/app-events';
import {ServiceAuthentication} from '../../../../../../ow-framework/services/authentication';
import {ServiceCart} from '../../../../../../ow-framework/services/cart';
import {ServiceNavigate} from '../../../../../../ow-framework/services/navigate';
import {ServiceSorting} from "../../../../../../ow-framework/services/sorting";
//
@Component( {
	selector: 'app-header',
	templateUrl: './app-header.html',
	styleUrls: [
		'./app-header.less'
	]
} )
export class ComponentAppHeader implements OnDestroy, OnInit {
	@Input()
	public lockdown: boolean = false; // when true, the user cannot do anything but sign out, or sign in.
	//
	public readonly routes: typeof AppRouterLinks = AppRouterLinks;
	private subCartChanged: Subscription | null = null;
	public cachedCartData: InterfaceCartData[] = [];
	public displayedCartData: InterfaceDisplayedCartData[] = [];
	public cartCount: number = 0;
	public totalPrice: number = 0;
	private subUserReSync: Subscription | null = null;
	private subNavEnd: Subscription | null = null;
	private subCloseModal: Subscription | null = null;
	public isOnCheckout: boolean = false;
	public isSignedIn: boolean = false;
	public isShowingMenu: boolean = false;
	public isShowingCart: boolean = false;
	public isPreviewingCart: boolean = false;
	private cartPreviewTimer: any = null;
	public enabledLinkMyAccount: boolean = false;
	public enabledLinkDashboard: boolean = false;
	public enabledLinkOrders: boolean = false;
	public copyMap: {
		[key: string]: string;
	} = {
		eventWebsite: 'https://thisisframework.com/'
	};
	private subWorkspaceChanged: Subscription | null = null;
	//
	public constructor(
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly nav: ServiceNavigate,
		public readonly router: Router
	) {
		this.isSignedIn = this.auth.isSignedIn();
		this.subCartChanged = ServiceCart.cartChanged.subscribe( (event: InterfaceCartDataChanged): void => {
			this.syncCart();
			switch ( event.type ) {
				case 'added': {
					this.showCart( true );
					console.log( 'app-header - item added to cart', event.items );
					break;
				}
				case 'cleared': {
					break;
				}
				case 'removed': {
					break;
				}
			}
		} );
		this.subUserReSync = ServiceAppEvents.listen( 'user:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
			this.isSignedIn = this.auth.isSignedIn();
		} );
		this.subNavEnd = this.router.events.subscribe( (E): void => {
			if ( E instanceof NavigationEnd ) {
				this.isOnCheckout = E.urlAfterRedirects === '/' + this.routes.checkout;
			}
		} );
		this.subCloseModal = ServiceAppEvents.listen( 'cart:close' ).subscribe( (_: InterfaceAppEvent): void => {
			this.hideCart();
		} );
		this.subWorkspaceChanged = ServiceAppEvents.listen( 'workspace:changed' ).subscribe( (_: InterfaceAppEvent<InterfaceEventWorkspaceChanged>): void => {
			// TODO: fetch the new copy and update things as needed.
		} );
		//
		ServiceCart.deserialize();
		this.syncCart();
	}

	private syncCart(): void {
		this.cachedCartData = ServiceCart.getCartData();
		this.cartCount = this.cachedCartData.filter( (item: InterfaceCartData): boolean => {
			return !item.remove;
		} ).length;
		this.transformCachedCartData();
	}

	public ngOnInit(): void {
		this.enabledLinkDashboard = this.router.url !== '/' + this.routes.dashboard;
		this.enabledLinkMyAccount = this.router.url !== '/' + this.routes.myAccount;
		this.enabledLinkOrders = this.router.url !== '/' + this.routes.orders;
	}

	public ngOnDestroy(): void {
		if ( this.subCartChanged ) {
			this.subCartChanged.unsubscribe();
			this.subCartChanged = null;
		}
		if ( this.subUserReSync ) {
			this.subUserReSync.unsubscribe();
			this.subUserReSync = null;
		}
		if ( this.subNavEnd ) {
			this.subNavEnd.unsubscribe();
			this.subNavEnd = null;
		}
		if ( this.subCloseModal ) {
			this.subCloseModal.unsubscribe();
			this.subCloseModal = null;
		}
		if ( this.subWorkspaceChanged ) {
			this.subWorkspaceChanged.unsubscribe();
			this.subWorkspaceChanged = null;
		}
	}

	private transformCachedCartData(): void {
		const unsortedItems: {
			[dateNameKey: string]: InterfaceDisplayedCartData;
		} = {};
		for ( let x: number = 0; x < this.cachedCartData.length; ++x ) {
			const dateNameKey: string = String( '0000' + (this.cachedCartData[x].visitDate.year ?? '') ).slice( -4 )
			+ String( '00' + (this.cachedCartData[x].visitDate.month1 ?? '') ).slice( -2 )
			+ String( '00' + (this.cachedCartData[x].visitDate.day ?? '') ).slice( -2 )
			+ this.cachedCartData[x].name;
			if ( !(dateNameKey in unsortedItems) ) {
				unsortedItems[dateNameKey] = {
					name: this.cachedCartData[x].name,
					qty: 0,
					date: {
						display: this.cachedCartData[x].visitDate.isAnyDay
							? 'Any'
							: (this.cachedCartData[x].visitDate.isEventLength
								? 'Event'
								: String( '00' + this.cachedCartData[x].visitDate?.month1 ?? '' ).slice( -2 ) + '/' + String( '00' + this.cachedCartData[x].visitDate?.day ?? '' ).slice( -2 )
						),
						y: this.cachedCartData[x].visitDate.year ?? 0,
						m: this.cachedCartData[x].visitDate.month1 ?? 0,
						d: this.cachedCartData[x].visitDate.day ?? 0
					},
					lineItemTotal: 0,
					cartItems: []
				};
			}
			if ( !this.cachedCartData[x].remove ) {
				++unsortedItems[dateNameKey].qty;
				unsortedItems[dateNameKey].lineItemTotal += this.cachedCartData[x].price;
			}
			unsortedItems[dateNameKey].cartItems.push( this.cachedCartData[x] );
		}
		// ===== //
		this.displayedCartData = [];
		this.totalPrice = 0;
		Object.keys( unsortedItems ).sort( (A: string, B: string): number => {
			return ServiceSorting.naturalSort( A, B ); // early/strange dates go first, older dates appear last. sub-sorted by the name of the pass for sale.
		} ).forEach( (dateNameKey: string): void => {
			const lineItemTotal = Number( unsortedItems[dateNameKey].lineItemTotal.toFixed( 2 ) );
			unsortedItems[dateNameKey].lineItemTotal = lineItemTotal;
			unsortedItems[dateNameKey].cartItems.sort( (itemA: InterfaceCartData, itemB: InterfaceCartData): number => {
				return itemA.remove || itemB.remove ? -1 : 0; // the ones to destroy go up front, to make it easier on other logic located elsewhere.
			} );
			this.totalPrice += lineItemTotal;
			this.displayedCartData.push( unsortedItems[dateNameKey] );
		} );
		this.totalPrice = Number( this.totalPrice.toFixed( 2 ) );
	}

	public addQty( lineItem: InterfaceDisplayedCartData ): void {
		if ( lineItem.cartItems.length > 0 ) {
			let reUsedExisting: boolean = false;
			let idx: number = lineItem.cartItems.length;
			while ( idx-- > 0 ) { // trying to re-use an item that was removed previously.
				if ( lineItem.cartItems[idx].remove ) {
					lineItem.cartItems[idx].remove = undefined;
					reUsedExisting = true;
					++lineItem.qty;
					break;
				}
			}
			if ( reUsedExisting ) {
				this.syncCart();
			} else {
				const itemToCopy: InterfaceCartData = lineItem.cartItems[0];
				if ( itemToCopy.spaceID !== null ) {
					return; // you cannot duplicate unique tickets.
				}
				let ticketDate: InterfaceEventEntitlementLock['date'] = '';
				if ( itemToCopy.visitDate.isAnyDay ) {
					ticketDate = 'any';
				} else if ( itemToCopy.visitDate.isEventLength ) {
					ticketDate = 'event';
				} else {
					ticketDate = {
						year: itemToCopy.visitDate.year ?? 0,
						month1: itemToCopy.visitDate.month1 ?? 0,
						day: itemToCopy.visitDate.day ?? 0
					};
				}
				const entitlementLockData: InterfaceEventEntitlementLock = {
					eventID: itemToCopy.eventID,
					passID: itemToCopy.passID,
					spaceID: null,
					date: ticketDate,
					roleID: this.appConfig.getRoleID( 'Web' ),
					callback: (entitlementID: string | false): void => {
						console.log( 'result of entitlement lock', entitlementID );
						if ( typeof entitlementID === 'string' ) {
							ServiceCart.increaseQuantity( itemToCopy, entitlementID );
						}
						// don't need to this.syncCart(); because the cart service will trigger an event that makes it way back around that triggers the sync.
					}
				};
				ServiceAppEvents.broadcast( {
					topic: 'entitlement:lock',
					data: entitlementLockData
				} );
			}
		}
	}

	public reduceQty( lineItem: InterfaceDisplayedCartData ): void {
		for ( let x: number = 0; x < lineItem.cartItems.length; ++x ) {
			if ( !lineItem.cartItems[x].remove ) {
				--lineItem.qty;
				lineItem.cartItems[x].remove = true;
				break;
			}
		}
		this.syncCart();
	}

	public removeLineItemGroup( lineItem: InterfaceDisplayedCartData ): void {
		lineItem.cartItems.forEach( (item: InterfaceCartData): void => {
			item.remove = true;
		} );
		ServiceCart.removeTaggedCartItems();
	}

	public signOut(): void {
		this.syncCart();
		ServiceAppEvents.broadcast( 'user:signed-out' );
	}

	public goBuyNow(): void {
		this.syncCart();
		this.nav.toURL( '/' + this.routes.tickets );
	}

	public goSignIn(): void {
		this.syncCart();
		this.nav.toURL( '/' + this.routes.signIn );
	}

	public goMyAccount(): void {
		this.syncCart();
		this.nav.toURL( '/' + this.routes.myAccount );
	}

	public goDashboard(): void {
		this.syncCart();
		this.nav.toURL( '/' + this.routes.dashboard );
	}

	public goOrders(): void {
		this.syncCart();
		this.nav.toURL( '/' + this.routes.orders );
	}

	public toggleMenu(): void {
		this.hideCart();
		this.isShowingMenu = !this.isShowingMenu;
	}

	public toggleCart(): void {
		this.isShowingMenu = false;
		if ( this.isShowingCart || this.isOnCheckout ) {
			this.hideCart();
		} else {
			this.showCart();
		}
	}

	public showCart( preview: boolean = false): void {
		this.isPreviewingCart = !this.isShowingCart && preview;
		this.isShowingCart = true;

		if ( this.isPreviewingCart ) {
			clearTimeout( this.cartPreviewTimer );
			this.cartPreviewTimer = setTimeout( (): void => {
				this.hideCart();
			}, 5000 );
		}
	}

	public hideCart(): void {
		clearTimeout( this.cartPreviewTimer );
		this.isShowingCart = false;
		this.isPreviewingCart = false;
		ServiceCart.removeTaggedCartItems();
	}

	public touchCart(): void {
		if ( this.isPreviewingCart ) {
			this.isPreviewingCart = false;
			clearTimeout( this.cartPreviewTimer );
		}
	}

	public goCheckout(): void {
		this.syncCart();
		this.nav.toURL( '/' + this.routes.checkout );
	}
}
