import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../../../../../ow-framework/collections/profiles';
// ===== Interfaces ===== //
import {
	InterfaceAppContext,
	InterfaceNavMenuItem,
	InterfaceOWDocletAccount,
	InterfaceOWDocletConsumer,
	InterfaceOWUser
} from '../../../../../../ow-framework/interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../../../../ow-framework/services/authentication';
import {ServiceNavigate} from '../../../../../../ow-framework/services/navigate';
//
@Component( {
	selector: 'page-family',
	templateUrl: './family.html',
	styleUrls: [
		'./family.less'
	]
} )
export class PageFamily implements OnInit {
	public readonly routes: typeof AppRouterLinks = AppRouterLinks;
	public mastHeading: string = 'Hello';
	public readonly navMenuItems: InterfaceNavMenuItem[] = [
		{
			route: '/' + this.routes.dashboard,
			text: 'Dashboard'
		},
		{
			route: '/' + this.routes.family,
			text: 'Manage Family',
			shortText: 'Family'
		},
		{
			route: '/' + this.routes.orders,
			text: 'Orders & Billing',
			shortText: 'Billing'
		},
		{
			route: '/' + this.routes.myAccount,
			text: 'My Account',
			shortText: 'Account'
		}
	];
	//
	public haveUserInfo: boolean = false;
	private readonly strConsumerTemplateID: string = this.appConfig.getTemplateID( 'Consumer' );
	private readonly strAccountTemplateID: string = this.appConfig.getTemplateID( 'Account' );
	// ow_user fields
	public firstName: string | null = null;
	public lastName: string | null = null;
	// consumer fields
	public photo: string | null = null;
	public cashlessSpending: boolean = false; // TODO: fetch the account doclet linked to the consumer, to get the cashless status
	//
	public constructor(
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly nav: ServiceNavigate,
		private readonly title: Title
	) {
		this.title.setTitle( 'Portal' );
	}

	private fetchUserInfo(): void {
		this.colProfiles.getMyUserProfile( (userData: InterfaceOWUser | null): void => {
			if ( userData && userData._id ) {
				const profileID: string = userData._id.$oid;
				this.firstName = userData?.profile?.first_name ?? '';
				this.lastName = userData?.profile?.last_name ?? '';
				this.mastHeading = 'Hello, ' + this.firstName + ' ' + this.lastName;
				const ac: InterfaceAppContext = this.appConfig.getContext();
				this.colProfiles.getUserWorkspaceDoclet( ac, profileID, this.strConsumerTemplateID, (consumerDoclet: InterfaceOWDocletConsumer | null): void => {
					if ( consumerDoclet === null ) {
						console.log( 'User is missing their consumer doclet' );
					} else {
						this.photo = consumerDoclet.data?.['photo'] ?? null;
						this.colProfiles.getAccountWorkspaceDoclet( ac, profileID, this.strAccountTemplateID, (accountDoclet: InterfaceOWDocletAccount | null, _: InterfaceOWUser | null ): void => {
							if ( accountDoclet ) {
								this.cashlessSpending = accountDoclet.data.cashless_spending ?? false;
							}
						} );
					}
				} );
			}
		} );
	}

	public ngOnInit(): void {
		if ( this.auth.isSignedIn() ) {
			this.fetchUserInfo();
		} else {
			console.log( 'not signed in' );
			this.nav.toURL( '/' + this.routes.signIn );
		}
	}
}
