import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {InterfaceAppContext, InterfaceHTTPGateway} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIWorkspaceTasks {
	private readonly routePrefix: string = 'workspace/';
	//
	public constructor(
		private readonly gateway: ServiceOWGateway
	) {
		//
	}

	// ========================= Workflow Tasks ========================= //

	public getTasks( appContext: InterfaceAppContext ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/tasks' );
	}

	public approveTask( appContext: InterfaceAppContext, taskId: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/task/' + encodeURIComponent( taskId ), JSON.stringify( {
			status: 'confirmed'
		} ) );
	}

	public denyTask( appContext: InterfaceAppContext, taskId: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/task/' + encodeURIComponent( taskId ), JSON.stringify( {
			status: 'declined'
		} ) );
	}
}
