<header id="masthead"
	[ngClass]="{
		'minimized': didPickDate
	}"
>
<!--	<div class="wrapper logo-bar">-->
<!--		<div class="cart-toggle">-->
<!--			<i class="cart-icon fa-solid fa-cart-shopping"></i>-->
<!--			<ul class="contents">-->
<!--				<li class="price">{{ grandTotal | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}</li>-->
<!--				<li class="quantity">{{ cartCount }} Items</li>-->
<!--			</ul>-->
<!--		</div>-->
<!--	</div>-->
	<div class="wrapper cta-wrapper">
		<!-- TODO: if the product does not have capacity for that date, instead of "add to cart" it ought to say "select a date" or something shorter. -->
		<!-- TODO: once the user hits "select a date" the calendar ought to show up specific to that product. -->
		<h1>{{ copyMap['header'] }}</h1>
		<h2 [innerHTML]="copyMap['intro']"></h2>
		<div class="filter-bar none" tabindex="0">
			<div class="input-wrapper">
				<i class="fa-regular fa-calendar-days"></i>
				<input type="text" [value]="monthLabels[ selectedDate.month1 - 1 ] + ' ' + selectedDate.day + ', ' + selectedDate.year" />
				<i class="fa-solid fa-chevron-down"></i>
			</div>
			<div class="calendar-frame">
				<app-calendar-embed
					[selectedYYYYMMDD]="selectedDate"
					[boundaryDateBegin]="calendarRangeStart"
					[boundaryDateEnd]="calendarRangeEnd"
					(dayChanged)="daySelected( $event );"
					[calendarsToShow]="2"
				></app-calendar-embed>
			</div>
		</div>
	</div>
</header>
<div class="wrapper" *ngIf="multiUsePasses.length > 0;">
	<section id="listing-passes" class="list-tickets">
		<div class="ticket type-pass is-multi-use"
			*ngFor="let pass of multiUsePasses;"
		>
			<div class="ticket-header">
				<span class="title">{{ pass.data['name'] }}</span>
				<span class="price">{{ getEventPassPrice( pass._id.$oid, selectedDate.year, selectedDate.month1, selectedDate.day ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}<em>/ea</em></span>
				<span class="button" (click)="addToOverlay( pass );">{{ didPickDate || pass.data['is_event_length'] ? strAddToCart : strSelectDate }} <i class="fa-regular fa-arrow-right"></i></span>
				<span class="note" *ngIf="copyMap['addonLegal']">{{ copyMap['addonLegal'] }}</span>
			</div>
		</div>
	</section>
	<p class="notice" *ngIf="copyMap['passTerms']">{{ copyMap['passTerms'] }}</p>
</div>
<div
	[ngClass]="{
		'color-band': true,
		'none': singleUsePasses.length < 1
	}"
>
	<div class="wrapper">
		<span class="section-header">Admission</span>
		<span class="section-subheader meta">{{ copyMap['ticketTerms'] }}</span>
		<section id="listing-daily" class="list-tickets grid">
			<div class="ticket type-pass is-single-use"
				*ngFor="let pass of singleUsePasses;"
			>
				<div class="ticket-header">
					<span class="title">{{ pass.data['name'] }}</span>
					<span class="meta">Starting At</span>
					<span class="price">{{ getEventPassPrice( pass._id.$oid, selectedDate.year, selectedDate.month1, selectedDate.day ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}<em>/ea</em></span>
					<span class="button" (click)="addToOverlay( pass );">{{ didPickDate || pass.data['is_event_length'] ? strAddToCart : strSelectDate }} <i class="fa-regular fa-arrow-right"></i></span>
				</div>
			</div>
		</section>
	</div>
</div>
<div class="wrapper" *ngIf="addOnPasses.length > 0">
	<span class="section-header">Add-Ons</span>
	<span class="section-subheader meta">{{ copyMap['addonTerms'] }}</span>
	<section id="listing-addons" class="list-tickets grid">
		<div class="ticket type-pass is-addon"
			*ngFor="let pass of addOnPasses;"
		>
			<div class="ticket-header">
				<span class="title">{{ pass.data['name'] }}</span>
				<span class="price">{{ getEventPassPrice( pass._id.$oid, selectedDate.year, selectedDate.month1, selectedDate.day ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}<em>/ea</em></span>
				<span class="button" (click)="addToOverlay( pass );">{{ didPickDate || pass.data['is_event_length'] ? strAddToCart : strSelectDate }} <i class="fa-regular fa-arrow-right"></i></span>
				<span class="note">{{ copyMap['addonLegal'] }}</span>
			</div>
		</div>
	</section>
</div>
<div class="wrapper" *ngIf="merchandiseNames.length > 0">
	<span class="section-header">Merchandise</span>
	<span class="section-subheader meta">{{ copyMap['merchandise'] }}</span>
	<section id="listing-merch" class="list-tickets grid">
		<ng-container *ngFor="let productName of merchandiseNames;">
			<ng-container *ngFor="let productColor of merchandise[productName].colors;">
				<div class="ticket type-pass is-merch">
					<div class="ticket-header">
						<span class="title">{{ productName }}, {{ productColor }}</span>
						<img [alt]="productName" [src]="merchandise[productName].byColor[productColor].photo" />
						<!-- TODO: show price based upon selected color and size. but now we need to select a color and size. -->
						<span class="price">{{ getEventPassPrice(
							merchandise[productName].byColor[productColor].bySize[
								merchandise[productName].byColor[productColor].selectedSize
							]._id.$oid,
							selectedDate.year, selectedDate.month1, selectedDate.day
						) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}</span>
						<span class="button" (click)="addToOverlay( merchandise[productName].byColor[productColor].bySize[
							merchandise[productName].byColor[productColor].selectedSize
						] )">{{ didPickDate || merchandise[productName].byColor[productColor].bySize[
							merchandise[productName].byColor[productColor].selectedSize
						].data['is_event_length'] ? strAddToCart : strSelectDate }} <i class="fa-regular fa-arrow-right"></i></span>
						<span class="note">{{ '' }}</span>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</section>
</div>
<div
	[ngClass]="{
		'add-overlay': true,
		'active': showAddOverlay
	}"
>
	<div
		[ngClass]="{
			'modal': true,
			'no-date': overlaySelectedProduct && overlaySelectedProduct.data['is_event_length']
		}"
	>
		<div class="modal-body columned">
			<div class="column column-wide">
				<app-calendar-embed
					[largeUI]="true"
					[calendarsToShow]="1"
					[selectedYYYYMMDD]="selectedDate"
					[boundaryDateBegin]="calendarRangeStart"
					[boundaryDateEnd]="calendarRangeEnd"
					[passForSale]="overlaySelectedProduct"
					[passAvailability]="productAvailability"
					(dayChanged)="daySelected( $event );"
				></app-calendar-embed>
			</div>
			<div class="column" *ngIf="overlaySelectedProduct">
				<ul class="ticket-options">
					<ng-container *ngIf="overlaySelectedMerch">
						<li *ngFor="let size of merchandise[overlaySelectedMerch.data.name].byColor[overlaySelectedMerch.data.color].sizes;">
							<span class="details">
								<b>{{ overlaySelectedMerch.data['name'] + ", " +  overlaySelectedMerch.data.color + ", " + size }}</b>
								<span class="meta">{{ getEventPassPrice( merchandise[overlaySelectedMerch.data.name].byColor[overlaySelectedMerch.data.color].bySize[size]._id.$oid, selectedDate.year, selectedDate.month1, selectedDate.day ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}&nbsp;Each</span>
							</span>
							<span class="controls">
								<span class="control" (click)="overlayChangeQty( merchandise[overlaySelectedMerch.data.name].byColor[overlaySelectedMerch.data.color].bySize[size]._id.$oid, -1 );">-</span>
								<span class="qty">{{ overlayTicketSelection[ merchandise[overlaySelectedMerch.data.name].byColor[overlaySelectedMerch.data.color].bySize[size]._id.$oid ] }}</span>
								<span class="control" (click)="overlayChangeQty( merchandise[overlaySelectedMerch.data.name].byColor[overlaySelectedMerch.data.color].bySize[size]._id.$oid, 1 );">+</span>
							</span>
						</li>
					</ng-container>
					<li *ngIf="overlaySelectedPass">
						<span class="details">
							<b>{{ overlaySelectedPass.data['name'] }}</b>
							<span class="meta">{{ getEventPassPrice( overlaySelectedPass._id.$oid, selectedDate.year, selectedDate.month1, selectedDate.day ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}&nbsp;Each</span>
						</span>
						<span class="controls">
							<span class="control" (click)="overlayChangeQty( overlaySelectedPass._id.$oid, -1 );">-</span>
							<span class="qty">{{ overlayTicketSelection[ overlaySelectedPass._id.$oid ] }}</span>
							<span class="control" (click)="overlayChangeQty( overlaySelectedPass._id.$oid, 1 );">+</span>
						</span>
					</li>
					<li class="divider meta no-mobile- not-grid" *ngIf="(addOnPasses.length > 0 || merchAddOns.length > 0) && !overlaySelectedProduct.data.is_addon">
						<span>Optional Add-Ons</span>
						<ul class="optional-add-ons">
							<li
								*ngFor="let addOn of addOnPasses;"
								[ngClass]="{
									'addon': true,
									'no-mobile-' : true,
									'none': overlaySelectedProduct!.data.is_addon || addOn._id.$oid === overlaySelectedProduct!._id.$oid
								}"
							>
								<span class="details">
									<b>{{ addOn.data.name }}</b>
									<span class="meta">{{ getEventPassPrice( addOn._id.$oid, selectedDate.year, selectedDate.month1, selectedDate.day ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}</span>
								</span>
								<span class="controls">
									<span class="control" (click)="overlayChangeQty( addOn._id.$oid, -1 );">-</span>
									<span class="qty">{{ overlayTicketSelection[ addOn._id.$oid ] }}</span>
									<span class="control" (click)="overlayChangeQty( addOn._id.$oid, 1 );">+</span>
								</span>
							</li>
							<ng-container *ngIf="merchandiseNames.length > 0">
								<li
									*ngFor="let addOn of merchAddOns;"
									[ngClass]="{
										'addon': true,
										'no-mobile-' : true,
										'none': overlaySelectedProduct!.data.is_addon || addOn._id.$oid === overlaySelectedProduct!._id.$oid
									}"
								>
									<span class="details">
										<b>{{ addOn.data.name + ", " + addOn.data.color + ", " + addOn.data.size }}</b>
										<span class="meta">{{ getEventPassPrice( addOn._id.$oid, selectedDate.year, selectedDate.month1, selectedDate.day ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}</span>
									</span>
									<span class="controls">
										<span class="control" (click)="overlayChangeQty( addOn._id.$oid, -1 );">-</span>
										<span class="qty">{{ overlayTicketSelection[ addOn._id.$oid ] }}</span>
										<span class="control" (click)="overlayChangeQty( addOn._id.$oid, 1 );">+</span>
									</span>
								</li>
							</ng-container>
						</ul>
					</li>
				</ul>
				<span class="anchored ticket-subtotal">
					<b>Total</b>
					<span>{{ grandTotal | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}</span>
				</span>
			</div>
		</div>
		<div class="modal-footer">
			<span class="button grey" (click)="overlayBack( true );">Back</span>
			<span
				[ngClass]="{
					'button': true,
					'primary': true,
					'disabled': !didPickQty
				}"
				(click)="didPickQty && overlayAddToCart();">Add to Cart<i class="fa-solid fa-plus"></i></span>
		</div>
	</div>
</div>
