import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {
	InterfaceAnyObject,
	InterfaceAppContext,
	InterfaceCartData,
	InterfaceHTTPGateway,
	InterfaceOWAPIEventProductAvailabilityResponse,
	InterfaceOWAPIGetEventPassActionResponse,
	InterfaceOWAPIGetEventProductsActionResponse,
	InterfaceOWAPIGetMemberEntitlementsActionResponse,
	InterfaceOWAPIOrderItems,
	InterfaceOWAPIOrderPaymentInfo
} from '../../../../interfaces/interfaces';
interface InterfaceSubmitOrderFlagsIdsParam {
	cashless_spending: boolean;
	profile_id: string; // the profile ID of the customer placing the order.
	promo_code: string | undefined;
	captcha_token: string;
	kvp?: InterfaceAnyObject; // arbitrary data container
}
// ===== Services ===== //
import {ServiceAuthentication} from '../../../authentication';
import {ServiceOWGateway} from '../ow-gateway';
import {ServiceRegex} from '../../../regex';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIWorkspaceActions {
	protected readonly routePrefix: string = 'workspace/';
	//
	public constructor(
		private readonly auth: ServiceAuthentication,
		private readonly gateway: ServiceOWGateway
	) {
		//
	}

	public runActionByID( appContext: InterfaceAppContext, actionID: string, payload: InterfaceAnyObject ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/' + actionID, JSON.stringify( payload ) );
	}

	public getUserPublicInfo( appContext: InterfaceAppContext, profileIDs: string[] ): Observable<InterfaceHTTPGateway> {
		if ( profileIDs.length > 0 ) {
			return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/5f518939e7783ee90d2cb36e', JSON.stringify( {
				profile_ids: profileIDs // if you don't send this in, it grabs all users.
			} ) );
		}
		return this.gateway.requestDenied();
	}

	public getSeasonPassAvailabilityFromDateRange( appContext: InterfaceAppContext, groupID: string, startDate: Date, endDate: Date, location?: any ): Observable<InterfaceHTTPGateway> {
		// this api action is giving HTTP 500s
		// date format is YYYY-MM-DD
		// this fn is used to find out the users reserved-dates for SPH members. they can have up to 4 dates (each fam member can have 4 dates, or 5, i forget)
		let strStartDate: string = startDate.getFullYear() + '-' + String( '0' + ( startDate.getMonth() + 1 ) ).slice( -2 ) + '-' + String( '0' + startDate.getDate() ).slice( -2 );
		let strEndDate: string = endDate.getFullYear() + '-' + String( '0' + ( endDate.getMonth() + 1 ) ).slice( -2 ) + '-' + String( '0' + endDate.getDate() ).slice( -2 );
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/629e5e5ae32a107779d1da3b', JSON.stringify( {
			group_id: groupID,
			start_date: strStartDate,
			end_date: strEndDate
			// location: undefined
		} ) );
	}

	public assignSeasonPassMembersToGroupEvent( appContext: InterfaceAppContext, groupID: string, date: Date, memberIDs: string[], location?: undefined ): Observable<InterfaceHTTPGateway> {
		// date format is YYYY-MM-DD
		let strDate: string = date.getFullYear() + '-' + String( '0' + ( date.getMonth() + 1 ) ).slice( -2 ) + '-' + String( '0' + date.getDate() ).slice( -2 );
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/629fc649e32a10777918b06d', JSON.stringify( {
			group_id: groupID,
			date: strDate,
			members: memberIDs
			// location: undefined
		} ) );
	}

	public cancelSeasonPassGroupEvent( appContext: InterfaceAppContext, groupID: string, reason: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62a524a3e32a1077793748c6', JSON.stringify( {
			reservation_id: groupID,
			reason: reason // Are you sure you would like to cancel this reservation? Please provide a reason for the cancellation below:
		} ) );
	}

	public getDailyAdmissionAvailabilityFromDateRangeV2( appContext: InterfaceAppContext, docletIDs: string[], startDate: Date, endDate: Date, eventID: string, owRole?: string ): Observable<InterfaceHTTPGateway<InterfaceOWAPIEventProductAvailabilityResponse>> {
		// capacity is determined by the (Capacity Settings) doclet, that is linked to an (Event Pass) + the (Venue Event)
		// since passes for sale can be re-used, meaning the parent is the pass, and the child is the event,
		// ...you'll now need to pass along the event ID, per pass. or only make the array of doclet IDs belong to one event. etc...
		let strStartDate: string = startDate.getFullYear() + '-' + String( '0' + ( startDate.getMonth() + 1 ) ).slice( -2 ) + '-' + String( '0' + startDate.getDate() ).slice( -2 );
		let strEndDate: string = endDate.getFullYear() + '-' + String( '0' + ( endDate.getMonth() + 1 ) ).slice( -2 ) + '-' + String( '0' + endDate.getDate() ).slice( -2 );
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62aa7829e32a1077798236aa', JSON.stringify( { // RequestDailyAvailability
			doclet_ids: docletIDs,
			event_id: eventID,
			start_date: strStartDate,
			end_date: strEndDate,
			ow_role_id: owRole // the _id for 'Web' or 'POS'
		} ), true ); // fetches passes by category ID 6699b49b8a01791304eace67
	}

	public getDailyMerchandiseAvailabilityFromDateRange( appContext: InterfaceAppContext, docletIDs: string[], startDate: Date, endDate: Date, eventID: string, owRole?: string ): Observable<InterfaceHTTPGateway<InterfaceOWAPIEventProductAvailabilityResponse>> {
		let strStartDate: string = startDate.getFullYear() + '-' + String( '0' + ( startDate.getMonth() + 1 ) ).slice( -2 ) + '-' + String( '0' + startDate.getDate() ).slice( -2 );
		let strEndDate: string = endDate.getFullYear() + '-' + String( '0' + ( endDate.getMonth() + 1 ) ).slice( -2 ) + '-' + String( '0' + endDate.getDate() ).slice( -2 );
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62aa7829e32a1077798236aa', JSON.stringify( { // RequestDailyAvailability
			doclet_ids: docletIDs,
			event_id: eventID,
			start_date: strStartDate,
			end_date: strEndDate,
			ow_role_id: owRole // the _id for 'Web' or 'POS'
		} ), true ); // fetches merchandise by category ID 67cb3c76b95cc4e7b82ae593
	}

	public getDailyAdmissionAvailabilityFromDateRange( appContext: InterfaceAppContext, startDate: Date, endDate: Date, owRole?: string ): Observable<InterfaceHTTPGateway> {
		console.trace( 'New function params and new interface outputs exist. You need to update your code.' );
		return this.gateway.requestDenied();
		/*
		let strStartDate: string = startDate.getFullYear() + '-' + String( '0' + ( startDate.getMonth() + 1 ) ).slice( -2 ) + '-' + String( '0' + startDate.getDate() ).slice( -2 );
		let strEndDate: string = endDate.getFullYear() + '-' + String( '0' + ( endDate.getMonth() + 1 ) ).slice( -2 ) + '-' + String( '0' + endDate.getDate() ).slice( -2 );
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62aa7829e32a1077798236aa', JSON.stringify( {
			start_date: strStartDate,
			end_date: strEndDate,
			ow_role_id: owRole // the _id for 'Web' or 'POS'
		} ), true );
		*/
	}

	public submitCart( appContext: InterfaceAppContext, profileID: string, data: any ): Observable<InterfaceHTTPGateway> {
		// billing addr: street1, unit, city, state, zip.
		// name on card, card #, cvv, exp date.
		// items to purchase and what days it belongs on.
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62aab993e32a1077798b0b90', JSON.stringify( {
			/*
			data: {
				date: YYYY-MM-DD,
				items: [
					parking?: template_id? doclet_id?
					cabana?: template_id? doclet_id?
					{
						ticket_type: doclet_id,
						first_name: string,
						last_name: string,
					}
				]
			}
			*/
		} ), true );
	}

	public reserveTicket( // 6 params
		appContext: InterfaceAppContext,
		eventID: string,
		passDocletID: string,
		locationID: string | null,
		roleID: string,
		date: Date | InterfaceCartData['visitDate'] | { eventID: string; } | 'any' | string | null
	): Observable<InterfaceHTTPGateway> { // Reserve Item
		// the date param can be a JS Date, the YYYY-MM-DD string as as a bunch of ints, or the value 'any' for an any-day, or the eventID if it's an event-length ticket.
		let dateRequested: string | null = null;
		if ( date instanceof Date ) {
			dateRequested = String( date.getFullYear() ) + '-' + String( '00' + (date.getMonth() + 1) ).slice( -2 ) + '-' + String( '00' + date.getDate() ).slice( -2 );
		} else if ( typeof date === 'string' ) {
			if ( ServiceRegex.YYYYMMDDExp.test( date ) ) {
				dateRequested = date;
			} else if ( date === 'any' ) {
				dateRequested = 'any';
			}
		} else {
			if ( date === null || 'eventID' in date ) {
				dateRequested = null; // the event-length dates should now be NULL.
			} else if ( 'year' in date && 'month1' in date && 'day' in date ) {
				dateRequested = String( '0000' + date.year ).slice( -4 ) + '-' + String( '00' + date.month1 ).slice( -2 ) + '-' + String( '00' + date.day ).slice( -2 );
			}
		}
		if ( dateRequested === '' ) {
			console.trace( 'Invalid date format while trying to reserve a ticket', date );
			return this.gateway.requestDenied();
		}
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62ac370ce32a107779bd3b8e', JSON.stringify( {
			data: {
				date: dateRequested,
				doclet_id: passDocletID,
				event_id: eventID,
				location_id: locationID,
				ow_role_id: roleID // Web
			}
		} ), true );
	}

	public removeTicketReservation( appContext: InterfaceAppContext, reservationIDs: string[] ): Observable<InterfaceHTTPGateway> {
		// this released the ticket locks (the held tickets by ID) so that others can buy them.
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62ac371de32a107779bd3d9c', JSON.stringify( {
			data: {
				items: reservationIDs
			}
		} ), true );
	}

	public submitOrder( appContext: InterfaceAppContext, orderedItems: InterfaceOWAPIOrderItems[], paymentInfo: InterfaceOWAPIOrderPaymentInfo, flagsTokensIDs: InterfaceSubmitOrderFlagsIdsParam ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62aab993e32a1077798b0b90', JSON.stringify( {
			data: {
				cashless_spending: flagsTokensIDs.cashless_spending,
				captcha: flagsTokensIDs.captcha_token,
				items: orderedItems, // update the cart service, add the event ID to each ticket. group them up by date/event/etc.
				kvp: flagsTokensIDs.kvp && Object.keys( flagsTokensIDs.kvp ).length > 0 ? flagsTokensIDs.kvp : undefined,
				payment_info: paymentInfo,
				profile_id: flagsTokensIDs.profile_id,
				promo_code: flagsTokensIDs.promo_code,
			}
		} ), true );
	}

	public recordResourceUse( appContext: InterfaceAppContext, resource: string, type: string, context: InterfaceAnyObject ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62aef2c2e32a10777944bee8', JSON.stringify( {
			context: context,
			realm_id: appContext.realmID,
			resource: resource,
			type: type
		} ), true );
	}

	public checkPromoCode( appContext: InterfaceAppContext, cartItems: InterfaceOWAPIOrderItems[], promoCode: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62efe9d6e32a107779fb8305', JSON.stringify( {
			data: {
				items: cartItems,
				promo_code: promoCode
			}
		} ), true );
	}

	public toggleCashlessSpending( appContext: InterfaceAppContext, customerProfileID: string, toggle: boolean, targetRealmID: string ): Observable<InterfaceHTTPGateway> {
		// apiResponse.data.data.items is an array of InterfaceOWDoclet -- same apiResponse as fetchDoclets
		const profileID: string | null = this.auth.getProfileID();
		if ( !profileID ) {
			return this.gateway.requestDenied();
		}
		// to fetch the state, you need to go acquire the account doclet. don't use this to fetch the state.
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62ed7c60e32a1077798d6c48', JSON.stringify( {
			data: {
				profile_id: customerProfileID,
				target_realm_id: targetRealmID,
				cashless_spending: toggle
			}
		} ) );
	}

	public getSavedPaymentMethod( appContext: InterfaceAppContext, customerProfileID: string, targetRealmID: string ): Observable<InterfaceHTTPGateway> {
		const profileID: string | null = this.auth.getProfileID();
		if ( !profileID ) {
			return this.gateway.requestDenied();
		}
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62fe9ec8e32a1077792eada6', JSON.stringify( {
			data: {
				profile_id: customerProfileID,
				target_realm_id: targetRealmID
			}
		} ) );
	}

	public savePaymentMethod( appContext: InterfaceAppContext, customerProfileID: string, ccNum: string, ccCVV: string, ccExpMM: string, ccExpYYYY: string, ccZip: string, targetRealmID: string ): Observable<InterfaceHTTPGateway> {
		const profileID: string | null = this.auth.getProfileID();
		if ( !profileID ) {
			return this.gateway.requestDenied();
		}
		// TODO: send the parent-app's workspace ID (the VP workspace)
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62fe9ef4e32a1077792ec119', JSON.stringify( {
			data: {
				profile_id: customerProfileID,
				target_realm_id: targetRealmID,
				payment_info: {
					billing_zip: ccZip, // 5 digits
					card_number: ccNum, // 15 to 16 digits
					card_exp_month: ccExpMM, // 01 to 12
					card_exp_year: ccExpYYYY, // 4 digit year
					card_cvv: ccCVV // 3 to 4 digits
				}
			}
		} ) );
	}

	public getReservedSeating( appContext: InterfaceAppContext, dateYYYYMMDD: string, roleID: string ): Observable<InterfaceHTTPGateway> {
		// returns seating locations available, by ticket ID.
		// { <ticket_id> : [ seat1, seat2, seat4, seat8, ... ] }
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/644e21a33796540a7ea6927c', JSON.stringify( {
			data: {
				date: dateYYYYMMDD,
				ow_role_id: roleID
			}
		} ), true ); // see: InterfaceOWDailyReservedSeating
	}

	public getEventPasses( appContext: InterfaceAppContext, eventID: string, roleID: string ): Observable<InterfaceHTTPGateway<InterfaceOWAPIGetEventPassActionResponse>> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/6699b2ad493a9d4cbfaf707b', JSON.stringify( {
			data: {
				doclet_id: eventID,
				ow_role_id: roleID
			}
		} ), true, {
			limit: 999
		} );
	}

	public getEventProducts( appContext: InterfaceAppContext, eventID: string, roleID: string ): Observable<InterfaceHTTPGateway<InterfaceOWAPIGetEventProductsActionResponse>> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/67cb69eb97b1482798fb3346', JSON.stringify( {
			data: {
				doclet_id: eventID,
				ow_role_id: roleID
			}
		} ), true, {
			limit: 999
		} );
	}

	public getEventPassAvailability( appContext: InterfaceAppContext, eventID: string, roleID: string, arrPassIDs: string[], locationID?: string ): Observable<InterfaceHTTPGateway> {
		// this is for something LIKE season passes.
		// locationID is the seating location, etc.
		// this fetches availability by passID, for an event.
		// TODO: investigate this. do not use this.
		console.trace( 'WARNING - DO NOT USE THIS?' ); if ( Math.random() < 9999 ) { throw "oops?"; }
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/6726c88b4f940b07ef0878fa', JSON.stringify( {
			doclet_ids: arrPassIDs,
			event_id: eventID,
			ow_role_id: roleID,
			location: locationID
		} ), true, {
			limit: 999
		} );
	}

	public getActions( workspaceID: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( workspaceID ) + '/actions' );
	}

	public getActionInfo( workspaceID: string, actionID: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( workspaceID ) + '/action/' + encodeURIComponent( actionID ) );
	}

	public getMemberEntitlements( appContext: InterfaceAppContext, purchaserConsumerID: string, eventID?: string ): Observable<InterfaceHTTPGateway<InterfaceOWAPIGetMemberEntitlementsActionResponse>> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/66daa20e7cc6041fc20accee', JSON.stringify( {
			data: {
				event_id: eventID,
				profile_id: purchaserConsumerID // used to be the purchaser ID, but the logic on server-side wanted the consumer ID of the purchaser.
			}
		} ) );
	}
}
