import {Component} from '@angular/core';
//
@Component( {
	selector: 'modal-cancel-reservation',
	templateUrl: './cancel-reservation.html',
	styleUrls: [
		'./cancel-reservation.less'
	]
} )
export class ModalCancelReservation {
	//
	public constructor() {
		//
	}
}
