import {Injectable} from '@angular/core';
// ===== Services ===== //
import {ServiceOWAPICoreWorkspace} from './core/workspace';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPICore {
	private routePrefix: string = 'ow/';
	//
	public constructor(
		public workspace: ServiceOWAPICoreWorkspace
	) {
		//
	}
}
