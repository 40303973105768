import {Injectable} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceAnyObject} from '../interfaces/interfaces';
//
@Injectable( {
	providedIn: 'root'
} )
export class TransformerOWAPI {
	//
	public constructor() {
		//
	}

	private static serializeFilterValue( inputVal: any ): string | false {
		let output: string | false = false;
		try {
			switch ( typeof inputVal ) { // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof
				case 'bigint': {
					// missing support for basic numbers, as well as big numbers.
					// value defaults to a plain string...
					output = inputVal.toString(); // .toString() removes the trailing 'n' off of a BigInt.
					break;
				}
				case 'boolean': {
					output = inputVal ? '{b}true' : '{b}false';
					break;
				}
				case 'function': {
					// nope
					break;
				}
				case 'number': {
					// missing support for basic numbers.
					// value defaults to a plain string...
					output = String( inputVal ); // warning ~ may be represented as a whole number, floats, exponents, -Infinity, etc...
					break;
				}
				case 'object': {
					if ( Array.isArray( inputVal ) ) {
						output = '['
						const arrStr: string[] = [];
						for ( let x: number = 0; x < inputVal.length; ++x ) {
							const subOutput: string | false = TransformerOWAPI.serializeFilterValue( inputVal[x] ); // Recursion //
							if ( subOutput !== false ) {
								arrStr.push( subOutput );
							}
						}
						output += arrStr.join( ',' ) + ']';
					} else {
						const subKeys: string[] = Object.keys( inputVal );
						if ( subKeys.length === 1 && subKeys[0] === '$oid' ) { // MongoId()
							output = '{id}' + inputVal['$oid'];
						} else {
							output = TransformerOWAPI.createFilter( inputVal ); // Recursion //
						}
					}
					break;
				}
				case 'string': {
					output = inputVal;
					break;
				}
				case 'symbol': {
					// nope
					break;
				}
				case 'undefined': {
					// nope?
					break;
				}
				default: { // something not found in the spec?
					console.log( 'New data type discovered:', typeof inputVal );
					break;
				}
			}
		} catch ( fail ) {
			console.log( 'Failed to transform a value (', inputVal, ') into an acceptable string.' );
		}
		return output;
	}

	public static createFilter( kvpData: InterfaceAnyObject ): string {
		// OW API filter needs to be in the form of "key:value"
		// arrays are as follows: "key:[val1,val2,val3]"
		// values which are to be object IDs "_id" needs {id} in front of the value. "key:{id}value"
		let filterSegments: string[] = [];
		const keys: string[] = Object.keys( kvpData );
		for ( let x: number = 0; x < keys.length; ++x ) {
			const k: string = keys[x];
			const v: string | false = TransformerOWAPI.serializeFilterValue( kvpData[k] ); // Recursion //
			if ( v !== false ) {
				filterSegments.push( k + ':' + v );
			}
		}
		return filterSegments.join( '|' ); // keyA:valA|keyB:[val1,val2,val3]|keyC:{id}valC
	}
}
