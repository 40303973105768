<app-masthead
	[mastHeading]="mastHeading"
	[navMenuItems]="navMenuItems"
></app-masthead>
<div class="wrapper">
	<section class="card">
		<div class="column">
			<h3>My Family</h3>
			<ul class="family-list">
				<li class="family-member" *ngIf="haveUserInfo">
					<div class="family-member-photo">
						<img alt="" *ngIf="photo" [src]="photo" />
						<span class="no-photo" *ngIf="!photo">{{ firstName + ' ' + lastName | initials }}</span>
					</div>
					<div class="family-member-details">
						<b>{{ firstName + ' ' + lastName }}</b>
						<span>Family Organizer</span>
					</div>
				</li>
				<li class="family-member expanded">
					<div class="family-member-photo">
						<span class="no-photo">JD</span>
					</div>
					<div class="family-member-details">
						<b>John Doe</b>
						<span>Adult</span>
					</div>
					<i class="accordion-toggle fa-solid fa-chevron-down"></i>
				</li>
				<li class="family-member-setting">
					<div class="setting-name">
						<b>Cashless Spending</b>
						<span>Allowed</span>
					</div>
					<ui-toggle-box
						[checked]="cashlessSpending"
					></ui-toggle-box>
				</li>
				<li class="family-member-setting">
					<div class="setting-name">
						<b>Daily Spending Limit</b>
						<span>$50.00 USD</span>
					</div>
					<span class="button ghost">Edit</span>
				</li>
				<li class="family-member">
					<div class="family-member-photo">
						<span class="no-photo">JD</span>
					</div>
					<div class="family-member-details">
						<b>Jane Doe</b>
						<span>Child</span>
					</div>
					<i class="accordion-toggle fa-solid fa-chevron-right"></i>
				</li>
				<li class="family-actions">
					<span class="button black ghost">Add Child</span>
					<span class="button black">Invite Adult</span>
				</li>
			</ul>
		</div>
	</section>
</div>
