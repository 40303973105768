// ===== Modules ===== //
import {NgModule} from '@angular/core';
// ===== Services - Core ===== //
import {ServiceAppEvents} from './app-events';
import {ServiceAuthentication} from './authentication';
import {ServiceCart} from './cart';
import {ServiceNavigate} from './navigate';
import {ServiceNotifications} from './notifications';
import {ServiceOWAPI} from './ow-api';
import {ServiceRegex} from './regex';
import {ServiceSocketIO} from './socket-io';
import {ServiceSorting} from './sorting';
import {ServiceWorkspaces} from './workspaces';
// ===== Services - OW API ===== //
import {ServiceOWAPIAccount} from './api/ow/account';
import {ServiceOWAPIAccountAuth} from './api/ow/accounts/auth';
import {ServiceOWAPIAccountProfile} from './api/ow/accounts/profile';
import {ServiceOWAPIAccountRegistration} from './api/ow/accounts/registration';
import {ServiceOWAPIAssets} from './api/ow/assets';
import {ServiceOWAPIAssetsFiles} from './api/ow/assets/files';
import {ServiceOWAPIMessage} from './api/ow/message';
import {ServiceOWAPIMessageChannels} from './api/ow/messages/channels';
import {ServiceOWGateway} from './api/ow/ow-gateway';
import {ServiceOWAPISupport} from './api/ow/support';
import {ServiceOWAPIWorkspace} from './api/ow/workspace'
import {ServiceOWAPIWorkspaceActions} from './api/ow/workspace/actions';
import {ServiceOWAPIWorkspaceDoclets} from './api/ow/workspace/doclets';
import {ServiceOWAPIWorkspaceSettings} from './api/ow/workspace/settings';
import {ServiceOWAPIWorkspaceTasks} from './api/ow/workspace/tasks';
import {ServiceOWAPIWorkspaceTemplates} from './api/ow/workspace/templates';
import {ServiceOWAPIWorkspaceWorkspaces} from './api/ow/workspace/workspaces';
// ===== Services - POS ===== //
import {ServiceQRData} from './pos/scan-qr-code';
// ===== //
const services: any[] = [
	ServiceSocketIO,
	ServiceAppEvents,
	ServiceAuthentication,
	ServiceCart,
	ServiceNavigate,
	ServiceNotifications,
	ServiceOWAPI,
	ServiceOWAPIAccount,
	ServiceOWAPIAccountAuth,
	ServiceOWAPIAccountProfile,
	ServiceOWAPIAccountRegistration,
	ServiceOWAPIAssets,
	ServiceOWAPIAssetsFiles,
	ServiceOWAPIMessage,
	ServiceOWAPIMessageChannels,
	ServiceOWAPISupport,
	ServiceOWAPIWorkspace,
	ServiceOWAPIWorkspaceActions,
	ServiceOWAPIWorkspaceDoclets,
	ServiceOWAPIWorkspaceSettings,
	ServiceOWAPIWorkspaceTasks,
	ServiceOWAPIWorkspaceTemplates,
	ServiceOWAPIWorkspaceWorkspaces,
	ServiceOWGateway,
	ServiceQRData,
	ServiceRegex,
	ServiceSorting,
	ServiceWorkspaces
];
//
@NgModule( {
	declarations: [],
	exports: [],
	imports: [],
	providers: services // should leave this as a blank array, if you also provide a .forRoot() function that returns the same junk.
} )
export class ModuleServices {
	/*
	public static forRoot() {
		return { // this is the magic angular uses to ensure the module is treated like a Singleton...
			ngModule: ModuleServices,
			providers: services
		}
	}
	*/
}
