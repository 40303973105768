import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
// ===== Interfaces ===== //
import {
	InterfaceEventProductAvailability,
	InterfaceOWDoclet,
	InterfaceOWTemplateEventPass
} from '../../../../../../ow-framework/interfaces/interfaces';
interface InterfaceYYYYMM1DD {
	year: number;
	month1: number; // 1 though 12
	day: number; // 0 through 31.
}
interface InterfaceCalendarData {
	year: number;
	month1: number; // 1 to 12
	leadingBlanks: undefined[];
	daysInMonth: number[];
	trailingBlanks: undefined[];
	blockedDays: { [day: string]: true; };
	weeksInMonth: undefined[];
}
// ===== Transformers ===== //
import {TransformerDate} from '../../../../../../ow-framework/transformers/date';
import {TransformerEventPasses} from '../../../../../../ow-framework/transformers/event-passes';
//
let now: Date = new Date();
//
@Component( {
	selector: 'app-calendar-embed',
	templateUrl: './calendar-embed.html',
	styleUrls: [
		'./calendar-embed.less'
	]
} )
export class ComponentCalendarEmbed implements OnChanges, OnInit {
	public readonly thisYear: number = now.getFullYear(); // these x3 are only used on the HTML side to calculate the 'past' CSS class.
	public readonly thisMonth1: number = now.getMonth() + 1;
	public readonly thisDay: number = now.getDate();
	//
	@Input()
	public calendarsToShow: number = 2;
	//
	@Input()
	public largeUI: boolean = false;
	//
	public arrCalendarDisplayIdx: number[] = []; // just a junk array to control how many loops for an *ngFor.
	//
	@Input()
	public selectedYYYYMMDD: InterfaceYYYYMM1DD | string | undefined = undefined;
	//
	public selectedYear: number = now.getFullYear();
	public selectedMonth1: number = 1 + now.getMonth(); // 1 through 12
	public selectedDay: number = now.getDate(); // 1 through 31
	//
	@Input()
	public displayYear: number = new Date().getFullYear(); // basically it's which year the user is looking at on the calendar.
	//
	@Input()
	public boundaryDateBegin: InterfaceYYYYMM1DD | undefined = undefined;
	//
	@Input()
	public boundaryDateEnd: InterfaceYYYYMM1DD | undefined = undefined;
	//
	@Input()
	public blockedDates: string[] = []; // YYYY-MM-DD // the park is closed on certain days...
	//
	public calendarDatesBlocked: { [YYYYMMDD: string]: true; } = {}; // 20230804
	//
	@Input()
	public passForSale: InterfaceOWDoclet<InterfaceOWTemplateEventPass> | undefined = undefined; // the selected pass-props to use, when figuring out and displaying price tags per day.
	//
	@Input()
	public passAvailability: InterfaceEventProductAvailability | undefined = undefined;
	//
	@Output()
	public dayChanged: EventEmitter<InterfaceYYYYMM1DD> = new EventEmitter<InterfaceYYYYMM1DD>();
	//
	@Output()
	public monthChanged: EventEmitter<void> = new EventEmitter<void>();
	//
	public readonly monthLabels: string[] = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
	public calendarData: InterfaceCalendarData[] = [];

	private isIFaceYMD( inputVar: unknown ): inputVar is InterfaceYYYYMM1DD { // type narrowing using type predicates.
		return typeof inputVar === 'object' && inputVar !== null && 'year' in inputVar && 'month1' in inputVar && 'day' in inputVar;
	}

	public constructor() {
		//
	}

	private updateCalendar(): void {
		now = new Date();
		// this recalculates everything.
		// it shouldn't be used when simply showing the next/prev month.
		// showing the next/prev month should be moving through what this creates.
		this.calendarData = [];
		const startAt: InterfaceYYYYMM1DD = this.boundaryDateBegin ? this.boundaryDateBegin : {
			year: now.getFullYear(),
			month1: 1 + now.getMonth(),
			day: now.getDay()
		};
		const stopAt: InterfaceYYYYMM1DD = this.boundaryDateEnd ? this.boundaryDateEnd : {
			year: now.getFullYear(),
			month1: 1 + now.getMonth(),
			day: new Date( now.getFullYear(), now.getMonth() + 1, 0 ).getDate() // the 1st of the next month, except it becomes "last night".
		};
		for ( let YYYY: InterfaceCalendarData['year'] = startAt.year; YYYY <= stopAt.year; ++YYYY ) {
			let startMM1: InterfaceCalendarData['month1'] = YYYY === startAt.year ? startAt.month1 : 1; // begin on the first month of a year (January), or the month where the boundary begins.
			const stopMM1: InterfaceCalendarData['month1'] = YYYY === stopAt.year
				? stopAt.month1 // if we're only going for the same year, then stop at the month defined by stopAt
				: (startAt.year < stopAt.year ? 12 : stopAt.month1); // otherwise go all the way to month 12 unless it's the same year defined by stopAt.
			for ( ; startMM1 <= stopMM1; ++startMM1 ) {
				const leadingBlanks: InterfaceCalendarData['leadingBlanks'] = new Array( new Date( YYYY, startMM1 - 1, 1 ).getDay() ); // MM1 is 1 to 12 but new Date() wants 0 to 11.
				const daysInMonth: InterfaceCalendarData['daysInMonth'] = Array.from( { length: new Date( YYYY, startMM1, 0 ).getDate() } ).map( (_, idx: number): number => idx + 1 ); // [ 1, 2, 3, ..., 30, 31 ]
				const trailingBlanks: InterfaceCalendarData['trailingBlanks'] = new Array( Math.ceil( (leadingBlanks.length + daysInMonth.length) / 7 ) * 7 - leadingBlanks.length - daysInMonth.length );
				const blockedDates: InterfaceCalendarData['blockedDays'] = {};
				// ===== blocking past dates as needed. (out of bounds) ===== //
				if ( this.boundaryDateBegin ) {
					if ( YYYY < this.boundaryDateBegin.year || (YYYY === this.boundaryDateBegin.year && startMM1 < this.boundaryDateBegin.month1) ) {
						// if all days in the month (1-28/20/30) should be blocked... because it's last year or the current year buy the prior month(s).
						for ( let dim: number = 0; dim < daysInMonth.length; ) {
							blockedDates[ String( ++dim ) ] = true;
						}
						// otherwise we have to check on a per-day basis to calculate past-dates.
					} else if ( YYYY === this.boundaryDateBegin.year && startMM1 === this.boundaryDateBegin.month1 ) {
						for ( let day: number = 1; day < this.boundaryDateBegin.day; ++day ) {
							blockedDates[ String( day ) ] = true;
						}
					}
				}
				// ===== blocking future dates as needed. (out of bounds) ===== //
				if ( this.boundaryDateEnd ) {
					if ( YYYY > this.boundaryDateEnd.year || (YYYY === this.boundaryDateEnd.year && startMM1 > this.boundaryDateEnd.month1) ) {
						for ( let dayOfMonth: number = 0; dayOfMonth < daysInMonth.length; ) {
							blockedDates[ String( ++dayOfMonth ) ] = true;
						}
					} else if ( YYYY === this.boundaryDateEnd.year && startMM1 === this.boundaryDateEnd.month1 ) {
						for ( let dayOfMonth: number = 1; dayOfMonth <= daysInMonth.length; ++dayOfMonth ) {
							if ( dayOfMonth > this.boundaryDateEnd.day ) {
								blockedDates[ String( dayOfMonth ) ] = true;
							}
						}
					}
				}
				if ( Array.isArray( this.passForSale?.data.blocked_dates ) ) {
					for ( let bd: number = 0; bd < this.passForSale.data.blocked_dates.length; ++bd ) {
						const strArr: string[] = this.passForSale.data.blocked_dates[bd].split( /-/g );
						if ( strArr.length === 3 && String( YYYY ) === strArr[0] && String( '0' + startMM1 ).slice( -2 ) === strArr[1] ) {
							blockedDates[ String( Number.parseInt( strArr[2], 10 ) ) ] = true;
						}
					}
				}
				if ( Array.isArray( this.passForSale?.data.dates_valid ) ) {
					// grab all dates for this year + month.
					// make an array of days of the month? or..
					// and then remove the entries for which ones "dates_valid" hit upon.
					// for what's left, shove them all onto blocked_dates
					const potentialNewBlockedDays: {
						[day: string]: boolean; // day needs to be WITHOUT leading "0"
					} = {};
					daysInMonth.forEach( (dim: number): void => {
						potentialNewBlockedDays[ String( dim ) ] = true;
					} );
					for ( let dv: number = 0; dv < this.passForSale.data.dates_valid.length; ++dv ) {
						const strArr: string[] = this.passForSale.data.dates_valid[dv].split( /-/g );
						if ( strArr.length === 3 && String( YYYY ) === strArr[0] && String( '0' + startMM1 ).slice( -2 ) === strArr[1] ) {
							potentialNewBlockedDays[ String( Number.parseInt( strArr[2], 10 ) ) ] = false;
						}
					}
					for ( const dim of daysInMonth ) {
						if ( potentialNewBlockedDays[ String( dim ) ] ) { // valid dates turned these values FALSE, so only remaining "non valid" days remain.
							blockedDates[ String( dim ) ] = true;
						}
					}
				}
				this.calendarData.push( {
					year: YYYY,
					month1: startMM1,
					leadingBlanks: leadingBlanks,
					daysInMonth: daysInMonth,
					trailingBlanks: trailingBlanks,
					blockedDays: blockedDates,
					weeksInMonth: new Array( (leadingBlanks.length + daysInMonth.length + trailingBlanks.length) / 7 )
				} );
			}
		}
		console.log( 'pre-calculated calendar month data junk things and stuff', this.calendarData, this.boundaryDateEnd );
	}

	private parseYYYYMMDDToSelectedDate(): void {
		if ( typeof this.selectedYYYYMMDD === 'string' && /^\d\d\d\d-\d\d-\d\d$/.test( this.selectedYYYYMMDD ) ) {
			// YYYY-MM-DD where MM is 01 to 12
			const arrYYYYMMDD: string[] = this.selectedYYYYMMDD.split( /-/g );
			this.selectedYear = Number( arrYYYYMMDD[0] );
			this.selectedMonth1 = Number( arrYYYYMMDD[1] );
			this.selectedDay = Number( arrYYYYMMDD[2] );
		} else if ( this.selectedYYYYMMDD && this.isIFaceYMD( this.selectedYYYYMMDD ) ) {
			// is an object, carry over the props.
			this.selectedYear = this.selectedYYYYMMDD.year;
			this.selectedMonth1 = this.selectedYYYYMMDD.month1;
			this.selectedDay = this.selectedYYYYMMDD.day;
		} // else is not defined. stays defaulted to (now)
	}

	public ngOnChanges( changes: SimpleChanges ): void {
		// OnInit happens after initial changes occur. ngOnChanges runs first.
		// but the [values] passed into the comp are already accessible, even though ngOnChanges hasn't yet fired.
		let willUpdateCalendar: boolean = false;
		if ( 'selectedYYYYMMDD' in changes ) {
			this.parseYYYYMMDDToSelectedDate();
		}
		if ( 'blockedDates' in changes ) {
			this.calendarDatesBlocked = {};
			const rxYMD: RegExp = /^\d\d\d\d-\d\d-\d\d$/;
			for ( let x: number = 0; x < this.blockedDates.length; ++x ) {
				if ( rxYMD.test( this.blockedDates[x] ) ) {
					this.calendarDatesBlocked[ this.blockedDates[x] ] = true;
				}
			}
		}
		//
		if ( 'passForSale' in changes ) {
			const p4s: SimpleChange = changes['passForSale'];
			const oldDocletID: string | undefined = p4s.previousValue && 'doclet' in p4s.previousValue ? p4s.previousValue?.doclet?._id?.$oid : undefined;
			const newDocletID: string | undefined = p4s.currentValue && 'doclet' in p4s.currentValue ? p4s.currentValue?.doclet?._id?.$oid : undefined;
			if ( p4s.firstChange && !oldDocletID && !newDocletID ) {
				willUpdateCalendar = true;
			} else {
				if ( !newDocletID || (oldDocletID && newDocletID && oldDocletID !== newDocletID ) ) {
					// if !newDocletID => the selected pass properties was removed. is now a basic calendar...
					// if old !== new => the selected pass props were changed.
					willUpdateCalendar = true;
				}
			}
		}
		// must be after this.updateCalendar() because it uses the new length of this.calendarData.length
		if ( 'calendarsToShow' in changes ) {
			this.arrCalendarDisplayIdx = Array.from( { length: Math.min( this.calendarData.length, Math.max( 1, this.calendarsToShow ) ) } ).map( (_, idx: number): number => idx );
			willUpdateCalendar = true;
		}
		if ( willUpdateCalendar ) {
			this.updateCalendar();
		}
	}

	public ngOnInit(): void {
		// initial changes happen first, then OnInit is fired. see ngOnChanges
		this.parseYYYYMMDDToSelectedDate();
		//
		this.arrCalendarDisplayIdx = Array.from( { length: Math.min( this.calendarData.length, Math.max( 1, this.calendarsToShow ) ) } ).map( (_, idx: number): number => idx );
	}

	public selectDay( YYYY: number, MM1: number, DD: number ): void {
		// YYYY could just be the displayed year (this.displayYear)
		// MM1 could be (this.displayMonth1)
		// but it's possible multiple calendar panels are going to be showing...
		//
		// rather than always passing them in, the values can be taken from displayYear and displayMonth1.
		// or: ngFor="let y = calendarData[x].daysInMonth;"
		// (click)="selectDay( calendarMonthData.year, calendarMonthData.month1, y );"
		this.selectedYear = YYYY;
		this.selectedMonth1 = MM1;
		this.selectedDay = DD;
		//
		this.dayChanged.emit( {
			year: this.selectedYear,
			month1: this.selectedMonth1,
			day: this.selectedDay
		} );
	}

	// TODO: replace this with an ngOnChanges setup, or else this fn will be called millions of times on mouse-move.
	// basically, pre-calc the calendar cell's prices, once, and then make use of it inside the ngFor loop. instead of calling this fn() instead the loop.
	// if the availability object changes, or the passForSale changes, re-calc the cached setup, etc.
	public getEventPassPrice( year: number, month1: number, day: number ): number {
		if ( this.passForSale?._id ) {
			const d: Date = new Date();
			const strPurchaseDate: string = TransformerDate.strYYYYMMDDFromYMDInts( d.getFullYear(), 1 + d.getMonth(), d.getDate() );
			const strTargetDate: string = TransformerDate.strYYYYMMDDFromYMDInts( year, month1, day );
			const soldCount: number = this.passAvailability?.[strTargetDate]?.[this.passForSale._id.$oid]?.item_count ?? 0;
			return TransformerEventPasses.getEventPassPriceV2( this.passForSale, strPurchaseDate, strTargetDate, soldCount );
		}
		return 0;
	}
}
