<app-masthead
	[mastHeading]="mastHeading"
	[navMenuItems]="navMenuItems"
	[serialCode]="serialCode"
></app-masthead>
<div id="page-my-account" class="page wrapper">
	<div class="card columned">
		<div class="column form">
			<h3>About You</h3>
			<label>
				<span class="frame-label">First Name <b>*</b></span>
				<input type="text" autocomplete="off"
					[class.error]="userProfileErrors.first_name"
					[(ngModel)]="userProfileData.first_name"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateProfileData( 'first_name' );"
				/>
			</label>
			<label>
				<span class="frame-label">Last Name <b>*</b></span>
				<input type="text" autocomplete="off"
					[class.error]="userProfileErrors.last_name"
					[(ngModel)]="userProfileData.last_name"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateProfileData( 'last_name' );"
				/>
			</label>
			<label class="wide">
				<span class="frame-label">Email Address <b>*</b></span>
				<input type="text" autocomplete="off" readonly
					[class.error]="userProfileErrors.email"
					[(ngModel)]="email"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateProfileData( 'email' );"
				/>
			</label>
			<label>
				<span class="frame-label">Date of Birth <b>*</b></span>
				<input type="date" autocomplete="off"
					[class.error]="consumerErrors.dob"
					[(ngModel)]="consumerData.dob"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(blur)="validateConsumerData( 'dob' );"
					(input)="validateConsumerData( 'dob' );"
				/>
			</label>
			<label>
				<span class="frame-label">Phone <b>*</b></span>
				<input type="text" autocomplete="off"
					[class.error]="consumerErrors.phone"
					[(ngModel)]="consumerData.phone"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateConsumerData( 'phone' )"
				/>
			</label>
			<label class="wide cashless-spending">
				<span class="not-yet-a-frame-label">
					<span class="label-note">Cashless Spending</span>
					<span class="cashless-spending-note">Activate in venue cashless spending by safely storing your credit card. <span class="bold">No need to reach for your wallet or your smartphone</span>. Simply tap your venue issued wristband to make any purchase throughout the venue.</span>
				</span>
				<ui-toggle-box [checked]="cashlessSpending" (changed)="cashlessToggle( $event );"></ui-toggle-box>
			</label>
			<hr />
			<span class="button save"
				[ngClass]="{
					disabled: formHasErrors || !consumerDocletID,
					busy: formBusy
				}"
				(click)="updateAccount();"
			>Save Changes<i class="busy-icon fa-solid fa-spin fa-circle-notch"></i></span>
		</div>
		<div class="column">
			<h3>Photo ID</h3>
			<div class="photo-id">
				<div class="photo-id-wrapper">
					<image-cropper
						[ngStyle]="{
							display: usingImageCropper ? 'block' : 'none'
						}"
						[imageChangedEvent]="imageChangedEvent"
						(imageCropped)="imageCropperOnChange( $event )"
						(imageLoaded)="imageCropperOnImageLoad( $event )"
						(cropperReady)="cropperReady()"
						(loadImageFailed)="loadImageFailed()"
						[format]="'jpeg'"
						[maintainAspectRatio]="true"
						[aspectRatio]="16 / 16"
						[cropperMinWidth]="150"
						[cropperMinHeight]="150"
						[cropperMaxHeight]="1200"
						[cropperMaxWidth]="1200"
						[imageQuality]="95"
						[alignImage]="'center'"
						[backgroundColor]="'#FFFFFF'"
					></image-cropper>
					<span class="placeholder" *ngIf="!usingImageCropper && consumerData.photo.length < 1"><i class="fa-solid fa-user-astronaut"></i></span>
					<span class="profile-picture" *ngIf="!usingImageCropper && consumerData.photo.length > 0"><img alt="" [src]="consumerData.photo"/></span>
				</div>
				<label *ngIf="!usingImageCropper" class="button grey upload file-picker">
					<input accept="image/*" id="file-picker-profile-photo" type="file"
						(change)="inputTypeFileOnChange( $event )"/>
					<span class="button-display">Upload New Photo</span>
				</label>
				<label *ngIf="usingImageCropper" class="button green" (click)="setProfilePhoto();">
					<span class="button-display">Crop & Save Photo</span>
				</label>
				<label *ngIf="usingImageCropper" class="button text-only" (click)="clearImageCropper();">
					<span class="button-display text-only">Cancel Upload</span>
				</label>
			</div>
		</div>
	</div>
</div>
