<span class="vertical-nav">
	<span class="vertical-nav-segment">
		<span class="vertical-nav-item-wrapper"
			*ngFor="let entry of entries; index as x"
			[class.active]="activeIndex === x"
			(click)="itemSelected( x );"
		>
			<i [ngClass]="entry['fa-icon'] ? 'fal ' + entry['fa-icon'] : {}"></i>
			<span class="vertical-nav-item">{{ entry.text }}</span>
		</span>
	</span>
</span>
