import {Pipe, PipeTransform} from '@angular/core';
// ===== Interfaces ===== //
import {} from '../interfaces/interfaces';
// ===== Transformers ===== //
//
@Pipe( {
	name: 'passPriceTime',
	pure: true
} )
export class PipePassPriceTime implements PipeTransform {
	// passDoclet.data['price'] | passPriceTime : '2023-06-06'
	public transform( transformedDataUsedToBeHereButIsNowInvalid: any | undefined, selectedYYYYMMDD1?: string ): string {
		console.trace( 'Obsolete use of the passPriceTime pipe.' ); // need to update the transformer.
		return '';
		// return TransformerEventTicket.getTicketTimeDisplayFromPriceType( ticketPricing, selectedYYYYMMDD1 );
	}
}
