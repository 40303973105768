<div class="wrapper">
	<div class="checkout-wrapper">
		<div class="column col-1">
			<h2>Order Summary</h2>
			<p>Review your order &amp; enter attendee details below.</p>
			<ul class="product-list">
				<li class="cart-park-visit-details"
					*ngFor="let lineItem of productListing; let lineItemIdx = index;"
				>
					<div class="item">
						<div class="quantity">{{ lineItem.passProps.length }}</div>
						<div class="details">
							<b>{{ lineItem.passDisplayName }}</b>
							<span>{{ lineItem.strDisplayDate }}&nbsp;{{ lineItem.strDisplayTime }}</span>
							<span
								*ngIf="(lineItem.locationID?.length ?? 0) > 0">&#35;{{ lineItem.locationID?.join(', ') ?? '' }}</span>
						</div>
						<div class="price not-discounted"
							*ngIf="!lineItem.isDiscounted">{{ lineItem.lineItemSubTotal | currency : 'USD' }}
						</div>
						<div class="price with-discount" *ngIf="lineItem.isDiscounted">
							<span class="discount-applied">{{ lineItem.lineItemSubTotal | currency : 'USD' }}</span>
							<span class="discount-amount">Discounted<span
								style="display: none;">&nbsp;{{ lineItem.discountAmount | currency : 'USD' }}</span></span>
						</div>
					</div>
					<ul class="extras adult-extras"
						*ngIf="passIDToTicketProps[ lineItem.passID ].dataFirstLastName || passIDToTicketProps[ lineItem.passID ].dataDOB"
					>
						<li class="header">
							<span class="spacing-fix"></span>
							<span class="name-header" *ngIf="passIDToTicketProps[ lineItem.passID ].dataFirstLastName">Name on Ticket</span>
							<!-- <span *ngIf="passIDToTicketProps[ lineItem.passID ].dataFirstLastName">&nbsp;</span> -->
							<span *ngIf="passIDToTicketProps[ lineItem.passID ].dataDOB">Date of Birth</span>
						</li>
						<li class="row"
							*ngFor="let passProp of lineItem.passProps; let rowIdx = index;"
						>
							<span class="spacing-fix"></span>
							<label>
								<span class="label-text">First Name</span>
								<input type="text" placeholder="First"
									*ngIf="passIDToTicketProps[ lineItem.passID ].dataFirstLastName"
									[name]="'first-name-' + lineItemIdx + '-' + rowIdx"
									[ngClass]="{
										'error': passProp.errors.first_name && !passIDToTicketProps[ lineItem.passID ].flagsFirstLastIsOptional,
										'firstname': true
									}"
									[(ngModel)]="passProp.first_name"
									[ngModelOptions]="{ updateOn: 'blur' }"
									(blur)="!passIDToTicketProps[ lineItem.passID ].flagsFirstLastIsOptional && validateDisplayItem( passProp, 'first_name' );"
									(focus)="tmpTicketErrors = false;"
								/>
							</label>
							<label class="lastname-label">
								<span class="label-text">Last Name</span>
								<input type="text" placeholder="Last"
									*ngIf="passIDToTicketProps[ lineItem.passID ].dataFirstLastName"
									[name]="'last-name-' + lineItemIdx + '-' + rowIdx"
									[ngClass]="{
										'error': passProp.errors.last_name && !passIDToTicketProps[ lineItem.passID ].flagsFirstLastIsOptional,
										'lastname': true
									}"
									[(ngModel)]="passProp.last_name"
									[ngModelOptions]="{ updateOn: 'blur' }"
									(blur)="!passIDToTicketProps[ lineItem.passID ].flagsFirstLastIsOptional && validateDisplayItem( passProp, 'last_name' );"
									(focus)="tmpTicketErrors = false;"
								/>
							</label>
							<label>
								<span class="label-text">Date of Birth</span>
								<input type="date" [max]="dobMaxDate"
									*ngIf="passIDToTicketProps[ lineItem.passID ].dataDOB"
									[name]="'dob-' + lineItemIdx + '-' + rowIdx"
									[ngClass]="{
										'dob': true
									}"
									[(ngModel)]="passProp.dob"
									[ngModelOptions]="{ updateOn: 'blur' }"
									(blur)="validateDisplayItem( passProp, 'dob' );"
									(click)="tmpTicketErrors = false;"
									(focus)="tmpTicketErrors = false;"
								/>
							</label>
						</li>
					</ul>
				</li>
			</ul>
		</div>
		<div class="column col-2 payment">
			<form class="form">
				<span class="sign-in-notice">{{ isSignedIn ? 'Not your account?' : 'Already have an account?' }} <a
					(click)="showTinySignIn();">Sign In</a> instead.</span>
				<label>
					<span class="frame-label">First Name</span>
					<input type="text" name="first-name"
						*ngIf="!isSignedIn"
						[class.error]="accountErrors.firstName"
						[(ngModel)]="accountData.firstName"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateAccData( 'firstName' );"
					/>
					<input type="text" readonly *ngIf="isSignedIn" [value]="accountData.firstName">
				</label>
				<label>
					<span class="frame-label">Last Name</span>
					<input type="text" name="last-name"
						*ngIf="!isSignedIn"
						[class.error]="accountErrors.lastName"
						[(ngModel)]="accountData.lastName"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateAccData( 'lastName' );"
					/>
					<input type="text" readonly *ngIf="isSignedIn" [value]="accountData.lastName">
				</label>
				<label class="wide">
					<span class="frame-label">Email Address<span [ngClass]="{
						'shown': emailIsInUse,
						'email-already-in-use': true
					}"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;Already in use</span></span>
					<input type="text" name="email"
						*ngIf="!isSignedIn"
						[class.error]="accountErrors.email || emailIsInUse"
						[(ngModel)]="accountData.email"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateAccData( 'email' );"
						(focus)="emailIsInUse = false; accountErrors.email = false;"
						(blur)="checkIfEmailAlreadyExist();"
					/>
					<input type="text" readonly *ngIf="isSignedIn" [value]="accountData.email">
				</label>
				<label class="wide" *ngIf="!isSignedIn">
					<span class="frame-label">Password</span>
					<input type="password" name="password1"
						[class.error]="accountErrors.password1"
						[(ngModel)]="accountData.password1"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateAccData( 'password1' );"
					/>
				</label>
				<label class="wide" *ngIf="!isSignedIn">
					<span class="frame-label">Password (Confirm)</span>
					<input type="password" name="password2"
						[class.error]="accountErrors.password2"
						[(ngModel)]="accountData.password2"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateAccData( 'password2' );"
					/>
				</label>
				<hr *ngIf="grandTotal > 0 || (accountData.cashlessSpending && !(grandTotal > 0))"/>
				<!--
					shipping info, here.
					[ street ]
					[ city ]
					[ zip ]
					[ state ]
					----
					[ same as shipping ? ] // if checked, over-write billing info
				-->
				<label class="wide" *ngIf="grandTotal > 0 || (accountData.cashlessSpending && !(grandTotal > 0))">
					<span class="frame-label">Cardholder Name</span>
					<input type="text" name="cardholder-name"
						[class.error]="checkoutErrors.card.name"
						[(ngModel)]="checkoutData.card.name"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateCardData( 'name' );"
					/>
				</label>
				<label class="wide" *ngIf="grandTotal > 0 || (accountData.cashlessSpending && !(grandTotal > 0))">
					<span class="frame-label">Card Details</span><span
						[ngClass]="{
							'card-details-invalid': true,
							'shown': checkoutErrors.card.number
						}"
					><i class="fa-solid fa-circle-exclamation"></i>&nbsp;Invalid Card Details</span>
					<div class="input inputs-group card-number">
						<input type="text" name="ccnum" maxlength="16" placeholder="Card Number"
							[class.error]="checkoutErrors.card.number"
							[(ngModel)]="checkoutData.card.number"
							[ngModelOptions]="{ updateOn: 'blur' }"
							(ngModelChange)="validateCardData( 'number' );"
						/>
						<input type="text" name="exp-mm" maxlength="2" placeholder="MM"
							[class.error]="checkoutErrors.card.expMM"
							[(ngModel)]="checkoutData.card.expMM"
							[ngModelOptions]="{ updateOn: 'blur' }"
							(ngModelChange)="validateCardData( 'expMM' );"
						/>
						<input type="text" name="exp-yy" maxlength="2" placeholder="YY"
							[class.error]="checkoutErrors.card.expYYYY"
							[(ngModel)]="checkoutData.card.expYYYY"
							[ngModelOptions]="{ updateOn: 'blur' }"
							(ngModelChange)="validateCardData( 'expYYYY' );"
						/>
						<input type="text" name="cvv" maxlength="4" placeholder="CVV"
							[class.error]="checkoutErrors.card.cvv"
							[(ngModel)]="checkoutData.card.cvv"
							[ngModelOptions]="{ updateOn: 'blur' }"
							(ngModelChange)="validateCardData( 'cvv' );"
						/>
					</div>
				</label>
				<hr />
				<label class="wide">
					<span class="frame-label">Billing Address</span>
					<input type="text" name="billing-address"
						[class.error]="checkoutErrors.billing.street"
						[(ngModel)]="checkoutData.billing.street"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateBillingData( 'street' );"
					/>
				</label>
				<label>
					<span class="frame-label">Unit / Floor</span>
					<input type="text" name="billing-unit"
						[(ngModel)]="checkoutData.billing.unit"
						[ngModelOptions]="{ updateOn: 'blur' }"
					/>
				</label>
				<label>
					<span class="frame-label">City</span>
					<input type="text" name="billing-city"
						[class.error]="checkoutErrors.billing.city"
						[(ngModel)]="checkoutData.billing.city"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateBillingData( 'city' );"
					/>
				</label>
				<label>
					<span class="frame-label">State</span>
					<input type="text" name="billing-state"
						[class.error]="checkoutErrors.billing.state"
						[(ngModel)]="checkoutData.billing.state"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateBillingData( 'state' );"
					/>
				</label>
				<label>
					<span class="frame-label">Postal Code</span>
					<input type="text" name="billing-postal"
						[class.error]="checkoutErrors.billing.zip"
						[(ngModel)]="checkoutData.billing.zip"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateBillingData( 'zip' );"
					/>
				</label>
				<hr />
				<div
					[ngClass]="{
						'wide': true,
						'promo': true,
						'error': invalidPromoCode
					}"
				>
					<span class="frame-label">Promo Code</span>
					<div class="promo-wrapper">
						<input type="text" name="discount-code"
							[class.error]="invalidPromoCode"
							[(ngModel)]="promoCode"
							[ngModelOptions]="{ updateOn: 'blur' }"
							(focus)="clearDiscountCodeError();"
						/>
						<span
							[ngClass]="{
								'button': true,
								'centered': true,
								'grey': !promoCode,
								'disabled': !promoCode
							}"
							(click)="useDiscountCode();"
						>Apply</span>
					</div>
				</div>
				<hr />
				<ul class="totals">
					<li><span class="label">Subtotal</span><span class="amount">{{ subTotal | currency : 'USD' }}</span></li>
					<li><span class="label">Discount</span><span class="amount">-{{ discounts | currency : 'USD' }}</span></li>
					<li><span class="label">Total</span><span class="amount">{{ grandTotal | currency : 'USD' }}</span></li>
				</ul>
				<div class="required-check-boxes">
					<!-- <ui-check-box-liability-waiver (changed)="liabilityCheckboxChanged( $event );"></ui-check-box-liability-waiver> -->
					<ui-check-box-terms-of-use
						[termsAndConditionsURL]="termsConditionsURL"
						(changed)="termsAndConditionsChanged( $event );"
					></ui-check-box-terms-of-use>
				</div>
				<label class="wide cashless-spending">
					<span class="not-yet-a-frame-label">
						<span class="label-note">Cashless Spending</span>
						<span class="cashless-spending-note">Activate in venue cashless spending by safely storing your credit card. <span class="bold">No need to reach for your wallet or your smartphone</span>. Simply tap your venue issued wristband to make any purchase throughout the venue.</span>
					</span>
					<ui-toggle-box
						[checked]="accountData.cashlessSpending"
						(changed)="cashlessToggle( $event );"
					></ui-toggle-box>
				</label>
				<div
					[ngClass]="{
						'whats-wrong': true,
						'have-errors': haveCheckoutErrors
					}"
				>{{ declinedReason }}</div>
				<div class="captcha" *ngIf="!cartHeldIDsFatalError">
					<re-captcha
						[siteKey]="reCaptchaSiteKey"
						(resolved)="reCaptchaSetToken( $event );"
						(error)="reCaptchaErrored( $event );"
					></re-captcha>
				</div>
				<div class="info-plz">
					<div class="huh-do-what-now">Please provide all information before proceeding.</div>
					<div class="missing-info" *ngIf="tmpTicketErrors">Please fill in all pass information.</div>
					<div class="missing-info" *ngIf="emailIsInUse">Email address is already in use.</div>
					<div class="missing-info" *ngIf="checkoutErrors.card.name || checkoutErrors.card.number || checkoutErrors.card.expMM || checkoutErrors.card.expYYYY">Please provide all credit card information.</div>
					<div class="missing-info" *ngIf="checkoutErrors.billing.street || checkoutErrors.billing.city || checkoutErrors.billing.zip || checkoutErrors.billing.state">Please provide billing information.</div>
				</div>
				<div
					*ngIf="!cartHeldIDsFatalError"
					[ngClass]="{
						'button': true,
						'disabled': !liabilityAgreementChecked || !termsAndConditionsChecked || isCheckingOut || emailIsInUse
					}"
					(click)="submitCart();"
				>{{ isCheckingOut ? 'Processing' : 'Pay ' + (grandTotal | currency : 'USD') }}
				</div>
				<div *ngIf="cartHeldIDsFatalError" id="checkout-fatal-error">Some items are unavailable. Please go to <a
					[routerLink]="[ '/' + routes.tickets ]">Buy Tickets</a> and choose different tickets.
				</div>
			</form>
		</div>
	</div>
</div>
