import {Injectable} from '@angular/core';
// ===== Configs ===== //
import {ConfigOWEnvironmentIDs} from '../../../ow-framework/environment-ids/ow-staging-ids';
// ===== Interfaces ===== //
import {InterfaceAppContext} from '../../../ow-framework/interfaces/interfaces';
//
@Injectable( {
	providedIn: 'root'
} )
export class BaseConfig extends ConfigOWEnvironmentIDs { // --- DEV --- // ...the baseAPI and ioHost should be different than live.
	//
	public constructor() {
		super();
		console.log( '========================= Staging MODE =========================' );
	}
	// ===== //
	protected readonly appKey: string = '66146ec4c2913c3555867501'; // RYST
	// protected readonly realmID: string = '668dc9af9f1b43d92d2e6aa3'; // Simpati Co (Portal) // the generic POS staff realm is 66907621e8e57780feaa7709
	protected workspaceID: string = '669177e372fdd1583eddd035'; // Simpati Co (shared between Portal and POS)
	protected readonly posStaffRealmID: string = '66907621e8e57780feaa7709'; // POS Staff
	protected readonly appShortName: string = 'RYST'; // used for support tickets. needs the short-code (acronym) of the project name.
	protected readonly reCaptchaSiteKey: string = '6LferCoqAAAAAIG62qrrYHNdplVT1JdZUKlBU-Gn'; // both dev and live
	// ===== Roles ===== //
	protected readonly roleIDs: { // roles are workspace-specific, as well as staging vs live.
		[key: string]: string;
	} = {
		'Admin': '66919de72ec6833212811356',
		'Staff': '66919de72ec6833212811357', // these x5 roles are Simpati Co specific roles.
		'POS': '66919de72ec6833212811358',
		'Web': '66919de72ec6833212811359',
		'OmniPlatform': '66919de72ec6833212811355'
	};
	// ===== Settings ===== //
	protected readonly settingsIDs: {
		[key: string]: string;
	} = { // 'nickname' : (doclet).setting_id // ObjectId // don't use the ._id key's value in the API, it doesn't return anything.
		// 'Holiday Schedule': '66048559be3d8159082f931c' // <= 'setting_id' vs the '_id' => '66048569be3d8159082f931e'
	};
	// ===== Realm IDs ===== //
	protected readonly realmIDs: {
		[key: string]: string;
	} = {
		'Portal': this.RYSTRealmID,
		'POS': this.posStaffRealmID
	};
	// ===== //
	public getContext(): InterfaceAppContext { // most of the API service files needs these values, but they only exist at run-time, per app.
		return {
			appKey: this.appKey,
			appRealmID: this.RYSTRealmID,
			appWorkspaceID: this.RYSTWorkspaceID,
			realmID: this.RYSTRealmID,
			workspaceID: this.workspaceID
		};
	}

	public setWorkspaceID( workspaceID: string ): void {
		this.workspaceID = workspaceID;
	}
}
