import {EventEmitter, Injectable} from '@angular/core';
// ===== Interfaces ===== //
import {
	InterfaceAddToCart,
	InterfaceCartData,
	InterfaceCartDataChanged,
	InterfaceCartSerialization,
	InterfaceEventEntitlementLock,
	InterfaceSerializedCart
} from '../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAppEvents} from './app-events';
import {ServiceRegex} from './regex';
import {ServiceSorting} from './sorting';
//
@Injectable( {
	providedIn: 'root',
} )
export class ServiceCart {
	// TODO: make a thing that harbors the entire logic needed to calculate ticket prices, totals, etc.
	// - it must fetch passes for sale
	// - it must handle/fetch the availability, so that it can deal with surge pricing, etc.
	private static readonly storageKeyCart: string = 'ryst-cart';
	private static readonly storageKeyPromoCode: string = 'ryst-promo';
	public static cartData: InterfaceCartData[] = []; // TODO: make this private, create a getter(), change code everywhere, etc.
	public static cartChanged: EventEmitter<InterfaceCartDataChanged> = new EventEmitter<InterfaceCartDataChanged>(); // this never gets garbage collected, which is an issue, but its required.
	private static promoCode: string | null = null;
	public static promoCodeChanged: EventEmitter<void> = new EventEmitter<void>();

	public static getSerializedCart(): InterfaceSerializedCart {
		const output: InterfaceSerializedCart = {
			cartData: [],
			promoCode: ServiceCart.promoCode
		};

		for ( let x: number = 0; x < ServiceCart.cartData.length; ++x ) {
			const data: InterfaceCartData = ServiceCart.cartData[x];
			if ( data.remove ) {
				continue;
			}
			output.cartData.push( {
				pid: data.passID,
				eid: data.eventID,
				n: data.name,
				p: data.price.toFixed( 2 ),
				id: data?.ticketID ?? undefined,
				s: data?.spaceID ?? undefined,
				v: { // visit date & time
					d: {
						y: data.visitDate?.year ?? undefined,
						m: data.visitDate?.month1 ?? undefined,
						d: data.visitDate?.day ?? undefined,
						a: data.visitDate?.isAnyDay ? '1' : '0',
						e: data.visitDate?.isEventLength ? '1' : '0'
					},
					t: data?.entryTime ?? undefined
				},
				c: {
					id: data.consumer?._id?.$oid ?? undefined,
					f: data.consumer?.firstName ?? undefined,
					l: data.consumer?.lastName ?? undefined,
					d: data.consumer?.dob ?? undefined,
					p: data.consumer?.phone ?? undefined
				}
			} );
		}
		return output;
	}

	public static serialize(): void {
		const data: InterfaceSerializedCart = ServiceCart.getSerializedCart();
		localStorage.setItem( ServiceCart.storageKeyCart, JSON.stringify( data.cartData, null, 0 ) );
		if ( data.promoCode === null || data.promoCode.length < 1 ) {
			localStorage.removeItem( ServiceCart.storageKeyPromoCode );
		} else {
			localStorage.setItem( ServiceCart.storageKeyPromoCode, data.promoCode );
		}
	}

	public static deserialize(): void {
		try {
			const strJSON: string | null = localStorage.getItem( ServiceCart.storageKeyCart );
			if ( strJSON === null ) {
				return;
			}
			const mysteryData: InterfaceCartSerialization[] | unknown = JSON.parse( strJSON );
			if ( !Array.isArray( mysteryData ) ) {
				throw 'Invalid JSON. Expecting an array.';
			}
			const output: InterfaceCartData[] = [];
			for ( let x: number = 0; x < mysteryData.length; ++x ) {
				const mysteryEntry: any = mysteryData[x];
				if ( mysteryEntry === null || Array.isArray( mysteryEntry ) || typeof mysteryEntry !== 'object' ) {
					throw 'Invalid cart entry. Expecting an object.';
				}
				let passID: string | null = 'pid' in mysteryEntry && typeof mysteryEntry.pid === 'string' ? mysteryEntry.pid as string : null;
				if ( typeof passID !== 'string' || !ServiceRegex.mongoIdExp.test( passID ) ) {
					console.error( 'ServiceCart::deserialize - Invalid passID', passID );
					throw 'Invalid passID found.';
				}
				let eventID: string | null = 'eid' in mysteryEntry && typeof mysteryEntry.eid === 'string' ? mysteryEntry.eid as string : null;
				if ( typeof eventID !== 'string' || !ServiceRegex.mongoIdExp.test( eventID ) ) {
					console.error( 'ServiceCart::deserialize - Invalid eventID', eventID );
					throw 'Invalid eventID for passID ' + passID + ' [' + x + ']';
				}
				const name: string | null = 'n' in mysteryEntry && typeof mysteryEntry.n === 'string' ? mysteryEntry.n.replace( ServiceRegex.trimRegExp, '' ) : null;
				if ( !name ) {
					throw 'Invalid ticket Name for passID ' + passID + ' [' + x + ']';
				}
				const price: number = 'p' in mysteryEntry && typeof mysteryEntry.p === 'string' ? Number.parseFloat( mysteryEntry.p ) : NaN;
				if ( Number.isNaN( price ) || !Number.isFinite( price ) || price < 0 ) {
					throw 'Invalid price for passID ' + passID + ' [' + x + ']';
				}
				const ticketID: string | null = 'id' in mysteryEntry && typeof mysteryEntry.id === 'string' ? mysteryEntry.id.replace( ServiceRegex.trimRegExp, '' ).toLowerCase() : null;
				if ( typeof ticketID === 'string' && ticketID !== '' ) { // blank ticket IDs means we couldn't acquire one, but one is still needed, i think.
					if ( !ServiceRegex.mongoIdExp.test( ticketID ) ) {
						throw 'Invalid ticketID ' + ticketID + ' for passID ' + passID + ' [' + x + ']';
					}
				}
				const spaceID: string | null = 's' in mysteryEntry && typeof mysteryEntry.s === 'string' ? mysteryEntry.s.replace( ServiceRegex.trimRegExp, '' ) : null;
				let visitDate: InterfaceCartData['visitDate'] = {
					year: null,
					month1: null,
					day: null,
					isAnyDay: false,
					isEventLength: false
				};
				let visitTime: string | null = null; // HH:MM:SS
				if ( 'v' in mysteryEntry ) { // visit date & time
					if ( 'd' in mysteryEntry.v ) {
						if ( 'y' in mysteryEntry.v.d && typeof mysteryEntry.v.d.y === 'number' ) {
							visitDate.year = mysteryEntry.v.d.y as number;
							if ( !String( visitDate.year ).match( /^\d\d\d\d$/ ) ) {
								throw 'Invalid year for passID ' + passID + ' [' + x + ']';
							}
						}
						if ( 'm' in mysteryEntry.v.d && typeof mysteryEntry.v.d.m === 'number' ) {
							visitDate.month1 = mysteryEntry.v.d.m as number;
							if ( visitDate.month1 < 0 || visitDate.month1 > 12 ) {
								// months ought to be 1 - 12, but i'm allowing 0 for special logic that really shouldn't exist...
								throw 'Invalid month for passID ' + passID + ' [' + x + ']';
							}
						}
						if ( 'd' in mysteryEntry.v.d && typeof mysteryEntry.v.d.d === 'number' ) {
							visitDate.day = mysteryEntry.v.d.d as number;
							if ( visitDate.day < 0 || visitDate.day > 31 ) {
								// day ought to be 1 - 28,29,30,31 with some better restrictions on the upper range.
								throw 'Invalid day for passID ' + passID + ' [' + x + ']';
							}
						}
						visitDate.isAnyDay = mysteryEntry.v.d.a === '1';
						visitDate.isEventLength = mysteryEntry.v.d.e === '1';
					} // end if visit date exists
					if ( 't' in mysteryEntry.v && typeof mysteryEntry.v.t === 'string' && mysteryEntry.v.t.length > 0 ) {
						visitTime = mysteryEntry.v.t as string;
						visitTime = visitTime.replace( ServiceRegex.trimRegExp, '' );
						if ( !ServiceRegex.HHMMSSExp.test( visitTime ) ) {
							throw 'Invalid time for passID ' + passID + ' [' + x + ']';
						}
						const arrTime: string[] = visitTime.split( /:/g );
						const HH: number = Number.parseInt( arrTime[0], 10 );
						const MM: number = Number.parseInt( arrTime[1], 10 );
						const SS: number = Number.parseInt( arrTime[2], 10 );
						if ( HH < 0 || HH > 23 || MM < 0 || MM > 59 || SS < 0 || SS > 59 ) {
							throw 'Invalid time for passID ' + passID + ' [' + x + ']';
						}
					} // end if visit time exists
				} // end if visit exists. (date & time)
				const consumerData: InterfaceCartData['consumer'] = {
					_id: undefined,
					firstName: null,
					lastName: null,
					dob: null,
					phone: null
				};
				if ( 'c' in mysteryEntry && mysteryEntry.c !== null && !Array.isArray( mysteryEntry.c ) && typeof mysteryEntry.c === 'object' ) {
					if ( 'id' in mysteryEntry.c ) {
						if ( typeof mysteryEntry.c.id === 'string' && ServiceRegex.mongoIdExp.test( mysteryEntry.c.id ) ) {
							consumerData._id = { $oid : mysteryEntry.c.id as string };
						} else {
							throw 'Invalid Consumer ID for passID ' + passID + ' [' + x + ']';
						}
					}
					if ( 'f' in mysteryEntry.c ) { // fields can be missing/undefined, but cannot be a non-string if defined.
						if ( typeof mysteryEntry.c.f === 'string' ) {
							consumerData.firstName = mysteryEntry.c.f.replace( ServiceRegex.trimRegExp, '' ) as string;
						} else { // needing to put `as string` after a .replace because things like .c.f is an unknown type, so .replace is not obvious to the linter that it is from String.replace()
							throw 'Invalid first name for passID ' + passID + ' [' + x + ']';
						}
					}
					if ( 'l' in mysteryEntry.c ) {
						if ( typeof mysteryEntry.c.l === 'string' ) {
							consumerData.lastName = mysteryEntry.c.l.replace( ServiceRegex.trimRegExp, '' ) as string;
						} else {
							throw 'Invalid last name for passID ' + passID + ' [' + x + ']';
						}
					}
					if ( 'd' in mysteryEntry.c ) {
						if ( typeof mysteryEntry.c.d === 'string' ) {
							consumerData.dob = mysteryEntry.c.d.replace( ServiceRegex.trimRegExp, '' ) as string;
							if ( !ServiceRegex.YYYYMMDDExp.test( consumerData.dob ) ) {
								throw 'Invalid DOB for passID ' + passID + ' [' + x + ']';
							}
						} else {
							throw 'Invalid DOB for passID ' + passID + ' [' + x + ']';
						}
					}
					if ( 'p' in mysteryEntry.c ) {
						if ( typeof mysteryEntry.c.p === 'string' ) {
							consumerData.phone = mysteryEntry.c.p.replace( ServiceRegex.trimRegExp, '' );
						} else {
							throw 'Invalid phone for passID ' + passID + ' [' + x + ']';
						}
					}
				}
				// ===== //
				output.push( {
					passID: passID,
					eventID: eventID,
					name: name,
					price: price,
					ticketID: ticketID,
					spaceID: spaceID,
					visitDate: visitDate,
					entryTime: visitTime,
					consumer: consumerData
				} );
			} // end for each mysteryEntry -- mysteryData[x]
			ServiceCart.cartData = output;
		} catch ( fail ) {
			console.error( 'Failed to deserialize cart data.', fail );
		}
		const promoCode: string | null = localStorage.getItem( ServiceCart.storageKeyPromoCode );
		if ( promoCode === null || promoCode.length < 1 ) {
			localStorage.removeItem( ServiceCart.storageKeyPromoCode );
		} else {
			const oldPromoCode: string | null = ServiceCart.promoCode;
			ServiceCart.promoCode = promoCode;
			if ( promoCode !== oldPromoCode ) {
				ServiceCart.promoCodeChanged.emit();
			}
		}
	}

	public static clear(): void {
		ServiceCart.cartData = [];
		ServiceCart.promoCode = null;
		ServiceCart.serialize();
		ServiceCart.cartChanged.emit( {
			type: 'cleared'
		} );
		ServiceCart.promoCodeChanged.emit();
	}

	public static getTicketCountByPassID( passID: string ): number {
		return ServiceCart.cartData.filter( (item: InterfaceCartData): boolean => {
			return item.passID === passID;
		} ).length;
	}

	public static getTicketCount(): number {
		return ServiceCart.cartData.length;
	}

	private static releaseEntitlementIDs( entitlementIDs: string | string[] ): void {
		const eIDs: string[] = Array.isArray( entitlementIDs ) ? entitlementIDs : [ entitlementIDs ];
		if ( eIDs.length > 0 ) {
			ServiceAppEvents.broadcast( {
				topic: 'entitlement:unlock',
				data: {
					id: eIDs
				}
			} );
		}
	}

	public static addToCart( data: InterfaceAddToCart | InterfaceAddToCart[] ): void {
		const newItems: InterfaceCartData[] = [];
		if ( Array.isArray( data ) ) { // array length can be zero.
			for ( let x: number = 0; x < data.length; ++x ) {
				const cartItem: InterfaceCartData = {
					passID: data[x].passForSale._id.$oid,
					eventID: data[x].eventID,
					name: data[x].passForSale.data.name ?? '',
					price: data[x].price,
					ticketID: data[x].ticketID,
					spaceID: data[x].spaceID,
					visitDate: data[x].visitDate,
					entryTime: data[x].time,
					consumer: data[x].consumerData
				};
				ServiceCart.cartData.push( cartItem );
				newItems.push( cartItem );
			}
		} else {
			const cartItem: InterfaceCartData = {
				passID: data.passForSale._id.$oid,
				eventID: data.eventID,
				name: data.passForSale.data.name ?? '',
				price: data.price,
				ticketID: data.ticketID,
				spaceID: data.spaceID,
				visitDate: data.visitDate,
				entryTime: data.time,
				consumer: data.consumerData
			};
			ServiceCart.cartData.push( cartItem );
			newItems.push( cartItem );
		}
		ServiceCart.serialize(); // saving progress....
		if ( newItems.length > 0 ) {
			ServiceCart.cartChanged.emit( {
				type: 'added',
				items: newItems
			} );
		}
	}

	public static removeTaggedCartItems(): void {
		const removedItems: InterfaceCartData[] = [];
		for ( let x: number = 0; x < ServiceCart.cartData.length; ++x ) {
			if ( ServiceCart.cartData[x].remove ) {
				removedItems.push( ServiceCart.cartData.splice( x--, 1 ).pop() as InterfaceCartData );
			}
		}
		if ( removedItems.length > 0 ) {
			ServiceCart.serialize();
			const entitlementIDs: string[] = [];
			for ( let x: number = 0; x < removedItems.length; ++x ) {
				const eID: string | null = removedItems[x].ticketID;
				if ( typeof eID === 'string' && eID.length > 0 ) {
					entitlementIDs.push( eID );
				}
			}
			ServiceCart.releaseEntitlementIDs( entitlementIDs );
			ServiceCart.cartChanged.emit( {
				type: 'removed',
				items: removedItems
			} );
		}
	}

	public static increaseQuantity( itemToCopy: InterfaceCartData, ticketID: string | null ): void {
		if ( itemToCopy.spaceID !== null ) {
			return; // you cannot duplicate unique tickets.
		}
		const newItem: InterfaceCartData = { // don't copy the property references. only copy the values.
			passID: itemToCopy.passID.toString(),
			eventID: itemToCopy.eventID.toString(),
			ticketID: ticketID,
			entryTime: itemToCopy.entryTime === null ? null : itemToCopy.entryTime.toString(),
			spaceID: null, // you shouldn't be able to +/- cart items that have a location ID
			name: itemToCopy.name.toString(),
			price: Number( itemToCopy.price ),
			visitDate: {
				year: itemToCopy.visitDate.year === null ? null : Number( itemToCopy.visitDate.year ),
				month1: itemToCopy.visitDate.month1 === null ? null : Number( itemToCopy.visitDate.month1 ),
				day: itemToCopy.visitDate.day === null ? null : Number( itemToCopy.visitDate.day ),
				isAnyDay: !!(itemToCopy.visitDate.isAnyDay),
				isEventLength: !!(itemToCopy.visitDate.isEventLength)
			},
			consumer: {
				_id: undefined,
				firstName: null,
				lastName: null,
				dob: null,
				phone: null
			}
		};
		ServiceCart.cartData.push( newItem );
		ServiceCart.serialize();
		ServiceCart.cartChanged.emit( {
			type: 'added',
			items: [ newItem ]
		} );
	}

	public static sortByDate( data?: InterfaceCartData[] ): InterfaceCartData[] { // smallest (oldest) dates first. missing dates (passes for the entire event, etc) go first.
		// will either sort its own data, or something you pass in.
		return (data ?? ServiceCart.cartData).sort( (A: InterfaceCartData, B: InterfaceCartData): number => {
			if ( (B.visitDate.year ?? 0) < (A.visitDate.year ?? 0) ) {
				return 1; // B goes first.
			} // otherwise B is the same or more than A, so it stays `A before B`
			if ( (B.visitDate.month1 ?? 0) < (A.visitDate.month1 ?? 0) ) {
				return 1;
			}
			if ( (B.visitDate.day ?? 0) < (A.visitDate.day ?? 0) ) {
				return 1;
			}
			return ServiceSorting.naturalSort( (A.spaceID ?? ''), (B.spaceID ?? '') );
		} );
	}

	public static getCartData(): InterfaceCartData[] {
		return ServiceCart.cartData; // it's a public property, but that might change one day.
	}

	public static getPromoCode(): string | null {
		return ServiceCart.promoCode;
	}

	public static setPromoCode( code: string ): void {
		code = code.replace( ServiceRegex.trimRegExp, '' );
		const oldPromoCode: string | null = ServiceCart.promoCode;
		if ( code.length > 0 ) {
			ServiceCart.promoCode = code;
			localStorage.setItem( ServiceCart.storageKeyPromoCode, code );
			console.log( 'Cart Promo Code', code );
		} else {
			localStorage.removeItem( ServiceCart.storageKeyPromoCode );
		}
		if ( code !== oldPromoCode ) {
			ServiceCart.promoCodeChanged.emit();
		}
	}
}
