import {Injectable} from '@angular/core';
// ===== Interfaces ===== //
import {
	InterfaceAnyObject,
	InterfaceAppContext,
	InterfaceHTTPGateway,
	InterfaceOWAPIResponseData_T,
	InterfacePOSActionScanTicket,
	InterfacePOSProcessQRDataResult
} from '../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceOWAPI} from '../ow-api';
//
@Injectable( {
	providedIn: 'root',
} )
export class ServiceQRData {
	public constructor(
		private readonly owapi: ServiceOWAPI
	) {
		//
	}

	public processVenueEventQRData( appContext: InterfaceAppContext, eventID: string, staffProfileID: string, qrData: string, callback: (result: InterfacePOSProcessQRDataResult) => void ): void {
		const output: InterfacePOSProcessQRDataResult = {
			error: true, // prove otherwise...
			errorType: 'bad_qr_code', // most likely reason.
			result: null
		};
		if ( qrData.length < 1 ) {
			callback( output );
			return;
		}
		let docletID: string | null = null;
		try {
			const payload: InterfaceAnyObject = JSON.parse( qrData );
			// must have `{ "_id" : "123456789012345678901234" }`
			// not `{ "_id" : { "$oid" : <string> } }`
			if ( !payload || typeof payload?.['_id'] !== 'string' || payload['_id'].length !== 24 ) {
				console.log( 'POS - Parse QR Data - Missing required "_id" property.' );
			} else {
				docletID = payload['_id'];
			}
		} catch ( fail ) {
			console.log( 'POS - Parse QR Data - failed to JSON.parse', fail );
		}
		if ( !docletID ) {
			callback( output );
			return;
		}
		// ===== //
		// at this point, the method should of returned already on error. otherwise we are about to fetch a lot of data and return the results.
		// ===== //
		this.owapi.workspace.actions.pos.scanTicket( appContext, staffProfileID, docletID, eventID ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response.success ) {
				const apiResponse: InterfaceOWAPIResponseData_T<InterfacePOSActionScanTicket> | undefined = response.data;
				if ( apiResponse && Array.isArray( apiResponse?.data?.items ) ) {
					if ( apiResponse.data.items.length > 0 ) {
						output.error = false;
						output.errorType = null;
						output.result = apiResponse.data.items[0]
					} else {
						console.log( 'POS - Parse QR Data - No records from the API' );
					}
				} else {
					console.log( 'POS - Parse QR Data - Bad response from server', response.data );
					output.errorType = 'network';
				}
			} else {
				console.log( 'POS - Parse QR Data - Failed to make an API call', response );
				output.errorType = 'network';
			}
			callback( output );
		} );
	}
}
