import {Component, OnInit} from '@angular/core';
// ===== MomentJS ===== //
// import * as momentTZ from "moment-timezone"; // TODO: drag in moment-js-timezone-data
// ===== Components ===== //
import {ComponentDropDown} from '../drop-down';
//
@Component( {
	selector: 'time-zone-drop-down',
	templateUrl: '../drop-down.html',
	styleUrls: [
		'../drop-down.less'
	]
} )
export class ComponentTimeZoneDropDown extends ComponentDropDown implements OnInit {
	//
	public constructor() {
		super();
		// this.items = momentTZ.tz.names(); // this requires moment-js-timezone-data
	}

	public override ngOnInit(): void {
		if ( typeof this.placeholderText !== 'string' || this.placeholderText.length < 1 ) {
			this.placeholderText = 'Choose A Time Zone';
		}
		this._onInit();
	}
}
