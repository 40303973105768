import {Injectable} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceOWDoclet} from '../interfaces/interfaces';
//
@Injectable( {
	providedIn: 'root'
} )
export class TransformerTransactions {
	//
	public constructor() {
		//
	}

	public static getStatus( transaction: InterfaceOWDoclet ): string {
		switch ( transaction?.data?.['response_status'] ) {
			case 'approved': {
				return 'Approved';
			}
			case 'declined': {
				return 'Declined';
			}
		}
		return 'N/A';
	}

	public static getAction( transaction: InterfaceOWDoclet ): string {
		switch ( transaction?.data?.['action'] ) {
			case 'CHARGE': {
				return 'Charge';
			}
			case 'REFUND': {
				return 'Refund';
			}
		}
		return 'N/A';
	}

	public static isCashTrx( transaction: InterfaceOWDoclet ): boolean {
		return !!transaction?.data?.hasOwnProperty?.( 'amount_tender' ) ?? false;
	}
}
