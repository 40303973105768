import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../../../../../ow-framework/collections/profiles';
// ===== Interfaces ===== //
import {
	InterfaceAppContext,
	InterfaceForgotPasswordData,
	InterfaceForgotPasswordErrors,
	InterfaceHTTPGateway,
	InterfaceOWAPISignInResponse,
	InterfaceOWAPISignUpResponse,
	InterfaceOWUserAuth,
	InterfaceSignInData,
	InterfaceSignInErrors,
	InterfaceSignUpData,
	InterfaceSignUpErrors
} from '../../../../../../ow-framework/interfaces/interfaces';
// ===== Services ===== //
import {ServiceAppEvents} from '../../../../../../ow-framework/services/app-events';
import {ServiceAuthentication} from '../../../../../../ow-framework/services/authentication';
import {ServiceNavigate} from '../../../../../../ow-framework/services/navigate';
import {ServiceOWAPI} from '../../../../../../ow-framework/services/ow-api';
import {ServiceRegex} from '../../../../../../ow-framework/services/regex';
//
@Component( {
	selector: 'page-sign-in',
	templateUrl: './sign-in.html',
	styleUrls: [
		'./sign-in.less'
	]
} )
export class PageSignIn implements OnInit {
	private readonly appContext: InterfaceAppContext = this.appConfig.getContext();
	public readonly routes: typeof AppRouterLinks = AppRouterLinks;
	private busy: boolean = false;
	public activeForm: 'forgot-password' | 'sign-up' | 'sign-in' = 'sign-in';
	public badCredentials: boolean = false;
	//
	public constructor(
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly nav: ServiceNavigate,
		private readonly owapi: ServiceOWAPI,
		private readonly title: Title
	) {
		this.title.setTitle( 'Portal' );
	}

	public ngOnInit(): void {
		if ( this.auth.isSignedIn() ) {
			this.nav.toURL( '/' + this.routes.dashboard );
		}
	}

	public blur( E: Event ): void {
		if ( E.target instanceof HTMLElement ) {
			void E.target.dispatchEvent( new Event( 'blur' ) );
		}
	}

	// ========== Sign In ========== //
	public signInErrors: InterfaceSignInErrors = {
		email: false,
		password: false
	};

	public signInData: InterfaceSignInData = {
		email: '',
		password: ''
	};

	public showSignIn(): void {
		this.clearErrors();
		this.clearPasswords();
		this.activeForm = 'sign-in';
	}

	public validateSignInEmail(): void {
		this.signInErrors.email = !ServiceRegex.emailRegExp.test( this.signInData.email );
	}

	public validateSignInPassword(): void {
		this.signInErrors.password = this.signInData.password.length < 1;
	}

	public signInFail(): void {
		this.signInErrors.email = true;
		this.signInErrors.password = true;
		this.badCredentials = true;
	}

	public signIn(): void {
		this.validateSignInEmail();
		this.validateSignInPassword();
		if ( this.busy || this.signInErrors.email || this.signInErrors.password ) {
			return;
		}
		this.auth.clear();
		this.busy = true;
		this.owapi.account.auth.login( this.appContext, this.signInData.email, this.signInData.password ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response ) {
				if ( response.success && response.status === 200 ) {
					const apiResponse: InterfaceOWAPISignInResponse = response.data;
					if ( apiResponse && apiResponse.data ) {
						const authData: InterfaceOWUserAuth = apiResponse.data;
						if ( authData && authData.auth_key && authData.user && authData.user._id && authData.user._id.$oid ) {
							this.auth.setTokens( authData.auth_key, authData.user._id.$oid );
							this.colProfiles.cacheUserProfiles( [ authData.user ] );
						}
					}
					if ( this.auth.isSignedIn() ) {
						ServiceAppEvents.broadcast( 'user:signed-in' ); // tell the top level app, that the user just signed in...
						this.clearForms();
						this.nav.toURL( '/' + this.routes.dashboard );
					} else {
						this.signInFail();
					}
				} else {
					this.signInFail();
				}
			} else {
				alert( 'An error occurred. Please try again later.' );
			}
			this.busy = false;
		} );
	}

	public clearSignInErrors(): void {
		this.signInErrors.email = false;
		this.signInErrors.password = false;
		this.badCredentials = false;
	}

	// ========== Forgot Password ========== //
	public forgotPasswordErrors: InterfaceForgotPasswordErrors = {
		email: false
	};

	public forgotPasswordData: InterfaceForgotPasswordData = {
		email: ''
	};

	public showForgotPassword(): void {
		this.clearErrors();
		this.clearPasswords();
		this.activeForm = 'forgot-password';
	}

	public validateForgotPasswordEmail(): void {
		this.forgotPasswordErrors.email = !ServiceRegex.emailRegExp.test( this.forgotPasswordData.email );
	}

	public forgotPassword(): void {
		if ( !this.busy ) {
			if ( ServiceRegex.emailRegExp.test( this.forgotPasswordData.email ) ) {
				this.owapi.account.registration.resetPassword( this.appContext, this.forgotPasswordData.email.toLowerCase() ).subscribe( (response: InterfaceHTTPGateway): void => {
					if ( response ) {
						if ( response.success ) {
							// clear all forms. clear all errors.
							alert( 'Please check your email for resetting your password.' );
							this.clearForms();
							this.showSignIn();
						} else {
							if ( response.status === 400 ) {
								alert( 'The account does not exist, or the email not valid.' );
								this.forgotPasswordErrors.email = true;
							} else {
								alert( 'An error occurred. Please try again later.' );
							}
						}
					} else {
						alert( 'An error occurred. Please try again later.' );
					}
				} );
			} else {
				this.forgotPasswordErrors.email = true;
			}
		}
	}

	// ========== Sign Up ========== //
	public signUpErrors: InterfaceSignUpErrors = {
		firstName: false,
		lastName: false,
		email: false,
		password1: false,
		password2: false
	};

	public signUpData: InterfaceSignUpData = {
		firstName: '',
		lastName: '',
		email: '',
		password1: '',
		password2: ''
	};

	public showSignUp(): void {
		this.clearErrors();
		this.clearPasswords();
		this.activeForm = 'sign-up';
	}

	public validateSignUpData( key: keyof InterfaceSignUpData ): void {
		switch ( key ) {
			case 'firstName': {
				this.signUpData.firstName = this.signUpData.firstName.replace( ServiceRegex.trimRegExp, '' );
				this.signUpErrors.firstName = this.signUpData.firstName.length < 1;
				break;
			}
			case 'lastName': {
				this.signUpData.lastName = this.signUpData.lastName.replace( ServiceRegex.trimRegExp, '' );
				this.signUpErrors.lastName = this.signUpData.lastName.length < 1;
				break;
			}
			case 'email': {
				this.signUpData.email = this.signUpData.email.replace( ServiceRegex.trimRegExp, '' );
				this.signUpErrors.email = !this.signUpData.email.match( ServiceRegex.emailRegExp );
				break;
			}
			case 'password1': {
				this.signUpErrors.password1 = this.signUpData.password1.length < 1;
				if ( this.signUpData.password2.length > 0 ) {
					this.signUpErrors.password1 = this.signUpData.password1 !== this.signUpData.password2;
					this.signUpErrors.password2 = this.signUpData.password1 !== this.signUpData.password2;
				}
				break;
			}
			case 'password2': {
				this.signUpErrors.password2 = this.signUpData.password2.length < 1;
				if ( this.signUpData.password2.length > 0 ) {
					if ( this.signUpData.password1 !== this.signUpData.password2 ) {
						this.signUpErrors.password1 = this.signUpData.password1 !== this.signUpData.password2;
						this.signUpErrors.password2 = this.signUpData.password1 !== this.signUpData.password2;
					}
				}
				break;
			}
		}
	}

	public clearSignUpError( key: keyof InterfaceSignUpErrors ): void {
		if ( key in this.signUpErrors ) {
			this.signUpErrors[ key ] = false;
		}
	}

	public signUp(): void {
		if ( !this.busy ) {
			const keys: (keyof InterfaceSignUpErrors)[] = Object.keys( this.signUpErrors ) as (keyof InterfaceSignUpErrors)[];
			for ( let x: number = 0; x < keys.length; ++x ) {
				this.validateSignUpData( keys[x] );
			}
			for ( let x: number = 0; x < keys.length; ++x ) {
				if ( this.signUpErrors[ keys[x] ] ) {
					return;
				}
			}
			this.busy = true;
			this.owapi.account.registration.register( // 7 params
				this.appContext,
				this.signUpData.email,
				this.signUpData.password1, // param #3
				this.appConfig.getTemplateID( 'Consumer' ),
				{
					'first_name': this.signUpData.firstName,
					'last_name': this.signUpData.lastName
				},
				{
					'first_name': this.signUpData.firstName,
					'last_name': this.signUpData.lastName
				}
			).subscribe( (response: InterfaceHTTPGateway): void => {
				// you cannot sign-in the user from the result of this. they still have to verify their email address.
				this.busy = false;
				console.log( 'Registration response:', response );
				if ( response.success && response.status === 201 ) { // good
					const apiResponse: InterfaceOWAPISignUpResponse = response.data;
					if ( apiResponse.data.doclet_id ) {
						this.nav.toURL( '/' + this.routes.checkEmail );
					} else {
						alert( 'Something went wrong. Please try again.' );
					}
				} else if ( response.status === 409 ) { // email already in use.
					this.signUpErrors.email = true;
				} else { // HTTP status: 500, 400, etc.
					// unknown error
					console.log( response );
					alert( 'An error occurred. Please try again later.' );
				}
			} );
		}
	}

	// ========== //
	private clearErrors(): void {
		this.signInErrors.email = false;
		this.signInErrors.password = false;
		//
		this.signUpErrors.firstName = false;
		this.signUpErrors.lastName = false;
		this.signUpErrors.email = false;
		this.signUpErrors.password1 = false;
		this.signUpErrors.password2 = false;
		this.signUpErrors.company = false;
		//
		this.forgotPasswordErrors.email = false;
	}

	private clearForms(): void {
		this.signInData.email = '';
		this.signInData.password = '';
		//
		this.signUpData.firstName = '';
		this.signUpData.lastName = '';
		this.signUpData.email = '';
		this.signUpData.password1 = '';
		this.signUpData.password2 = '';
		this.signUpData.company = '';
		//
		this.forgotPasswordData.email = '';
		//
		this.clearErrors();
	}

	private clearPasswords(): void {
		this.signInData.password = '';
		this.signUpData.password1 = '';
		this.signUpData.password2 = '';
	}
}
